import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthWrapper } from './components/AuthWrapper';

import { useAuth } from '../../utils/Auth';
import { ErrorAlert, NotificationAlert } from '../../components/GeneralError';

const Forgot = (props) => {
	const { sendPasswordReset } = useAuth();
	const [credentials, setCredentials] = useState({});
	const [hasError, setHasError] = useState(null);
	const [isBusy, setIsBusy] = useState(false);
	const [isSent, setSent] = useState(false);

	const { t } = useTranslation();

	const isValid = hasError === null && credentials && credentials.email && credentials.email !== '';

	const onChange = (e) => {
		setHasError(null);
		setSent(false);
		const { currentTarget } = e;
		const { name, value } = currentTarget;

		const newCredentials = { ...credentials, [name]: value };
		setCredentials(newCredentials);
	};

	const doReset = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		setSent(false);
		try {
			await sendPasswordReset(credentials.email);
			setSent(true);
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	return (
		<div className="row h-100 d-flex justify-content-md-center">
			<AuthWrapper>
				{isSent && <NotificationAlert message={t('l.passwordResetRequested')} margin="" />}
				<form>
					{hasError && <ErrorAlert message={hasError} margin="" />}
					<div className="form-group">
						<label>{t('l.EnterEmailAddress')}</label>
						<input className="form-control mb-3" type="text" placeholder={t('l.emailAddress')} name="email" onChange={onChange} />
					</div>

					<button className="btn btn-primary" onClick={doReset} disabled={!isValid || isBusy}>
						{isBusy && <i className="fal fa-refresh fa-spin mr-2" />}
						{t('l.resetPassword')}
					</button>
				</form>

				<div className="d-flex justify-content-between mt-3">
					<Link className="text-secondary" to="/login">
						{t('l.alreadyHaveAccount')}
					</Link>
					{props.languageSelect}

					{/* <div className="mt-3">
						<span>Don't have an acount?</span>
						<Link className="text-secondary ml-2" to="/register">
							Register Here
						</Link>
					</div> */}
				</div>
			</AuthWrapper>
		</div>
	);
};

export default Forgot;
