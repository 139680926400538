import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSubmit } from '../../../components/ButtonSubmit';
import { ErrorAlert, NotificationAlert } from '../../../components/GeneralError';
import { CardWithHeader } from '../../../components/layout/CardContent';
import { HttpHandler } from '../../../utils/HttpHandler';

export const ChargeCardStop = (props) => {
	const { chargeCard = [], navBackward } = props;
	const [isBusy, setIsBusy] = useState(false);
	const [hasError, setHasError] = useState('');
	const [hasSuccess, setHasSuccess] = useState('');
	const { httpPost } = HttpHandler();
	const [data, setData] = useState({ type: 'stopChargeCards', stopReason: 1, chargeCardList: chargeCard });

	const { t } = useTranslation();

	const onChange = (e) => {
		const { name, value } = e.currentTarget;

		setData({ ...data, [name]: value });
	};

	const onSubmit = async (event) => {
		event.preventDefault();

		setIsBusy(true);
		setHasError('');
		setHasSuccess('');
		try {
			const result = await httpPost('/support', null, data);
			if (result) {
				setHasSuccess(result.data?.message ?? t('cs.stopChargeCardsRequestReceivedSuccessfully'));
			} else throw new Error (t('cs.error'));
		} catch (error) {
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	return (
		<CardWithHeader heading={t('cs.putCardsOnStop')}>
			<ErrorAlert message={hasError} margin={0} />
			<NotificationAlert message={hasSuccess} margin={0} />

			<div className="mb-3">
				<label className="m-0">{t('cs.selectReasonStoppingChargeCard')}</label>
				<select className="form-control form-control-sm" name="stopReason" onChange={onChange} disabled={isBusy}>
					<option value={1}>{t('cs.lostStolen')}</option>
					<option value={2}>{t('cs.cardDamaged')}</option>
					<option value={3}>{t('cs.cardIncorrect')}</option>
				</select>
			</div>

			<div className="mb-5">
				<ul className="pl-4">
					{chargeCard.map((element) => (
						<li>{`${element.description} (${element.identifier})`}</li>
					))}
				</ul>
			</div>

			<ButtonSubmit btnSubmitClick={onSubmit} btnResetClick={navBackward} isBusy={isBusy} isDirty={chargeCard.length > 0} />
		</CardWithHeader>
	);
};
