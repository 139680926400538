import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import { BreadCrumb } from '../../components/layout/BreadCrumb';

import { Page } from '../../components/layout/Page';
import { MapLocations } from '../../components/maps/MapLocations';

export const LiveMap = (props) => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const navBackward = () => {
		navigate(-1);
	};

	return (
		<Page icon="fal fa-map-location" heading={t('m.chargePointMap')}>
			<BreadCrumb title={t('m.chargePointMap')} navBackward={navBackward} />
			<Card className="mt-3 h-100">
				<CardBody className="p-1 p-lg-4">
					<MapLocations />
				</CardBody>
			</Card>
		</Page>
	);
};
