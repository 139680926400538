import axios from 'axios';
import { useAuth } from './Auth';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;

const restEndpoint = process.env.REACT_APP_BASE_URL;
const timeout = process.env.REACT_APP_API_TIMEOUT;

export const HttpHandler = (basePath = process.env.REACT_APP_API_PATH, skipAuth = false) => {
	const { user = {} } = useAuth() ?? {};

	const getAccessToken = async (token = null) => {
		if (token === null && skipAuth === true) return '';

		const authToken = token === null ? await user.getIdToken() : token;
		return `Bearer ${authToken}`;
	};

	const httpGet = async (endpoint, params, responseType = 'json', token = null) => {
		const _accessToken = await getAccessToken(token);

		console.debug('Getting data from', endpoint);
		try {
			const result = await axios({
				method: 'get',
				baseURL: `${restEndpoint}${basePath}`,
				url: endpoint,
				headers: { Authorization: _accessToken },
				timeout: timeout,
				responseType: responseType,
				params: params,
			});

			console.debug('Done Getting data from', endpoint);
			return result;
		} catch (error) {
			console.error('Error Getting data from', endpoint, error);
			throw error;
		}
	};

	const httpPost = async (endpoint, params, data, token = null) => {
		const _accessToken = await getAccessToken(token);

		console.debug('Posting data to', endpoint);
		try {
			const result = await axios({
				method: 'post',
				baseURL: `${restEndpoint}${basePath}`,
				url: endpoint,
				headers: { Authorization: _accessToken },
				timeout: timeout,
				params: params,
				data: data,
			});

			console.debug('Done Posting data to', endpoint);
			return result;
		} catch (error) {
			console.error('Error Posting data to', endpoint, error);
			throw error;
		}
	};

	const httpPut = async (endpoint, params, data, token = null) => {
		const _accessToken = await getAccessToken(token);

		console.debug('Putting data to', endpoint);
		try {
			const result = await axios({
				method: 'put',
				baseURL: `${restEndpoint}${basePath}`,
				url: endpoint,
				headers: { Authorization: _accessToken },
				timeout: timeout,
				params: params,
				data: data,
			});

			console.debug('Done Putting data to', endpoint);
			return result;
		} catch (error) {
			console.error('Error Putting data to', endpoint, error);
			throw error;
		}
	};

	const httpDel = async (endpoint, params, token = null) => {
		const _accessToken = await getAccessToken(token);

		console.debug('Deleting data from', endpoint);
		try {
			const result = await axios({
				method: 'delete',
				baseURL: `${restEndpoint}${basePath}`,
				url: endpoint,
				headers: { Authorization: _accessToken },
				timeout: timeout,
				params: params,
			});

			console.debug('Done Deleting data from', endpoint);
			return result;
		} catch (error) {
			console.error('Error Deleting data from', endpoint, error);
			throw error;
		}
	};

	return { httpGet, httpPost, httpPut, httpDel, getAccessToken };
};
