import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSubmit } from '../../../components/ButtonSubmit';
import { UserContact } from '../../../components/UserContact';

export const InvoiceQuery = (props) => {

	const { t } = useTranslation();
	
	const { onChange, data } = props;
	const { description = '', invoiceRef = '' } = data;
	return (
		<form>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('cs.indicateWhatYouWouldLikeToDiscuss')}</label>
					<textarea className="form-control form-control-sm" rows="5" name="description" defaultValue={description} value={description} onChange={onChange} />
				</div>
			</div>

			<UserContact {...props} />

			<div className="row mb-3">
				<div className="col">
					<label className="mb-0">{t('cs.invoiceNumberReference')}</label>
					<input className="form-control form-control-sm " type="text" name="invoiceRef" defaultValue={invoiceRef} value={invoiceRef} onChange={onChange} />
				</div>
			</div>

			<ButtonSubmit {...props} />
		</form>
	);
};
