import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from './Alert';

export const AlertList = (props) => {
	const { items = [], pageSize, setPageSize, itemCount } = props;

	const { t } = useTranslation();

	const loadMore = () => {
		setPageSize(pageSize + 10);
	};

	return (
		<div>
			{items.map((item, index) => (
				<Alert key={`alert-${index}`} item={item} />
			))}
			{itemCount > pageSize && (
				<div className="d-flex justify-content-center">
					<button className="btn btn-link text-gray-500 text-xs" onClick={loadMore}>
					{t('a.loadmore')}
					</button>
				</div>
			)}
		</div>
	);
};
