import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';

import { useAuth } from '../../../utils/Auth';
import { readMfaIdFactor } from '../../../utils/JwtHelper';
import { ErrorAlert } from '../../../components/GeneralError';

const SectionHeading = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="font-weight-bolder">{t('l.manageMfa')}</div>
			<div className="border-top pb-3"></div>
		</>
	);
};

export const UserMfa = () => {
	const { t } = useTranslation();
	const { user, reAuthUser, enrollMfa, completeMfa, unenrolMfa, verifyMfa } = useAuth();

	const [isDirty, setIsDirty] = useState(false);
	const [isBusy, setIsBusy] = useState(false);
	const [hasError, setHasError] = useState('');
	const [mfaFactor] = useState(readMfaIdFactor(user?.accessToken ?? null));

	const [mfaQRCode, setMfaQRCode] = useState(null);

	const [status, setStatus] = useState(mfaFactor !== null ? 'enabled' : 'disabled');
	const [item, setItem] = useState({});

	const onChange = (event) => {
		const newItem = { ...item, [event.currentTarget.name]: event.currentTarget.value };
		setItem(newItem);
		setIsDirty(true);
	};

	const reauth = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		setHasError('');
		try {
			await reAuthUser(item.password);
			mfaFactor === null ? await startMfaEnrollment(e) : await unenrol(e);
		} catch (error) {
			switch (error.code ?? '') {
				case 'auth/multi-factor-auth-required': {
					setStatus('needsFactor');
					break;
				}
				default: {
					setHasError(t('l.thereWasAnError'));
					break;
				}
			}
		}
		setIsBusy(false);
	};

	const verfyFactor = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		setHasError('');
		try {
			await verifyMfa(item.verificationCode);
			setStatus('turnOff');
		} catch (error) {
			if (error.code === 'auth/invalid-verification-code') {
				setHasError(t('l.invalidCode'));
			} else setHasError(error.message);
		}
		setIsBusy(false);
	};

	const startMfaEnrollment = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		setHasError('');
		try {
			var result = await enrollMfa();
			setMfaQRCode(result);
			setStatus('scanCode');
		} catch (error) {
			if (error.code === 'auth/missing-password' || error.code === 'auth/requires-recent-login') {
				setStatus('needsPassword');
			} else {
				setHasError(error.message);
			}
		}
		setIsBusy(false);
	};

	const completeEnrollment = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		setHasError('');
		try {
			await completeMfa(mfaQRCode.totpSecret, item.verificationCode);
			setItem({});
			setStatus('enabled');
		} catch (error) {
			if (error.code === 'auth/mfa-enrollment-already-complete') {
				setStatus('enabled');
			} else if (error.code === 'auth/invalid-verification-code') {
				setHasError(t('l.invalidCode'));
			} else setHasError(error.message);
		}
		setIsBusy(false);
	};

	const unenrol = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		setHasError('');
		try {
			await unenrolMfa(mfaFactor?.factorId ?? null);
			setStatus(null);
		} catch (error) {
			switch (error.code ?? '') {
				case 'auth/multi-factor-auth-required': {
					setStatus('needsFactor');
					break;
				}
				case 'auth/requires-recent-login': {
					setStatus('needsPassword');
					break;
				}
				default: {
					setHasError(t('l.thereWasAnError'));
					break;
				}
			}
		}
		setIsBusy(false);
	};

	switch (status) {
		case 'enabled':
			return (
				<form>
					<SectionHeading />
					{hasError && <ErrorAlert message={hasError} margin="" />}
					<div className="mb-2">{t('d.mfaEnabled')}</div>
					<button className="btn btn-secondary" onClick={unenrol} disabled={isBusy}>
						{t('l.disableMfa')}
					</button>
				</form>
			);
		case 'needsPassword':
			return (
				<form>
					<SectionHeading />
					{hasError && <ErrorAlert message={hasError} margin="" />}
					<label className="mb-3">{t('d.needsPassword')}</label>
					<input className="form-control form-control-sm" type="password" name="password" onChange={onChange} />

					<button className="mt-3 btn btn-primary" onClick={reauth} disabled={isBusy}>
						{t('l.next')}
						<i className={`ms-2 ${isBusy ? 'fal fa-sync fa-spin' : 'fal fa-angle-right'}`} />
					</button>
				</form>
			);

		case 'needsFactor':
			return (
				<form>
					<SectionHeading />
					{hasError && <ErrorAlert message={hasError} margin="" />}
					<label className="mb-3">{t('l.verificationCode')}</label>
					<input className="form-control form-control-sm" type="text" name="verificationCode" onChange={onChange} />

					<button className="mt-3 btn btn-primary" onClick={verfyFactor} disabled={isBusy}>
						{t('l.next')}
						<i className={`ms-2 ${isBusy ? 'fal fa-sync fa-spin' : 'fal fa-angle-right'}`} />
					</button>
				</form>
			);

		case 'turnOff':
			return (
				<div>
					<SectionHeading />
					{hasError && <ErrorAlert message={hasError} margin="" />}
					<p className="mb-3">{t('d.mfaDisableConfirmation')}</p>
					<button className="mt-3 btn btn-primary" onClick={unenrol} disabled={isBusy}>
						<i className={`mr-2 ${isBusy ? 'fal fa-sync fa-spin' : 'fal fa-exclamation-triangle'}`} />
						{t('l.disableMfa')}
					</button>
				</div>
			);

		case 'scanCode':
			return (
				<>
					<SectionHeading />
					{hasError && <ErrorAlert message={hasError} margin="" />}
					<label>{t('d.mfaInstruction')}</label>
					<div className="d-flex flex-column align-items-center mt-2">
						<div className="m-3">
							<QRCode id="MFAQRCode" value={mfaQRCode.qrContents} />
						</div>
						<p>
							{t('l.secretKey')}
							<label className="m-2">{mfaQRCode.secretKey}</label>
						</p>
					</div>

					<div className="mt-3">
						<label className="mb-2">
						{t('l.enterCodeToCompleteRegistrationSteps')}
						</label>

						<label className="mb-3">{t('l.verificationCode')}</label>
						<input className="form-control form-control-sm" type="text" name="verificationCode" onChange={onChange} />
						<button className="mt-3 btn btn-primary" onClick={completeEnrollment} disabled={isBusy}>
						{t('l.complete')}
						</button>
					</div>
				</>
			);

		default:
			return (
				<>
					<SectionHeading />
					<div className="mb-3">{t('d.mfaDisabled')}</div>
					<button className="btn btn-success" onClick={startMfaEnrollment} disabled={isBusy}>
						<i className={`mr-2 ${isBusy ? 'fal fa-sync fa-spin' : 'fal fa-lightbulb-on'}`} />
						{t('l.enableMfa')}
					</button>
				</>
			);
	}
};
