import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorAlert, NotificationAlert } from '../../../components/GeneralError';

const AddressForm = ({ user, mobileNumber, updateProfile }) => {
	const [profile, setProfile] = useState({});
	const [isDirty, setIsDirty] = useState(false);
	const [hasError, setHasError] = useState(null);
	const [isDone, setIsDone] = useState(false);
	const [isBusy, setIsBusy] = useState(false);

	const { t } = useTranslation();

	const valueChange = (event) => {
		setHasError(false);
		const { name, value } = event.currentTarget;
		setProfile({ ...profile, [name]: value });
		setIsDirty(true);
	};

	const reset = () => {
		setProfile({
			displayName: user?.displayName ?? '',
			phoneNumber: user?.phoneNumber ?? '',
			email: user?.email ?? '',
		});

		setIsDirty(false);
	};

	const doUpdate = async () => {
		setIsBusy(true);
		setIsDone(false);
		try {
			await updateProfile(profile);
			setIsDirty(false);
			setIsDone(true);
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	useEffect(() => {
		setProfile({ displayName: user?.displayName, phoneNumber: mobileNumber, email: user?.email });
	}, [user, mobileNumber]);

	return (
		<form>
			{hasError && <ErrorAlert message={hasError} margin="" />}
			{isDone && <NotificationAlert message={t('u.profileUpdated')} margin="" />}
			<div className="row mb-3">
				<div className="col">
					<label className="mb-0">{t('u.displayName')}</label>
					<input className="form-control form-control-sm " type="text" name="displayName" value={profile.displayName ?? ''} onChange={valueChange} />
				</div>
			</div>

			<div className="row row-cols-1 row-cols-md-2 mb-3">
				<div className="col">
					<label className="mb-0">{t('u.phoneNumber')}</label>
					<input className="form-control form-control-sm " type="phone" name="phoneNumber" value={profile.phoneNumber ?? ''} onChange={valueChange} />
				</div>
				<div className="col">
					<label className="mb-0">{t('u.emailAddress')}</label>
					<input className="form-control form-control-sm " type="email" name="email" value={profile.email ?? ''} onChange={valueChange} />
				</div>
			</div>

			<button className="btn btn-primary" type="button" disabled={!isDirty || isBusy} onClick={doUpdate}>
				{t('u.updateProfile')}
			</button>
		</form>
	);
};

export default AddressForm;
