import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonText } from '../../components/Buttons';

const FilterActions = ({ action }) => {
	const [period, setPeriod] = useState(7);

	const { t } = useTranslation();

	const setFilter = async (days) => {
		setPeriod(days);
		if (action) await action(days);
	};

	return (
		<Fragment>
			<div className="d-none d-sm-flex">
				<ButtonText icon="fal fa-calendar mr-2" extraClass={period === 7 ? 'active' : ''} action={() => setFilter(7)} text={t('g.week')} />
				<ButtonText icon="fal fa-calendar-week mr-2" extraClass={period === 30 ? 'active' : ''} action={() => setFilter(30)} text={t('g.month')} />
				<ButtonText icon="fal fa-calendar-alt mr-2" extraClass={period === 365 ? 'active' : ''} action={() => setFilter(365)} text={t('g.year')} />
			</div>

			<div className="d-flex d-sm-none">
				<button className={`btn btn-icon btn-outline-primary mr-2 ${period === 7 ? 'active' : ''}`} onClick={() => setFilter(7)}>
					<i className="fal fa-calendar" />
				</button>
				<button className={`btn btn-icon btn-outline-primary mr-2 ${period === 30 ? 'active' : ''}`} onClick={() => setFilter(30)}>
					<i className="fal fa-calendar-week" />
				</button>
				<button className={`btn btn-icon btn-outline-primary ${period === 365 ? 'active' : ''}`} onClick={() => setFilter(365)}>
					<i className="fal fa-calendar-alt" />
				</button>
			</div>
		</Fragment>
	);
};

export default FilterActions;
