import React from 'react';

import './ClusterMarker.css';

const ClusterMarker = ({ clusterInfo, points, zoomCluster, lat, lng }) => {
	const { point_count } = clusterInfo.properties;

	const openCluster = () => {
		const { id } = clusterInfo;
		if (zoomCluster) zoomCluster(id, lat, lng);
	};

	return (
		<div
			className="cluster-marker"
			style={{
				width: `${30 + point_count / points}px`,
				height: `${30 + point_count / points}px`,
			}}
			title={`${point_count} locations`}
			onClick={openCluster}
		>
			{point_count}
		</div>
	);
};

export default ClusterMarker;
