import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import iconImage from '../../assets/images/icon.png';
import { useTheme } from '../../utils/ThemeContextProvider';

const CardItem = (props) => {
	const theme = useTheme();
	const { t } = useTranslation();
	const { icon = '', iconColor = 'primary', title = 'Title', onClick, children = null, hasMoreLink = true, isBusy = false, showLogo = true } = props;

	return (
		<Card className="mb-4 lift" onClick={onClick}>
			<CardBody className="d-flex align-items-center cardBody" style={{ height: children === null ? 'initial' : 160 }}>
				<i className={`${icon} fa-3x text-${iconColor} mx-3 py-2`} />
				<div className="d-flex flex-column h-100 w-100 position-relative">
					<p className={`text-gray-700 font-weight-300 d-flex align-items-center ${children !== null ? 'mb-2 h2' : 'h4'}`}>
						{showLogo && <img src={theme.faviconUrl ?? iconImage} style={{ maxWidth: 20 }} className={`mr-2 mt-n1 ${isBusy ? 'fa-spin' : ''}`} alt="icon" />}
						<span>{title}</span>
					</p>

					<span className="text-gray-600 d-flex flex-column flex-grow-1 justify-content-center">{children}</span>

					{hasMoreLink && (
						<div className="pt-2 d-flex flex-column justify-content-end">
							<span className={`text-${iconColor} font-weight-500 align-self-end`}>
								<span>{t('g.view')}</span>
								<i className="fal fa-angle-right ml-2" />
							</span>
						</div>
					)}
				</div>
			</CardBody>
		</Card>
	);
};

export const TileDetail = (props) => {
	const { to = null } = props;

	if (to === null) return <CardItem {...props} />;

	return (
		<Link to={to ?? ''} className="card-link">
			<CardItem {...props} />
		</Link>
	);
};
