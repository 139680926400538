import { useReducer, useState } from 'react';
import { HttpHandler } from '../utils/HttpHandler';

const ItemCrudContext = (baseUrl, itemId = null, model = null, basePath = process.env.REACT_APP_API_PATH) => {
	const isModelArray = Array.isArray(model);
	const initialModel = isModelArray ? [...model] ?? [] : { ...model } ?? {};

	const [original, setOriginal] = useState(initialModel);
	const initialState = { item: initialModel, isBusy: false, isDirty: false, hasError: '', wasSaved: false };
	const { httpGet, httpPost, httpPut } = HttpHandler(basePath);

	const [data, dispatch] = useReducer((prevState, action) => {
		switch (action.type) {
			case 'ISBUSY':
				return {
					...prevState,
					isBusy: true,
					hasError: '',
				};
			case 'HASDATA':
				return {
					...prevState,
					item: isModelArray ? [...action.data.item] : { ...action.data.item },
					isBusy: false,
					hasError: '',
				};
			case 'WASCHANGED':
				return {
					...prevState,
					item: isModelArray ? [...action.data.item] : { ...action.data.item },
					isDirty: true,
					wasSaved: false,
				};
			case 'MAPCHANGED':
				const { item } = prevState;
				const newItem = isModelArray ? [...item, ...action.data] : { ...item, ...action.data };

				return {
					...prevState,
					item: isModelArray ? [...newItem] : { ...newItem },
					isDirty: true,
					wasSaved: false,
				};
			case 'WASRESET':
				return {
					...prevState,
					item: isModelArray ? [...original] : { ...original },
					isDirty: false,
					hasError: '',
					wasSaved: false,
				};
			case 'WASSAVED':
				return {
					...prevState,
					isBusy: false,
					isDirty: false,
					wasSaved: true,
				};
			case 'MODALDISMISSED':
				return {
					...prevState,
					isBusy: false,
					isDirty: false,
					wasSaved: false,
					hasError: '',
				};
			case 'HASERROR':
				return {
					...prevState,
					isBusy: false,
					hasError: action.data,
				};
			default:
				throw new Error();
		}
	}, initialState);

	const getData = async () => {
		dispatch({ type: 'ISBUSY' });
		try {
			const result = await httpGet(`${baseUrl}${itemId === null ? '' : `/${itemId}`}`);
			dispatch({ type: 'HASDATA', data: { item: result.data } });
			if (model === null) setOriginal({ ...result.data });
		} catch (error) {
			const errorMessage = `${((error.response || {}).data || {}).message || error.message || ''}`;
			dispatch({ type: 'HASERROR', data: errorMessage });
		}
	};

	const onChange = async (event) => {
		const {
			currentTarget: { name, value, type, checked },
		} = event;

		const updatedItem = { ...data.item, [name]: type === 'checkbox' ? checked : value };
		dispatch({ type: 'WASCHANGED', data: { item: updatedItem } });
	};

	const onMapMarkerChange = (marker, property = 'coordinates') => {
		const { lat, lng } = marker;
		dispatch({ type: 'MAPCHANGED', data: { [property]: { latitude: lat, longitude: lng } } });
	};

	const createObject = async () => {
		dispatch({ type: 'ISBUSY' });

		try {
			await httpPost(baseUrl, null, data.item);
			dispatch({ type: 'WASSAVED' });
		} catch (error) {
			const errorMessage = `${((error.response || {}).data || {}).message || error.message || ''}`;
			dispatch({ type: 'HASERROR', data: errorMessage });
		}
	};

	const updateObject = async (payload = null) => {
		dispatch({ type: 'ISBUSY' });

		try {
			await httpPut(baseUrl, null, payload || data.item);
			dispatch({ type: 'WASSAVED' });
		} catch (error) {
			const errorMessage = `${((error.response || {}).data || {}).message || error.message || ''}`;
			dispatch({ type: 'HASERROR', data: errorMessage });
		}
	};

	const resetItem = async () => {
		dispatch({ type: 'WASRESET' });
	};

	const dismissModal = async () => {
		dispatch({ type: 'MODALDISMISSED' });
	};

	const createError = async (errorMessage) => {
		dispatch({ type: 'HASERROR', data: errorMessage });
	};

	return { data, getData, onChange, onMapMarkerChange, createObject, updateObject, resetItem, dismissModal, createError };
};

export default ItemCrudContext;
