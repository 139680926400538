import React, { useState, useEffect, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../components/Loading';
import { ErrorPage } from '../components/GeneralError';

import { HttpHandler } from './HttpHandler';
import { SetTitle, SetThemeColour, SetFavIcon } from './ThemeHelper';

const ThemeContext = createContext();

const applyTheme = (theme, t) => {
	try {
		SetTitle(theme.displayName, t);
		SetFavIcon(theme.faviconUrl);
		Object.entries(theme.theme ?? {}).map(([key, value]) => {
			SetThemeColour(key, value);
		});
	} catch (error) {
		console.error(error);
	}
};

export const useTheme = () => {
	return useContext(ThemeContext);
};

export const ThemeContextProvider = ({ children }) => {
	const [theme, setTheme] = useState({});
	const [hasLoaded, setHasLoaded] = useState(false);
	const [hasError, setHasError] = useState('');
	const { httpGet } = HttpHandler(`${process.env.REACT_APP_API_PATH}`, true);

	const { t } = useTranslation();

	const getTheme = async () => {
		try {
			const result = await httpGet('/tenant/theme');

			setTheme(result.data);
			applyTheme(result.data, t);
			setHasLoaded(true);
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		}
	};

	useEffect(() => {
		getTheme();
	}, []);

	return <ThemeContext.Provider value={theme}>{hasError === '' ? hasLoaded ? children : <Loading message={t('t.loading')} /> : <ErrorPage message={hasError} />}</ThemeContext.Provider>;
};
