// Note to self: there are a few variations of GroupBy's now. We should clean it up!
export const GroupBy = (items = [], groupByProperty = null) => {
	if (groupByProperty === null) return [{ recId: 1, data: items }];

	// Get list of unique keys from the list
	const keys = items.filter((element, index, self) => index === self.findIndex((x) => x[groupByProperty] === element[groupByProperty])).map((element) => element[groupByProperty]);

	let result = [];
	keys.forEach((element) => {
		const itemElements = items.filter((item) => item[groupByProperty] === element);
		result.push({ recId: element, data: itemElements });
	});

	return result;
};
