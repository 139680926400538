import React from 'react';
import { useTranslation } from 'react-i18next';

export const ButtonIcon = ({ action, icon, isBusy = false, title = '' }) => {
	return (
		<button className={`btn btn-secondary btn-icon btn-sm ml-2 ${isBusy ? 'animated-spin active' : ''}`} onClick={action} title={title}>
			<i className={icon} />
		</button>
	);
};

export const ButtonText = ({ action, text, extraClass, isBusy = false, icon = null, ...rest }) => {
	return (
		<button className={`btn btn-outline-primary btn-sm ml-2 ${extraClass}`} onClick={action} {...rest}>
			{icon && <i className={`fal ${isBusy ? 'fa-sync fa-spin' : icon}  mr-2`} />}
			<div className="btn-text">{text}</div>
		</button>
	);
};

export const ButtonNav = (props) => {
	const { previous, next, isLastPage, hasSelection = false } = props;

	const { t } = useTranslation();

	return (
		<div className="row mt-3">
			<div className="col d-flex justify-content-end">
				{previous && (
					<button className="btn btn-secondary mr-3" onClick={previous}>
						<i className="fal fa-chevron-left mr-1" />
						{t('g.previous')}
					</button>
				)}

				{next && !isLastPage && (
					<button className="btn btn-primary" onClick={next} disabled={!hasSelection}>
						{t('g.next')}
						<i className="fal fa-chevron-right ml-1" />
					</button>
				)}
			</div>
		</div>
	);
};
