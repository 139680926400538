import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/layout/Page';
import { FilterInitial } from './components/FilterInitial';

import { TransactionTable } from './components/TransactionTable';
import { FilterChargePointOptions } from '../../components/filters/FilterChargePointOptions';
import { BreadCrumb } from '../../components/layout/BreadCrumb';
import { FilterDate } from '../../components/filters/FilterDate';
import { FilterRecords } from '../../components/filters/FilterRecords';
import { PagedListContext } from '../../dataAccess/PagedListContext';
import ItemCrudContext from '../../dataAccess/ItemCrudContext';
import { FilterHelper } from '../../utils/FilterHelper';

import { TransactionGroupByType } from '../../enums/transactionGroupByTypes';
import Toaster from '../../components/toasts/Toaster';

const mapData = (items = [], filter = null) => {
	const locations = [
		...items.map((element) => {
			return { recId: element.locationId, identifier: element.parentName, checked: false, description: element.locationName };
		}),
	].filter((element, index, self) => index === self.findIndex((x) => x.recId === element.recId));

	const chargePoints = [
		...items.map((element) => {
			return { locationId: element.locationId, recId: element.recId, identifier: element.identity, checked: false, description: element.physicalReference ?? element.identity ?? '<not set>' };
		}),
	].filter((element) => FilterHelper.filterItem(element, FilterHelper.getFilterItemId(filter)));

	return { locations, chargePoints };
};

export const TransactionFilter = () => {
	const navigate = useNavigate();
	const [filter, setFilter] = useState([]);
	const [stepSize, setStepSize] = useState(1);
	const [optIndex, setOptIndex] = useState(0);
	const [useData, setDataToUse] = useState(0);
	const [groupByParameter, setGroupByParameter] = useState(TransactionGroupByType.locations);
	const [pageKey, setPageKey] = useState(0);
	const [liveData, setLiveData] = useState();
	const [rangedData, setRangedData] = useState();
	const [items, setItems] = useState([]);
	const [isBusy, setIsBusy] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [hasTimeout, setHasTimeout] = useState(false);
	const [showToast, setShowToast] = useState(true);

	const { t } = useTranslation();

	// Selection Data
	const { data: locationData, getData: getLocationData } = PagedListContext('chargePoint', null, 300000);
	const { data: cardData, getData: getCards } = PagedListContext('chargeTag', null, 300000);
	const { data: usersData, getData: getUsers } = ItemCrudContext('lookup/customerUsers', null, []);

	// Live Data
	const { getData: getLiveData } = PagedListContext('chargeTransaction/inprogress/true?pageSize=0', null, 0);
	const { getData: getRangedData } = PagedListContext(`chargeTransaction`, null, 10000);

	const { locations, chargePoints } = mapData(locationData.items, filter.at(-2) ?? []);
	const chargeCards = cardData.items?.map((element) => {
		return { recId: element.recId, description: element.description, checked: false, identifier: element.visibleNumber };
	});

	const users = usersData.item?.map((element) => {
		return { recId: element.value, description: element.text, checked: false };
	});

	const updateFilter = async (filterItem) => {
		filter.push(filterItem);
		await navForward(filterItem);
	};

	const fetchData = async (filterItem = null) => {
		setIsBusy(true);
		let nextIndex;
		if (filterItem) {
			nextIndex = optIndex + filterItem.stepSize;
			setStepSize(stepSize);
			setOptIndex(nextIndex);
		} else {
			nextIndex = 7;
		}

		// is this the last step
		if (nextIndex === 7) {
			const { dataType = '', dateStart, dateEnd } = filter.at(-1) ?? {};
			if (dataType === 'liveTransactions') {
				getLiveData().then((data) => {
					setCallbackData(data);
				});
				setDataToUse(1);
				setGroupByParameter(TransactionGroupByType.locations);
			} else if (dataType === 'dates') {
				const { dataType: startType = '' } = filter.at(0) ?? {};
				setGroupByParameter(startType);
				let selectedItems = [];
				let selectedItem = null;
				filter.forEach((element) => {
					if (element.multiSelect === false) {
						selectedItem = element.selection[0].recId;
					} else if (element.dataType === 'selection') {
						selectedItems = element.selection.map((element) => element.recId);
					}
				});
				let selectedStr = selectedItems.join();
				if (selectedStr !== '') selectedStr = '/' + selectedStr;
				const paramString = `${
					selectedItems.length === 0 && selectedItem === null ? '' : startType + `/${selectedItem === null ? '' : selectedItem + '/'}`
				}${dateStart}/${dateEnd}${selectedStr}`;
				getRangedData('', paramString).then((data) => {
					setCallbackData(data);
				});
				setDataToUse(2);
			}
		}
	};

	const setCallbackData = (data) => {
		setItems(data ? data.items : []);
		setIsBusy(data.isBusy);
		setHasTimeout(data.hasTimeout);
	};

	const navForward = async (filterItem) => {
		fetchData(filterItem);
	};

	const navBackward = () => {
		resetToastState();
		const filterItem = filter.at(-1) ?? { stepSize: 1 };
		const prevIndex = optIndex - filterItem.stepSize;

		filter.pop();
		setFilter(filter);

		if (prevIndex < 0) navigate(-1);
		else setOptIndex(prevIndex);
	};

	useEffect(() => {
		getLocationData();
		getCards();
		getUsers();
	}, []);

	const resetToastState = () => {
		setShowToast(false);
		setHasTimeout(false);
	};

	const handleRetry = async () => {
		resetToastState();
		await fetchData();
	};

	const handleOnClose = () => {
		resetToastState();
	};

	return (
		<Page>
			<BreadCrumb title={t('t.transactions')} filter={filter} navBackward={navBackward} />

			{optIndex === 0 && <FilterInitial setFilter={updateFilter} />}
			{optIndex === 1 && <FilterRecords heading={t('t.cards')} setFilter={updateFilter} stepSize={5} items={chargeCards} isLoading={cardData.isBusy} />}
			{optIndex === 2 && <FilterRecords heading={t('t.locations')} setFilter={updateFilter} stepSize={1} multiSelect={false} items={locations} isLoading={locationData.isBusy} />}
			{optIndex === 3 && <FilterChargePointOptions setFilter={updateFilter} allChargePointsStep={3} />}
			{optIndex === 4 && <FilterRecords heading={t('cp.chargePoints')} setFilter={updateFilter} stepSize={2} multiSelect={true} items={chargePoints} />}
			{optIndex === 5 && <FilterRecords heading={t('t.users')} setFilter={updateFilter} stepSize={1} multiSelect={true} items={users} isLoading={usersData.isBusy} />}

			{optIndex === 6 && <FilterDate setFilter={updateFilter} stepSize={1} />}
			{optIndex === 7 && <TransactionTable filter={filter} items={!hasError ? items : []} isLoading={isBusy} groupByParameter={groupByParameter} showLocation={false} />}

			<Toaster title={t('to.timeoutMessage')} show={(showToast && hasTimeout) || hasTimeout} onClose={handleOnClose}>
				<span style={{ fontSize: '0.875rem', textDecoration: 'underline' }} onClick={handleRetry} className="text-white text-decoration-line text-sm p-1 ml-4 pointer align-middle">
					{t('to.retryLabel')}
				</span>
			</Toaster>
		</Page>
	);
};
