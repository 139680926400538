import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import { ThemeContextProvider } from './utils/ThemeContextProvider';
import { AuthProvider } from './utils/Auth';

import './styles.scss';

import { setupLanguages } from './i18n';

setupLanguages();

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
	// <React.StrictMode>
	<ThemeContextProvider>
		<AuthProvider>
			<App />
		</AuthProvider>
	</ThemeContextProvider>
	// </React.StrictMode>
);
