import React from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from '../assets/images/loading.svg';

const Loading = (props) => {
	const { t } = useTranslation();
	const { message } = props;

	return (
		<div className="spinner d-flex flex-column justify-content-center align-items-center">
			<img className="spinner-img" src={Spinner} alt={t('g.loading')} />
			<p className="text-center">{message}</p>
		</div>
	);
};

export default Loading;
