import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthWrapper } from './components/AuthWrapper';
import { ErrorAlert, NotificationAlert } from '../../components/GeneralError';

import { useAuth } from '../../utils/Auth';
import { InputValidityHelper } from '../../utils/InputValidityHelper';

const ResetPasswordMessage = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	return (
		<>
			{t('l.passwordResetSuccessful')}{' '}
			<a onClick={() => navigate('/', { replace: true })} style={{ cursor: 'pointer' }}>
				{t('u.loginPage')}
			</a>
			. {t('l.youWillBeRedirected')}
		</>
	);
};

export const ResetPassword = (props) => {
	const { oobCode = null } = useParams();
	const navigate = useNavigate();
	const { resetPassword } = useAuth();
	const [credentials, setCredentials] = useState({});
	const [hasError, setHasError] = useState(null);
	const [isDone, setIsDone] = useState(false);
	const [isBusy, setIsBusy] = useState(false);

	const { t } = useTranslation();

	const isValid = InputValidityHelper.isPasswordUpdateFormValid(hasError, oobCode, credentials, 'password', 'passwordConfirm');

	const isValidPassword = (password) => {
		if (!password || password === '') return false;

		return password.length >= 8 ? true : false;
	};

	const onChange = (e) => {
		setHasError(null);
		const { currentTarget } = e;
		const { name, value } = currentTarget;

		const newCredentials = { ...credentials, [name]: value };
		setCredentials(newCredentials);
	};

	const doReset = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		setIsDone(false);
		try {
			await resetPassword(oobCode, credentials.password);
			setIsDone(true);
			setTimeout(() => {
				navigate('/', { replace: true });
			}, 3000);
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	return (
		<div className="row h-100 d-flex justify-content-md-center">
			<AuthWrapper>
				<form>
					{hasError && <ErrorAlert message={hasError} margin="" />}
					{isDone && <NotificationAlert message={t('l.resetPassword')} margin="" />}

					<p>{t('l.completePasswordResetAccount')}</p>
					<div className="form-group">
						<label>{t('l.newPassword')}</label>
						<input className="form-control mb-3" type="password" placeholder={t('l.newPassword')} name="password" onChange={onChange} autoComplete="off" />
						{!isValidPassword(credentials.password) && credentials.password && <span className="input-error">{t('e.passwordCharacters')}</span>}
					</div>

					<div className="form-group">
						<label>{t('l.confirmNewPassword')}</label>
						<input className="form-control mb-3" type="password" placeholder={t('l.confirmNewPassword')} name="passwordConfirm" onChange={onChange} autoComplete="off" />
						{!isValidPassword(credentials.passwordConfirm) && credentials.passwordConfirm && <span className="input-error">{t('e.passwordCharacters')}</span>}
					</div>

					<div className="d-flex justify-content-between align-items-center mt-3">
						<button className="btn btn-primary" onClick={doReset} disabled={!isValid || isBusy}>
							{isBusy && <i className="fal fa-refresh fa-spin mr-2" />}
							{t('l.resetPassword')}
						</button>
						{props.languageSelect}
					</div>
				</form>
			</AuthWrapper>
		</div>
	);
};
