import React from 'react';
import { useTranslation } from 'react-i18next';

export const ChargePointForm = ({ chargePoint }) => {
	const { vendor, model, identity } = chargePoint;

	const { t } = useTranslation();

	return (
		<form>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('cp.make')}</label>
					<input className="form-control form-control-sm" type="text" name="vendor" defaultValue={vendor ?? t('cp.notAvailable')} />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('cp.model')}</label>
					<input className="form-control form-control-sm" type="text" name="model" defaultValue={model ?? t('cp.notAvailable')} />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('cp.registrationNumber')}</label>
					<input className="form-control form-control-sm" type="text" name="identity" defaultValue={identity ?? t('cp.notAvailable')} />
				</div>
			</div>
		</form>
	);
};
