import React from 'react';
import GoogleMapReact from 'google-map-react';

import { useTheme } from '../../utils/ThemeContextProvider';
import LocationMarker from './components/markers/LocationMarker';

import './MapStatic.css';

export const MapStatic = ({ markerLat = 0, markerLng = 0 }) => {
	const theme = useTheme();
	const defaultPosition = { lat: markerLat ?? theme.latitude ?? 0, lng: markerLng ?? theme.longitude ?? 0 };

	const hasCoordinates = (coordinate) => {
		return coordinate === undefined || coordinate === null || coordinate === 0;
	};

	return (
		<div style={{ height: '100%', width: '100%' }}>
			{hasCoordinates(markerLat) && hasCoordinates(markerLng) ? (
				<span className="no-coords-container">
					<i className="fa-regular fa-location-dot-slash no-coords-icon" />
				</span>
			) : (
				<GoogleMapReact
					bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
					defaultCenter={defaultPosition}
					defaultZoom={theme?.defaultZoom ?? 15}
					options={(map) => {
						return {
							mapTypeControl: true,
						};
					}}
				>
					<LocationMarker lat={markerLat} lng={markerLng} />
				</GoogleMapReact>
			)}
		</div>
	);
};
