export const downloadCSVFile = (csvData) => {
	// Create a Blob object with the CSV string
	var blob = new Blob([csvData], { type: 'text/csv' });

	// Create a download link for the Blob and trigger a download
	var link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = 'sheet.csv';
	link.click();
};

export const downloadPDFFile = (data, filename = null) => {
	const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
	const link = document.createElement('a');

	link.href = url;
	link.setAttribute('download', filename || 'pdf_file_download'); // Setting the filename for download

	// Append to the document and trigger download
	document.body.appendChild(link);
	link.click();

	// Clean up by removing the link
	document.body.removeChild(link);
	window.URL.revokeObjectURL(url);
};
