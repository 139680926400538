import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

const ConnectorStatus = {
	UNKNOWN: { statusColour: 'dark', statusIcon: 'fa-question' },
	AVAILABLE: { statusColour: 'success', statusIcon: 'fa-check' },
	PREPARING: { statusColour: 'info', statusIcon: 'fa-pause' },
	CHARGING: { statusColour: 'primary', statusIcon: 'fa-bolt' },
	SUSPENDEDEV: { statusColour: 'warning', statusIcon: 'fa-check' },
	SUSPENDEDEVSE: { statusColour: 'warning', statusIcon: 'fa-check' },
	FINISHING: { statusColour: 'info', statusIcon: 'fa-check-double' },
	RESERVED: { statusColour: 'secondary', statusIcon: 'fa-calendar-check' },
	UNAVAILABLE: { statusColour: 'dark', statusIcon: 'fa-times' },
	FAULTED: { statusColour: 'danger', statusIcon: 'fa-times' },
	OCCUPIED: { statusColour: 'secondary', statusIcon: 'fa-ban' },
};

const DeviceStatusIcon = ({ item, statusText }) => {
	const { t } = useTranslation();
	const icon = item?.isOnline ? 'fa-check' : 'fa-exclamation';

	return (
		<Fragment>
			<i className={`fas ${icon} mr-1`} title={statusText} />
			{item?.notificationsPaused ? <i className="mr-1 ml-1 fal fa-exclamation" title={t('c.notificationsPaused')} /> : null}
		</Fragment>
	);
};

const DeviceStatus = ({ item }) => {
	const { t } = useTranslation();
	const statusText = item?.isOnline ? t('g.online') : t('g.offline');
	const statusColour = item?.isOnline ? 'success' : 'danger';

	return (
		<span title={`${t('cp.chargePointStatus')} ${statusText}`} className={`badge-opacity connector-badge badge badge-${statusColour} p-1 pl-2 pr-2 mr-1`}>
			<DeviceStatusIcon item={item} statusText={statusText} />
			{statusText}
		</span>
	);
};

const ConnectorStatusPills = ({ item, connectorPropName, indexPropName, connectorReferencePropName, showDeviceStatus = true, extraInfo = false }) => {
	const { t } = useTranslation();
	if (!item) return null;

	return (
		<div className="connectors-container">
			{showDeviceStatus && <DeviceStatus item={item} />}

			{(item[connectorPropName] || item[connectorPropName].length > 0) &&
				item[connectorPropName].map((connector, index) => {
					const { statusColour, statusIcon } = ConnectorStatus[connector.status.toUpperCase()];

					// Hide connnector with Index property of 0
					if (connector[indexPropName] > 0) {
						return extraInfo ? (
							<span
								title={`${t('g.connector')} ${connector[indexPropName]}: ${t(`con.${connector.status.toLowerCase()}`)}`}
								className={`badge-opacity connector-badge connector-item connector-extra-info mr-1`}
								key={index}>
								<span className={`connector-extra-info-icon badge badge-${statusColour}`}>
									<i className={`fas ${statusIcon}`} />
								</span>
								<span className="connector-inner ml-1">
									<span className="font-italic">{connector[connectorReferencePropName] || t('g.connector') + connector[indexPropName]}</span>
									<span className="font-weight-bolder">{!connector.status ? t(`con.unknown`) : t(`con.${connector.status.toLowerCase()}`)}</span>
								</span>
							</span>
						) : (
							<span
								title={`${t('g.connector')} ${connector[connectorReferencePropName] || connector[indexPropName]}: ${t(`con.${connector.status.toLowerCase()}`)}`}
								className={`badge-opacity connector-badge connector-item badge badge-${statusColour} p-1 pl-2 pr-2 mr-1`}
								key={index}>
								<i className={`fas ${statusIcon} mr-1`} />
								{!connector.status ? t(`con.unknown`) : t(`con.${connector.status.toLowerCase()}`)}
							</span>
						);
					}
				})}
		</div>
	);
};

export { ConnectorStatusPills };
export default ConnectorStatus;
