import React from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { TileImage } from '../../../components/tiles/TileImage';
import { TransactionGroupByType } from "../../../enums/transactionGroupByTypes";

export const FilterInitial = (props) => {
	const { setFilter } = props;

	const { t } = useTranslation();

	const updateFilter = (text, description, dataType, stepSize) => {
		setFilter({ text: text, dataType: dataType, stepSize: stepSize, description: description });
	};

	return (
		<Card>
			<CardBody className="mt-3 mb-3">
				<Row className="justify-content-start">
					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-0 offset-lg-3">
						<TileImage icon="fal fa-building" title={t('t.individualLocations')} onClick={() => updateFilter('Individual Locations', '', TransactionGroupByType.locations, 2)} />
					</Col>

					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
						<TileImage icon="fal fa-house-building" title={t('t.allLocations')} onClick={() => updateFilter('All Locations', t('t.allLocations'), TransactionGroupByType.locations, 6)} />
					</Col>

					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-0 offset-lg-3">
						<TileImage icon="fal fa-credit-card-blank" title={t('t.individualCards')} onClick={() => updateFilter('Individual Cards', '', TransactionGroupByType.chargeTokens, 1)} />
					</Col>

					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 ">
						<TileImage icon="fal fa-cards-blank" title={t('t.allCards')} onClick={() => updateFilter('All Cards', t('t.allCards'), TransactionGroupByType.chargeTokens, 6)} />
					</Col>

					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-0 offset-lg-3">
						<TileImage icon="fal fa-user" title={t('t.individualUsers')} onClick={() => updateFilter('Individual Users', '', TransactionGroupByType.users, 5)} />
					</Col>
					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
						<TileImage icon="fal fa-users" title={t('t.allUsers')} onClick={() => updateFilter('All Users', t('t.allUsers'), TransactionGroupByType.users, 6)} />
					</Col>

					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-0 offset-lg-3">
						<TileImage icon="fal fa-wave-pulse" title={t('t.liveTransactions')} onClick={() => updateFilter('Live Transactions', t('t.liveTransactions'), 'liveTransactions', 7)} />
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
