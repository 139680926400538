import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { CardWithHeaderActions } from '../../components/layout/CardContent';

import { BreadCrumb } from '../../components/layout/BreadCrumb';
import { Page } from '../../components/layout/Page';
import { InvoiceQuery } from './components/InvoiceQuery';

import { ProblemReport } from './components/ProblemReport';
import { RequestCallback } from './components/RequestCallback';
import { RequestTraining } from './components/RequestTraining';
import { NewFeature } from './components/NewFeature';
import { useAuth } from '../../utils/Auth';
import { HttpHandler } from '../../utils/HttpHandler';
import { ErrorAlert, NotificationAlert } from '../../components/GeneralError';
import { OrderChargePoint } from './components/OrderChargePoint';
import { OrderChargeCards } from './components/OrderChargeCards';
import { ChargeCardFilter } from '../chargeCards/ChargeCardFilter';
import { ChargePointFilter } from '../chargePoints/ChargePointFilter';
import { ContactUs } from './components/ContactUs';

const convertTypeToText = (type, t) => {
	switch (type) {
		case 'contactUs':
			return t('c.contactUs');
		case 'problem':
			return t('cs.reportAProblem');
		case 'callback':
			return t('cs.requestCallback');
		case 'invoice':
			return t('cs.invoiceRelatedQuery');
		case 'training':
			return t('cs.requestTraining');
		case 'feature':
			return t('cs.suggestANewFeature');
		case 'orderChargePoints':
			return t('cs.orderNewChargePoints');
		case 'orderChargeCards':
			return t('cs.orderChargeCards');

		default:
			return t('cs.customerSupport');
	}
};

const ConvertTypeToForm = ({ type, setHasError, setHasSuccess }) => {
	const { user } = useAuth();
	const { httpPost } = HttpHandler();
	const [isBusy, setIsBusy] = useState(false);
	const [isDirty, setIsDirty] = useState(false);

	const { t } = useTranslation();

	const [data, setData] = useState({
		type: type,
		contactName: /*user.displayName ??*/ '',
		contactNumber: /*user.phone ??*/ '',
		contactEmail: /*user.email ??*/ '',
	});

	const onChange = (event) => {
		const { name, value } = event.currentTarget;

		setHasError('');
		setHasSuccess('');
		setIsDirty(true);
		setData({ ...data, [name]: value });
	};

	const onSubmit = async (event) => {
		event.preventDefault();

		setIsBusy(true);
		setHasError('');
		setHasSuccess('');
		try {
			const result = await httpPost('/support', null, data);
			if (result) {
				setHasSuccess(result.data?.message ?? t('cs.requestSubmittedSuccessfully'));
				setIsDirty(false);
			} else throw new Error(t('cs.error'));
		} catch (error) {
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	const onReset = (event) => {
		event.preventDefault();
		let defaultData = {};

		Object.keys(data).map((key) => {
			if (key === 'contactName') defaultData[key] = user.displayName ?? '';
			else if (key === 'contactNumber') defaultData[key] = user.phone ?? '';
			else if (key === 'contactEmail') defaultData[key] = user.email ?? '';
			else if (key === 'type') defaultData[key] = type;
			else if (key === 'chargePointList') defaultData[key] = [];
			else if (key === 'chargeCardList') defaultData[key] = [];
			else defaultData[key] = '';
		});

		setIsDirty(false);
		setData({ ...defaultData });

		console.debug('data', data);
		console.debug('new', defaultData);
	};

	switch (type) {
		case 'contactUs':
			return <ContactUs data={data} btnSubmitClick={onSubmit} btnResetClick={onReset} isBusy={isBusy} isDirty={isDirty} onChange={onChange} />;
		case 'problem':
			return <ProblemReport data={data} btnSubmitClick={onSubmit} btnResetClick={onReset} isBusy={isBusy} isDirty={isDirty} onChange={onChange} />;
		case 'callback':
			return <RequestCallback data={data} btnSubmitClick={onSubmit} btnResetClick={onReset} isBusy={isBusy} isDirty={isDirty} onChange={onChange} />;
		case 'invoice':
			return <InvoiceQuery data={data} btnSubmitClick={onSubmit} btnResetClick={onReset} isBusy={isBusy} isDirty={isDirty} onChange={onChange} />;
		case 'training':
			return <RequestTraining data={data} btnSubmitClick={onSubmit} btnResetClick={onReset} isBusy={isBusy} isDirty={isDirty} onChange={onChange} />;
		case 'feature':
			return <NewFeature data={data} btnSubmitClick={onSubmit} btnResetClick={onReset} isBusy={isBusy} isDirty={isDirty} onChange={onChange} />;
		case 'orderChargePoints':
			return <OrderChargePoint data={data} btnSubmitClick={onSubmit} btnResetClick={onReset} isBusy={isBusy} isDirty={isDirty} onChange={onChange} />;
		case 'orderChargeCards':
			return <OrderChargeCards data={data} btnSubmitClick={onSubmit} btnResetClick={onReset} isBusy={isBusy} isDirty={isDirty} onChange={onChange} />;

		default:
			return 'Customer Support';
	}
};

export const ContactPage = (props) => {
	const { type } = useParams();
	const navigate = useNavigate();
	const [hasError, setHasError] = useState('');
	const [hasSuccess, setHasSuccess] = useState('');

	const { t } = useTranslation();

	const navBackward = () => {
		navigate(-1);
	};

	if (type === 'stopChargeCard') return <ChargeCardFilter title={t('cs.putCardsOnStop')} multiSelect={true} stepSizeInitial={2} />;
	if (type === 'removeChargePoint') return <ChargePointFilter title={t('cs.removeChargePoint')} stepSizeInitial={2} />;

	return (
		<Page>
			<BreadCrumb title={t('cs.customerSupport')} filter={[{ description: convertTypeToText(type, t) }]} navBackward={navBackward} />
			<CardWithHeaderActions heading={convertTypeToText(type, t)}>
				<ErrorAlert message={hasError} margin={0} />
				<NotificationAlert message={hasSuccess} margin={0} />
				<ConvertTypeToForm type={type} setHasError={setHasError} setHasSuccess={setHasSuccess} />
			</CardWithHeaderActions>
		</Page>
	);
};
