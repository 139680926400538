import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const BreadCrumb = ({ title, filter, navBackward = null }) => {

	const { t } = useTranslation();
	
	return (
		<div className="d-flex mt-2 mb-3 d-print-none flex-column flex-sm-row">
			{navBackward && (
				<Link to="#" onClick={navBackward} className="btn btn-sm btn-white text-body mb-3 mb-sm-0">
					<i className="fal fa-chevron-left mr-1 fa-xs"></i>
					{t('g.back')}
				</Link>
			)}

			<div className="d-flex flex-grow-1 justify-content-center p-0 m-0">
				<div className="breadcrumb text-gray-600 justify-content-center text-center">
					<span className="breadcrumb-item">{title}</span>

					{filter?.map((item, index) =>
						item.description !== '' ? (
							<span key={index} className="breadcrumb-item">
								{item.description}
							</span>
						) : null
					)}
				</div>
			</div>
		</div>
	);
};
