import React, { Fragment, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { utils, writeFileXLSX } from 'xlsx';
import _ from 'lodash';

import { CardWithHeaderActions } from '../../../components/layout/CardContent';
import { GroupedTable } from '../../../components/Tables';
import { EmptyData } from '../../../components/EmptyData';

import { Settings } from '../../../utils/Settings';
import { GroupBy } from '../../../utils/GroupByHelper';
import Loading from '../../../components/Loading';
import { downloadCSVFile } from '../../../utils/JsUtils';

import { TransactionGroupByType } from '../../../enums/transactionGroupByTypes';

const cleanSheetName = (sheetName) => {
	return sheetName.replace(/[/\\?%*:|"<>]/g, '-');
};

const calculateTotal = (list = null, property = null) => {
	if (list === null || property === null) return 0;

	let total = 0;
	list.forEach((element) => {
		total += element[property];
	});

	return total;
};

const calculateAverage = (list = null, property = null) => {
	if (list === null || property === null) return 0;

	let total = 0;
	let count = 0;
	list.forEach((element) => {
		total += element[property];
		count++;
	});

	return total / count;
};

const groupTemplate = (item = null, rest, t) => {
	if (item === null) return null;

	const { showCard = true, showLocation = true, label = '', title = t('t.total') } = rest;
	const avgSpeed = Settings.formatNumber(calculateAverage(item.data, 'transactionChargeRate'), 2);
	const totalDuration = Settings.formatDuration(calculateTotal(item.data, 'transactionDuration') * 1000, null, 'HH:mm:ss');
	const totalEnergy = Settings.formatNumber(calculateTotal(item.data, 'transactionEnergyUsed'), 2);
	const totalBilled = Settings.formatNumber(calculateTotal(item.data, 'transactionBilledAmountExcl'), 2);

	return (
		<Fragment>
			<tr className="text-primary d-none d-md-table-row">
				<td className="d-print-none p-0 pb-2 pt-2 pr-1"></td>

				{showCard && <td className="p-0 pb-2 pt-2 pr-1"></td>}
				{showLocation && <td className="p-0 pb-2 pt-2 pr-1"></td>}

				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>

				<td className="text-right p-0 pb-2 pt-2 pr-1">{title}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{avgSpeed}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalDuration}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalEnergy}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalBilled}</td>
			</tr>

			{/*------ Mobile View ------*/}
			<tr className="text-primary d-table-row d-md-none">
				<div className="d-flex pt-2">
					<div className="flex-grow-1">{title}</div>
					<div className="flex-grow-1 text-right">{`${totalEnergy} kWh`}</div>
					{/* <div className="ml-2">{totalDuration}</div> */}
				</div>
			</tr>
		</Fragment>
	);
};

const tableFooter = (items = null, rest, t) => {
	if (items === null) return null;

	const { showCard = true, showLocation = true, title = t('t.reportTotal') } = rest;
	const avgSpeed = Settings.formatNumber(calculateAverage(items, 'transactionChargeRate'), 2);
	const totalDuration = Settings.formatDuration(calculateTotal(items, 'transactionDuration') * 1000, null, 'HH:mm:ss');
	const totalEnergy = Settings.formatNumber(calculateTotal(items, 'transactionEnergyUsed'), 2);
	const totalBilled = Settings.formatNumber(calculateTotal(items, 'transactionBilledAmountExcl'), 2);

	return (
		<Fragment>
			{/*------ Web View ------*/}
			<tr className="text-secondary d-none d-md-table-row">
				<td className="p-0 pb-2 pt-2 pr-1 d-print-none"></td>

				{showCard && <td className="p-0 pb-2 pt-2 pr-1"></td>}
				{showLocation && <td className="p-0 pb-2 pt-2 pr-1"></td>}

				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>

				<td className="text-right p-0 pb-2 pt-2 pr-1">{title}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{avgSpeed}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalDuration}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalEnergy}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalBilled}</td>
			</tr>

			{/*------ Mobile View ------*/}
			<tr className="text-secondary d-table-row d-md-none">
				<div className="d-flex pt-2">
					<div className="flex-grow-1">{title}</div>
					<div className="flex-grow-1 text-right">{`${totalEnergy} kWh`}</div>
					{/* <div className="ml-2">{totalDuration}</div> */}
				</div>
			</tr>
		</Fragment>
	);
};

const LinkedColumn = ({ columnName, link, linkTitle }) => {
	return (
		<td className="p-1 pb-2 pt-2 pr-1">
			<div className="d-none d-print-block">{columnName}</div>
			<Link className="d-print-none p-0" to={link}>
				{linkTitle ?? ''}
			</Link>
		</td>
	);
};

const tableRow = (item, index, rest) => {
	const { showCard = true, showLocation = true, groupByParameter = TransactionGroupByType.chargeTokens } = rest;

	const getColumnsForGroupBy = () => {
		switch (groupByParameter) {
			case TransactionGroupByType.chargeTokens:
				return (
					<Fragment>
						{showCard && (
							<LinkedColumn
								key={`${index}-card`}
								columnName={item.chargeTokenDescription}
								link={`/chargeCards/${item.chargeTokenId}`}
								linkTitle={`${item.chargeTokenDescription} ${item.chargeTokenReference ? '| ' + item.chargeTokenReference : ''}`}
							/>
						)}
						{showLocation && (
							<LinkedColumn
								key={`${index}-location`}
								columnName={item.chargePointPhysicalReference}
								link={`/chargePoint/${item?.chargePointId}`}
								linkTitle={`${item.chargePointLocation ?? ''} ${item.chargePointPhysicalReference ?? ''}`}
							/>
						)}
					</Fragment>
				);
			case TransactionGroupByType.users:
				return (
					<Fragment>
						<LinkedColumn
							key={`${index}-location`}
							columnName={item.chargePointPhysicalReference}
							link={`/chargePoint/${item?.chargePointId}`}
							linkTitle={`${item.chargePointLocation ?? ''} ${item.chargePointPhysicalReference ?? ''}`}
						/>
						<LinkedColumn
							key={`${index}-card`}
							columnName={item.chargeTokenDescription}
							link={`/chargeCards/${item.chargeTokenId}`}
							linkTitle={`${item.chargeTokenDescription} ${item.chargeTokenReference ? '| ' + item.chargeTokenReference : ''}`}
						/>
					</Fragment>
				);
			default:
				return (
					<Fragment>
						{showLocation && (
							<LinkedColumn key={`${index}-location`} columnName={item.chargePointLocation} link={`/chargePoint/${item?.chargePointId}`} linkTitle={item.chargePointPhysicalReference} />
						)}
						{showCard && (
							<LinkedColumn
								key={`${index}-card`}
								columnName={item.chargeTokenDescription}
								link={`/chargeCards/${item.chargeTokenId}`}
								linkTitle={`${item.chargeTokenDescription} ${item.chargeTokenReference ? '| ' + item.chargeTokenReference : ''}`}
							/>
						)}
					</Fragment>
				);
		}
	};

	return (
		<Fragment>
			{/*------ Web View ------*/}
			<tr key={index} className="d-none d-md-table-row ">
				<td className="p-0 pr-0 pb-2 pt-2 text-left d-print-none" style={{ width: 5 }}>
					<div className="d-flex align-items-baseline">
						<Link className="d-print-none" to={`/session/${item?.transactionId}`}>
							<i className="fas fa-magnifying-glass" />
						</Link>
						{Settings.emptyDate(item?.transactionTimeEnd) && <i className="fal fa-bolt ml-2 p-0 text-primary" />}
					</div>
				</td>

				{/* Below will retrieve the correct fragments to render based on the groupByParameter. */ getColumnsForGroupBy()}

				<td className="text-right text-md-left pl-lg-0 p-1 pb-2 pt-2 pr-1">{Settings.formatDate(item.transactionTimeStart, 'date')}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{item.transactionConnectorPhysicalReference || item.transactionConnector}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatDate(item.transactionTimeStart, 'time')}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatNumber(item.transactionChargeRate, 2)}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatDuration(item.transactionDuration * 1000, null, 'HH:mm:ss')}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatNumber(item.transactionEnergyUsed, 2)}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatNumber(item.transactionBilledAmountExcl, 2)}</td>
			</tr>

			{/*------ Mobile View ------*/}
			<tr key={`${index}-1`} className="d-table-row d-md-none">
				<td className="border-bottom">
					<div className="d-flex align-items-center">
						<div className="d-flex flex-column flex-grow-1">
							{showCard && (
								<div className="mb-1">
									{Settings.emptyDate(item?.transactionTimeEnd) && <i className="fal fa-bolt mr-1 p-0 text-primary" />}
									<i className="fal fa-cards-blank mr-1" />
									{item.chargeTokenDescription}
								</div>
							)}

							<div className="mb-1">
								<i className="fal fa-calendar mr-1" />
								{Settings.formatDate(item.transactionTimeStart, 'datetime')}
							</div>

							<div className="d-flex">
								<div className="flex-grow-1">
									<i className="fal fa-battery-bolt mr-1" />
									{Settings.formatNumber(item.transactionEnergyUsed, 2)} kWh
								</div>
								<div className="flex-grow-1 text-right">
									<i className="fal fa-clock mr-1" />
									{Settings.formatDuration(item.transactionDuration * 1000, null, 'HH:mm:ss')}
								</div>
							</div>
						</div>
						<div className="ml-3">
							<Link className="d-print-none" to={`/session/${item?.transactionId}`}>
								<i className="fal fa-chevron-right font-weight-bold" />
							</Link>
						</div>
					</div>
				</td>
			</tr>
		</Fragment>
	);
};

const HeaderActions = ({ action }) => {
	const { hasData, printForm, downloadFile } = action;

	const [showDropdown, setShowDropdown] = useState(false);

	const { t } = useTranslation();

	const toggleDropDown = () => {
		setShowDropdown(!showDropdown);
	};

	const triggerDownload = (e, format) => {
		e.preventDefault();
		toggleDropDown();
		downloadFile(format);
	};

	return (
		<Fragment>
			<div className="d-none d-sm-flex align-items-center d-print-none">
				<button className="btn btn-sm btn-primary mr-2" disabled={!hasData} onClick={printForm}>
					<i className="fal fa-print mr-1" />
					{t('g.print')}
				</button>

				<button className="btn btn-sm btn-accent-1 mr-2" disabled={!hasData}>
					<i className="fal fa-envelope mr-1" />
					{t('g.email')}
				</button>

				<div className="dropdown">
					<button className="btn btn-sm btn-outline-secondary mr-2 dropdown-toggle" disabled={!hasData} onClick={toggleDropDown}>
						<i className="fal fa-download mr-2" />
						{t('g.download')}
					</button>
					<div className={`dropdown-menu dropdown-menu-right ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'xlsx')}>
							.xlsx
						</a>
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'csv')}>
							.csv
						</a>
					</div>
				</div>
			</div>

			<div className="d-flex d-sm-none">
				<button className="btn btn-icon btn-primary mr-2" disabled={!hasData} onClick={printForm}>
					<i className="fal fa-print" />
				</button>
				<button className="btn btn-icon btn-secondary mr-2" disabled={!hasData}>
					<i className="fal fa-envelope" />
				</button>
				<div className="dropdown">
					<button className="btn btn-icon btn-outline-secondary dropdown-toggle" disabled={!hasData} onClick={toggleDropDown}>
						<i className="fal fa-download" />
					</button>
					<div className={`dropdown-menu dropdown-menu-right ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'xlsx')}>
							.xlsx
						</a>
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'csv')}>
							.csv
						</a>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const buildHeaders = (showCard = true, showLocation = true, groupByParameter = TransactionGroupByType.chargeTokens, t) => {
	let headers = [];
	headers.push({ title: '', className: 'col-0 d-print-none align-top p-0 pr-1' });

	switch (groupByParameter) {
		case TransactionGroupByType.locations:
			if (showLocation) headers.push({ title: t('t.chargePoint'), className: 'align-top p-0 pr-1' });
			if (showCard) headers.push({ title: t('t.chargeCardUsed'), className: 'align-top p-0 pr-1' });
			break;
		case TransactionGroupByType.chargeTokens:
			if (showCard) headers.push({ title: t('t.chargeCardUsed'), className: 'align-top p-0 pr-1' });
			else if (showLocation) headers.push({ title: t('t.chargePoint'), className: 'align-top p-0 pr-1' });
			break;
		case TransactionGroupByType.users:
			headers.push({ title: t('t.chargePoint'), className: 'align-top p-0 pr-1' });
			headers.push({ title: t('t.chargeCardUsed'), className: 'align-top p-0 pr-1' });
			break;

		default:
			break;
	}

	headers.push({ title: t('t.startDate'), className: 'text-right align-top p-0 pr-3 col-2 col-lg-2 text-md-left' });
	headers.push({ title: t('g.connector'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-2 text-lg-right' });
	headers.push({ title: t('g.startTime'), className: 'text-right align-top p-0 pr-1 col-sm-2 col-lg-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.chargeSpeed'), className: 'text-right align-top p-0 pr-1 col-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.duration'), className: 'text-right align-top p-0 pr-1 col-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.totalEnergy'), className: 'text-right align-top p-0 pr-1 col-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.invoiced'), className: 'text-right align-top p-0 pr-1 col-1' });

	return headers;
};

const groupByFunc = (arr, prop) => {
	const sortedData = _.orderBy(arr, [prop]);
	const reference = prop === 'chargeTokenDescription' ? 'chargeTokenReference' : null;

	return sortedData.reduce((acc, obj) => {
		const key = obj[prop] ?? '';
		const referenceKey = obj[reference] ?? '';
		const combinedKey = referenceKey ? `${key} | ${referenceKey}` : key;

		if (!acc[combinedKey]) {
			acc[combinedKey] = [];
		}
		acc[combinedKey].push(obj);
		return acc;
	}, {});
};

export const TransactionTable = (props) => {
	const printRef = useRef();
	const downRef = useRef();
	const { t } = useTranslation();

	const { title = t('t.chargeTransactions'), items = [], isLoading = true, showCard = true, showLocation = true, groupByParameter = TransactionGroupByType.chargeTokens, loadMore = null } = props;

	let groupBy = [];
	switch (groupByParameter) {
		case TransactionGroupByType.chargeTokens:
			groupBy = ['chargeTokenDescription', 'chargePointPhysicalReference'];
			break;
		case TransactionGroupByType.users:
			groupBy = ['chargeUserEmail', 'chargePointPhysicalReference'];
			break;
		default:
			groupBy = ['chargePointLocation', 'chargePointPhysicalReference'];
			break;
	}

	// Used for display and printing
	let group1 = groupByFunc(items, groupBy[0]);
	let group2 = Object.keys(group1).map((key) => {
		const data = group1[key];
		const groupedData = groupByFunc(data, groupBy[1]);

		return { [key]: groupedData };
	});

	const headers = buildHeaders(showCard, showLocation, groupByParameter, t);
	const printForm = useReactToPrint({ content: () => printRef.current });

	const downloadFile = async (format = 'xlsx') => {
		const workbook = utils.book_new();
		let allData = [{ sheetName: 'All Transactions', sheetData: [] }];

		GroupBy(items, groupBy).forEach((element) => {
			const data = element.data.map((row) => {
				return {
					Location: row.chargePointLocation,
					DeviceId: row.chargePointDeviceId,
					Reference: row.chargePointPhysicalReference,
					Connector: row.transactionConnectorPhysicalReference || row.transactionConnector,
					Card: `${row.chargeTokenDescription} (${row.chargeTokenIdVisibleNumber})`,
					CardReference: row.chargeTokenReference,
					Date: new Date(row.transactionTimeStart),
					Time: Settings.formatDate(row.transactionTimeStart, 'time'),
					Duration: Settings.formatDuration(row.transactionDuration * 1000, null, 'HH:mm:ss'),
					kWh: row.transactionEnergyUsed,
					Invoiced: Settings.formatNumber(row.transactionBilledAmountExcl, 2),
					Tariff: row.transactionBilledTariff?.text,
				};
			});

			const firstRow = element.data[0];
			const { chargePointPhysicalReference, chargePointLocation, chargePointDeviceId } = firstRow;
			let sheetName = `${chargePointPhysicalReference ?? chargePointLocation}-${chargePointDeviceId}`;

			if (sheetName.length > 31) sheetName = sheetName.slice(0, 31);

			allData.push({
				sheetName,
				sheetData: data,
			});

			allData[0].sheetData = [...allData[0].sheetData, ...data];
		});

		allData.forEach((row, index) => {
			const worksheet = utils.json_to_sheet(row.sheetData);
			utils.book_append_sheet(workbook, worksheet, cleanSheetName(row.sheetName));

			if (index === 0 && format === 'csv') downloadCSVFile(utils.sheet_to_csv(worksheet));
		});

		if (format === 'xlsx') writeFileXLSX(workbook, `${title}.xlsx`);
	};

	return (
		<div ref={printRef}>
			<style type="text/css" media="print">
				{
					'\
  @page { size: landscape; }\
'
				}
			</style>
			<CardWithHeaderActions heading={title} actions={<HeaderActions action={{ isLoading: isLoading, hasData: group2.length > 0, printForm: printForm, downloadFile: downloadFile }} />}>
				{isLoading ? (
					<Loading message={`${t('g.loading')} ${title}...`} />
				) : group2.length <= 0 ? (
					<EmptyData />
				) : (
					<Fragment>
						<div ref={downRef}>
							<GroupedTable
								headers={headers}
								items={group2}
								rowTemplate={tableRow}
								groupTemplate={groupBy === null ? null : (items, rest) => groupTemplate(items, rest, t)}
								footTemplate={(items, rest) => tableFooter(items, rest, t)}
								showCard={showCard}
								showLocation={showLocation}
								groupByParameter={groupByParameter}
							/>
						</div>

						{loadMore && (
							<div className="d-flex justify-content-center">
								<button className="btn btn-link text-gray-500 text-xs" onClick={loadMore}>
									{t('a.loadmore')}
								</button>
							</div>
						)}
					</Fragment>
				)}
			</CardWithHeaderActions>
		</div>
	);
};
