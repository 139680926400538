import React, { useState, useEffect } from 'react';

const Toaster = (props) => {
    const { title, type = 'danger', show, children, onClose } = props;
    const [showToast, setShowToast] = useState();

    useEffect(() => {
        setShowToast(show);
    }, [show]);

    if (!showToast && !show) {
        return;
    }
    
	return (
		<div style={{top: '10px', right: '0', zIndex: '99999999', position: 'absolute'}} className={`card p-3 my-2 mr-4 rounded bg-${type}`}>
            <div className='m-auto'>
                <span style={{ fontSize: '0.875rem' }} className='text-white text-sm align-middle'>{title}</span>
                {children}
                <span onClick={onClose} className="fa-solid fa-x text-white text-xs p-1 ml-4 pointer align-middle"></span>
            </div>
		</div>
	);
};

export default Toaster;