import { useReducer, useEffect } from 'react';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useTheme } from '../utils/ThemeContextProvider';
import { HttpHandler } from '../utils/HttpHandler';
import { useTranslation } from 'react-i18next';


const buildLocation = (latitude, longitude, title, type, locationId, chargePointId, status) => {
	return {
		type: 'Feature',
		properties: {
			cluster: false,
			type: type,
			status: status ?? 'Unknown',
			title: title,
			locationId: locationId,
			chargePointId: chargePointId,
		},
		geometry: {
			type: 'Point',
			coordinates: [latitude, longitude],
		},
	};
};

export const MapLocationsContext = () => {
	const theme = useTheme();
	const { httpPost, getAccessToken } = HttpHandler();
	const { t } = useTranslation();

	const defaultZoom = theme.defaultMapZoom === undefined || theme.defaultMapZoom === null || theme.defaultMapZoom === 0 ? 15 : theme.defaultMapZoom;

	const initialState = {
		locations: [],
		locationId: null,
		locationUser: {},
		centre: { lat: theme.latitude ?? 0, lng: theme.longitude },
		bounds: null,
		zoom: defaultZoom,
		hasError: t('e.pleaseWaitLoading')
		,
	};

	const [data, dispatch] = useReducer((prevState, action) => {
		switch (action.type) {
			case 'HASDATA': {
				return {
					...prevState,
					locations: action.data,
					hasError: '',
				};
			}
			case 'LOCATIONSET': {
				return {
					...prevState,
					locationId: action.data,
				};
			}
			case 'USERLOCATIONSET': {
				const { location, centre, zoom } = action.data;
				const userLocation = buildLocation(location.lng, location.lat, 'Your current location', 'user');
				const nonUserLocations = prevState.locations.filter((item) => item.properties.type !== 'user');

				const newLocations = [...nonUserLocations, userLocation];

				return {
					...prevState,
					locations: [...newLocations],
					locationUser: location,
					centre: centre,
					zoom: zoom,
				};
			}
			case 'LOCATIONRESET': {
				return {
					...prevState,
					locationId: null,
				};
			}
			//case 'LOCATIONUPDATED': {
			//     //let currLocations = [...prevState.locations];
			//	//const index = currLocations.findIndex((element) => element.properties.locationId === action.locationId);
			//	//if (index < 0) break;
			//	// Set new status
			//	//currLocations[index].properties.status = action.status;
			//	//return {
			//	//	...prevState,
			//	//	locations: [...currLocations],
			//	//};
			//}
			case 'ZOOMCHANGED': {
				const { zoom, bounds } = action.data;
				return {
					...prevState,
					zoom: zoom,
					bounds: [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat],
				};
			}
			case 'HASERROR': {
				return {
					...prevState,
					hasError: t('e.errorLoadingData'),
				};
			}
			default:
				throw new Error('Unknown action type!');
		}
	}, initialState);

	const getData = async (boundary) => {
		try {
			const result = await httpPost('/chargeLocation/markers', null, {});
			if (result && result.data && result.data.result.length > 0) {
				const locs = result.data.result.map((location) => {
					const {
						coordinates: { lat, lng },
						name,
						locationId,
						chargePointId,
						status,
					} = location;

					return buildLocation(lng, lat, name, 'point', locationId, chargePointId, status);
				});

				dispatch({ type: 'HASDATA', data: locs });
			}
			getUserLocation();
		} catch (error) {
			console.error('Error loading data.', error)
			dispatch({ type: 'HASERROR' });
		}
	};

	const getUserLocation = () => {
		resetLocation();
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const location = { lat: position.coords.latitude, lng: position.coords.longitude };
					dispatch({ type: 'USERLOCATIONSET', data: { location: location, centre: location, zoom: defaultZoom } });
				},
				(error) => {
					console.error('Error Code = ' + error.code + ' - ' + error.message);
				},
				{ enableHighAccuracy: false, timeout: 5000, maximumAge: 60000 }
			);
		} else {
			console.error('Location data not available');
		}
	};

	const mapChanged = async ({ zoom, bounds }) => {
		dispatch({ type: 'ZOOMCHANGED', data: { zoom: zoom, bounds: bounds } });
	};

	const setLocation = (locationId) => {
		dispatch({ type: 'LOCATIONSET', data: locationId });
	};

	const resetLocation = async () => {
		dispatch({ type: 'LOCATIONRESET' });
	};

	useEffect(() => {
		const liveCon = new HubConnectionBuilder()
			.withUrl(`${process.env.REACT_APP_BASE_URL}/api/feed/locationUpdates`, { accessTokenFactory: () => getAccessToken(false) })
			.withAutomaticReconnect()
			.build();

		liveCon
			.start()
			.then(() => {
				console.debug('Subscription started...');
				liveCon.on('locationUpdate', (message) => {
					//const { locationId, status } = JSON.parse(message);
					//dispatch({ type: 'LOCATIONUPDATED', locationId: locationId, status: status });
				});
			})
			.catch((error) => console.error('Live update connection failed!'));
		return () => {
			liveCon.stop();
		};
	}, []);

	return { data, getData, mapChanged, setLocation, getUserLocation, resetLocation };
};
