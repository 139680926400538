import React, { Fragment, useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTheme } from '../../utils/ThemeContextProvider';
import { useAuth } from '../../utils/Auth';
import defaultLogo from '../../assets/images/logo.png';
import ProfileAvatar from '../../assets/images/profile-avatar.jpg';
import { useTranslation } from 'react-i18next';

export default function NavbarContainer() {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);
	const theme = useTheme();
	const { user, logout } = useAuth();

	const { t } = useTranslation();

	const navbarList = [
		{
			title: (
				<Fragment>
					<i className="fa-light fa-home fa-lg d-none d-md-block"></i>
					<div className="d-block d-md-none">{t('g.home')}</div>
				</Fragment>
			),
			to: '/',
		},
		{ title: t('i.invoices'), to: '/invoices' },
		{ title: t('c.contactUs'), to: '/support/contactUs' },
		{ title: t('u.profile'), to: '/profile', css: 'd-md-none' },
		{ title: t('l.logout'), onClick: logout, css: 'btn-light' },
	];

	return (
		<Navbar container={false} expand="md" className="topnav d-flex px-0">
			<NavbarBrand tag={Link} type={Link} to="/" className="mx-auto">
				<img src={theme.logoUrl ?? defaultLogo} alt="Application Logo" style={{ maxHeight: '35px' }} />
			</NavbarBrand>

			<NavbarToggler onClick={toggle} tag={'button'} className="position-absolute text-mute">
				<i className="fa-solid fa-bars" />
			</NavbarToggler>

			<Collapse isOpen={isOpen} navbar style={{ placeContent: 'end' }} className="bg-white sm-shadow">
				<Nav className="me-auto align-items-md-center" navbar>
					{navbarList.map((link, linkId) => (
						<NavItem key={linkId}>
							<NavLink tag={Link} to={link.to} onClick={link.onClick} className={'nav-link btn btn-sm ml-2 ml-md-0 mr-2 px-3 text-dark ' + link.css}>
								{link.title}
							</NavLink>
						</NavItem>
					))}

					<UncontrolledDropdown nav inNavbar className="dropdown-user">
						<DropdownToggle nav>
							<img className="img-fluid d-none d-md-block" src={user.picture ?? ProfileAvatar} width="40" alt="avatar" />
						</DropdownToggle>
						<DropdownMenu end className="dropdown-menu-right shadow animated--fade-in-up mb-4">
							<DropdownItem header className="d-flex align-items-center">
								<img className="dropdown-user-img" src={user.picture ?? ProfileAvatar} width="40" alt="avatar" />
								<div className="dropdown-user-details">
									<div className="dropdown-user-details-name">{user?.displayName ?? 'Username'}</div>
									<div className="dropdown-user-details-email">{user?.email ?? 'email address'}</div>
								</div>
							</DropdownItem>
							<DropdownItem divider />
							<DropdownItem tag={Link} to="/profile">
								<i className="fal fa-user dropdown-item-icon"></i>
								<span className="p-2 text-dark">{t('u.profile')}</span>
							</DropdownItem>
							<DropdownItem tag={Link} to="/settings">
								<i className="fal fa-cog dropdown-item-icon"></i>
								<span className="p-2 text-dark">{t('g.settings')}</span>
							</DropdownItem>
							<DropdownItem tag={'div'}>
								<i className="fal fa-info-circle dropdown-item-icon"></i>
								<span className="p-2 text-muted">{`${t('g.version')}: ${process.env.REACT_APP_VERSION}`}</span>
							</DropdownItem>
						</DropdownMenu>
					</UncontrolledDropdown>
				</Nav>
				{/* <NavbarText>Simple Text</NavbarText> */}
			</Collapse>
		</Navbar>
	);
}
