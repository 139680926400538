import { Settings } from './Settings';
import { GetThemeColour } from './ThemeHelper';

const groupBy = (xs, key) => {
	return xs.reduce(function (rv, x) {
		(rv[x[key]] = rv[x[key]] || []).push(x);
		return rv;
	}, {});
};

// Adds missing dates/values for the gaps in the data range
// This is based on "today" less 7, 30 or 365 days
const addRangePlaceholders = (period, displayFormat) => {
	let items = [];

	let daysInRange = 0;
	let element = '';
	switch (period) {
		// Week (mon-sun)
		case 1: {
			daysInRange = 7;
			element = 'day';
			break;
		}

		// Week (by day i.e 30/31 days)
		case 2: {
			daysInRange = 30;
			element = 'day';
			break;
		}

		// Week (by week i.e 1-4/5)
		case 3: {
			daysInRange = 4;
			element = 'week';
			break;
		}

		// By Month (Jan-Dec)
		case 4: {
			daysInRange = 12;
			element = 'month';
			break;
		}

		default:
			break;
	}

	// For the number dates, apply the format
	// look if the collection already has this "date". If not, add it, else, skip it.
	const today = new Date();

	for (let days = 0; days < daysInRange; days++) {
		const newDate = Settings.subtract(today, days, element);
		var newDatePart = newDate.format(displayFormat);
		//if (items.find((element) => element.datePart === newDatePart) === undefined) {
		//	items[days] = {
		//		datePart: newDatePart,
		//		value: 0,
		//	};
		//}

		items[daysInRange - (days + 1)] = {
			datePart: newDatePart,
			value: 0,
		};
	}

	return items;
};

const buildGraph = (label, valueProp = null, dateProp = null, items = null, period = 1, displayFormat = '') => {
	// Add date portion to items
	(items ?? []).forEach((element) => {
		element.period = Settings.formatDate(element[dateProp], 'custom', displayFormat);
	});

	// Group and sum for graph
	const groupedData = groupBy(items, 'period');
	const summedData = Object.keys(groupedData).map((element) => {
		return {
			datePart: element,
			value: groupedData[element].reduce((prev, curr) => prev + curr[valueProp], 0),
		};
	});

	const placeholders = addRangePlaceholders(period, displayFormat).map((item) => {
		const summedValue = summedData.find((summed) => summed.datePart === item.datePart);
		if (summedValue) {
			item.value = summedValue.value ?? 0;
		}
		return item;
	});

	// Graph Temaplate
	let result = {
		labels: placeholders.map((element) => element.datePart),
		datasets: [
			{
				label: label,
				data: placeholders.map((element) => element.value),

				borderColor: '#b71ca9',
				backgroundColor: GetThemeColour('--primary-25'),
				tension: 0.355,
				fill: true,
			},
		],
	};

	return result;
};

export const MapGraphData = {
	// Summed by The day of the week (mon-sun)
	toDayOfWeek: (label, valueProp = null, dateProp = null, items = null) => {
		return buildGraph(label, valueProp, dateProp, items, 1, 'ddd');
	},

	// Summed by the day of the month (1-31)
	toDayOfMonth: (label, valueProp = null, dateProp = null, items = null) => {
		return buildGraph(label, valueProp, dateProp, items, 2, 'DD MMM');
	},

	// Summed by Week (1-4/5)
	toWeekOfMonth: (label, valueProp = null, dateProp = null, items = null) => {
		return buildGraph(label, valueProp, dateProp, items, 3, '');
	},

	// Summed by Month (Jan-Dec)
	toMonthOfYear: (label, valueProp = null, dateProp = null, items = null) => {
		return buildGraph(label, valueProp, dateProp, items, 4, 'MMM');
	},
};
