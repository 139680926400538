import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ItemCrudContext from '../../../dataAccess/ItemCrudContext';
import { ErrorAlert, NotificationAlert } from '../../../components/GeneralError';

const BankingDetailsModel = {
	accountNumber: '',
	bankIdentifier: '',
	sortCode: '',
};

const BankingForm = ({ customerId, heading = true }) => {
	const { t } = useTranslation();
	const { data, getData, onChange, createError, updateObject } = ItemCrudContext(`/customer`, customerId);

	const { item, hasError, isBusy, isDirty, wasSaved } = data;
	const { accountNumber = '', bankIdentifier = '', sortCode = '' } = item.bankingDetails || BankingDetailsModel;

	const bankingInfoValidity = () => {
		if (!item?.bankingDetails) return true;

		const bbanLength = 30;
		const { accountNumber = '', sortCode = '', bankIdentifier = '' } = item.bankingDetails;
		const bbanNumber = accountNumber.concat(sortCode, bankIdentifier);

		if (bbanNumber.length > bbanLength) {
			const errorMessage = `${t('b.combinedLengthAccNoLonger')} ${bbanLength} ${t('b.characters')}\n\n${t('b.needsToAdhereToBasicBankAccNo')}`;
			createError(errorMessage);
			return false;
		}
		return true;
	};

	const onValueChange = (e) => {
		const {
			currentTarget: { name, value },
		} = e;

		const updatedItem = {
			currentTarget: {
				name: 'bankingDetails',
				value: {
					...item.bankingDetails,
					[name]: value,
				},
			},
		};
		onChange(updatedItem);
	};

	const onSave = () => {
		if (!bankingInfoValidity()) return;
		updateObject();
	};

	useEffect(() => {
		getData();
	}, [customerId]);

	return (
		<form>
			{heading && (
				<>
					<div className="font-weight-bolder">{t('b.bankingDetails')}</div>
					<div className="border-top pb-3"></div>
				</>
			)}
			{hasError && <ErrorAlert message={hasError} margin="" />}
			{wasSaved && <NotificationAlert message={t('b.bankingDetailsUpdated')}margin="" />}

			<div className="row mb-3">
				<div className="col">
					<label className="mb-0">{t('u.accountNumber')}</label>
					<input className="form-control form-control-sm " type="text" name="accountNumber" onChange={onValueChange} value={accountNumber} />
				</div>
			</div>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('u.sortCode')}</label>
					<input className="form-control form-control-sm" type="text" name="sortCode" onChange={onValueChange} value={sortCode} />
				</div>
			</div>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('u.bankIdentifier')}</label>
					<input className="form-control form-control-sm" type="text" name="bankIdentifier" onChange={onValueChange} value={bankIdentifier} />
				</div>
			</div>
			<button className="btn btn-primary" type="button" disabled={!isDirty || isBusy} onClick={onSave}>
				{isBusy && <i className="fal fa-sync fa-spin mr-2" />}
				{t('b.updateDetails')}
			</button>
		</form>
	);
};

export default BankingForm;
