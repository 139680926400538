import { useState, useEffect } from 'react';
import i18next from 'i18next';

import { HttpHandler } from '../../../utils/HttpHandler';

export const LanguageManager = () => {
	const [languages, setLanguages] = useState([]);
	const { httpGet } = HttpHandler(process.env.REACT_APP_API_PATH, true);

	const setLanguage = (e, selection = null) => {
		const { currentTarget } = e;
		const { value } = currentTarget;

		i18next.changeLanguage(!selection ? value : selection);
	};

	const selectedLanguageIndex = languages.find((element, index) => {
		if (element.value === i18next.language) return index;
	});

	useEffect(() => {
		const getLanguages = async () => {
			try {
				const result = await httpGet('/lookup/supportedLanguages');
				if (Array.isArray(result?.data)) setLanguages(result.data);
			} catch (error) {
				console.error(error);
			}
		};

		getLanguages();
	}, []);

	return { languages, setLanguage, selectedLanguage: i18next.language, selectedLanguageIndex };
};
