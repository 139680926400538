import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorAlert, NotificationAlert } from '../../../components/GeneralError';

const PasswordForm = ({ changePassword, heading = true }) => {
	const [password, setPassword] = useState({ current: '', newOne: '', newTwo: '' });
	const [isBusy, setIsBusy] = useState(false);
	const [isDone, setIsDone] = useState(false);
	const [hasError, setHasError] = useState(null);

	const { t } = useTranslation();

	const isValid =
		hasError === null && password.current && password.current !== '' && password.newOne && password.newOne.length > 5 && password.newOne !== '' && password.newTwo && password.newTwo !== '';

	const valueChange = (event) => {
		setHasError(null);
		const { name, value } = event.currentTarget;
		setPassword({ ...password, [name]: value });
	};

	const doUpdate = async () => {
		setIsBusy(true);
		setHasError(null);
		try {
			if (isValid) {
				await changePassword(password.current, password.newOne);
				setPassword({ current: '', newOne: '', newTwo: '' });
				setIsDone(true);
			}
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	return (
		<form>
			{heading && (
				<>
					<div className="font-weight-bolder">{t('p.passwordManagement')}</div>
					<div className="border-top pb-3"></div>
				</>
			)}
			{hasError && <ErrorAlert message={hasError} margin="" />}
			{isDone && <NotificationAlert message={t('u.passwordReset')} margin="" />}

			<div className="row mb-3">
				<div className="col">
					<label className="mb-0">{t('u.currentPassword')}</label>
					<input className="form-control form-control-sm " type="password" name="current" onChange={valueChange} value={password.current} />
				</div>
			</div>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('u.newPassword')}</label>
					<input className="form-control form-control-sm" type="password" name="newOne" onChange={valueChange} value={password.newOne} />
				</div>
			</div>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('u.confirmNewPassword')}</label>
					<input className="form-control form-control-sm" type="password" name="newTwo" onChange={valueChange} value={password.newTwo} />
				</div>
			</div>
			<button className="btn btn-primary" type="button" disabled={!isValid || isBusy} onClick={doUpdate}>
				{isBusy && <i className="fal fa-sync fa-spin mr-2" />}
				{t('u.changePassword')}
			</button>
		</form>
	);
};

export default PasswordForm;
