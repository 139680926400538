import React from 'react';
import { useTranslation } from 'react-i18next';

export const SelectFilter = (props) => {

	const { t } = useTranslation();

	const { selectAll, selectNone, textEntered, multiSelect = true } = props;

	return (
		<div className="d-flex flex-fill flex-wrap mb-4">
			<input className="form-control form-control-sm flex-grow-1" style={ { width: 'inherit' } } type="text" placeholder={t('g.search')} onChange={ textEntered } />
			{ multiSelect && <div className='d-flex flex-grow-1 flex-md-grow-0 justify-content-between'>

				<button className="btn btn-sm" onClick={ selectAll }>
					<i className="fal fa-check-square mr-1" />
					{t('g.selectAll')}
				</button>



				<button className="btn btn-sm" onClick={ selectNone }>
					<i className="fal fa-square mr-1" />
					{t('g.deselectAll')}
				</button>
			</div>
			}
		</div>
	);
};
