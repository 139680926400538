import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import _ from 'lodash';
import { ErrorAlert } from '../../components/GeneralError';
import { BreadCrumb } from '../../components/layout/BreadCrumb';
import { Page } from '../../components/layout/Page';
import Loading from '../../components/Loading';
import { HttpHandler } from '../../utils/HttpHandler';
import { ChargePointInfo } from './components/ChargePointInfo';

export const ChargePointWrapper = () => {
	const { id } = useParams();
	const { httpGet } = HttpHandler();
	const navigate = useNavigate();
	const [isBusy, setIsBusy] = useState(true);
	const [hasError, setHasError] = useState('');
	const [chargePoint, setChargePoint] = useState({});

	const { t } = useTranslation();

	const getData = async () => {
		setIsBusy(true);
		try {
			const result = await httpGet(`/chargePoint/${id}`);
			if (result === null || result.data === null) throw new Error(t('g.noDataReturned'));

			const { vendor, model, deviceId, physicalReference, latitude, longitude, isOnline, lastContact, connectors } = result.data;

			const geolocation = { latitude, longitude };

			setChargePoint({ recId: id, isOnline, vendor, model, identity: deviceId, locationName: physicalReference, geolocation, physicalReference, lastContact, connectors });
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	const sorting = (arrayProp, prop, id, order) => {
		let dataCopy = { ...chargePoint };
		dataCopy[arrayProp] = _.orderBy(dataCopy[arrayProp], [prop], order);

		setChargePoint(dataCopy);
	};

	const navBackward = () => {
		navigate(-1);
	};

	useEffect(() => {
		getData();
	}, [id]);

	return (
		<Page>
			<BreadCrumb title={t('cp.chargePoints')} navBackward={navBackward} />
			{isBusy ? (
				<Card>
					<CardBody>
						<Loading message={t('g.loadingChargePointLocations')} />
					</CardBody>
				</Card>
			) : hasError !== '' ? (
				<ErrorAlert message={hasError} />
			) : (
				<ChargePointInfo chargePoint={chargePoint} sorting={sorting} connectorSorting={'connectors'} sortKeys={['connectorIndex', 'status']} connectorReferencePropName={'physicalReference'} />
			)}
		</Page>
	);
};
