import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/layout/Page';
import { CardPlain } from '../../components/layout/CardContent';
import { EmptyData } from '../../components/EmptyData';
import { BreadCrumb } from '../../components/layout/BreadCrumb';

export const InvoiceFilter = () => {
	
	const { t } = useTranslation();
	
	console.debug('loading invoices');


	return (
		<Page>
			<BreadCrumb title={t('i.invoices')} />
			<CardPlain heading={t('i.viewInvoices')}>
				<EmptyData />
			</CardPlain>
		</Page>
	);
};
