import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSubmit } from '../../../components/ButtonSubmit';
import { UserContact } from '../../../components/UserContact';

export const RequestTraining = (props) => {
	const { onChange, data } = props;
	const { description = '' } = data;

	const { t } = useTranslation();

	return (
		<form>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('cs.indicateTrainingRequirements')}</label>
					<textarea className="form-control form-control-sm" rows="5" name="description" defaultValue={description} value={description} onChange={onChange} />
				</div>
			</div>

			<UserContact {...props} />
			<ButtonSubmit {...props} />
		</form>
	);
};
