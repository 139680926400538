import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/layout/Page';
import { TileDetail } from '../../components/tiles/TileDetail';
import { PagedListContext } from '../../dataAccess/PagedListContext';

const Home = () => {
	const { data: cpData, getData: getCPData } = PagedListContext('/report/chargeNetworkStatistics', null, 0);
	const { data: txData, getData: getTxData } = PagedListContext('report/chargeSessionStatistics', null, 0);

	const { t } = useTranslation();

	useEffect(() => {
		getCPData();
		getTxData();
	}, []);

	const { chargePointCount = 0, chargePointOfflineCount = 0, tokenActiveCount = 0 } = cpData.items[0] ?? {};
	const { transactionCount = 0, energykWhTotal = 0 } = txData.items[0] ?? {};

	return (
		<Page icon="fal fa-home" heading={t('g.home')} subHeading={t('g.selectAction')}>
			<Row className="mt-3 justify-content-md-start">
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-charging-station" title={t('cp.chargePoints')} to="/chargePoints" iconColor="primary" isBusy={cpData.isBusy}>
						<span>{chargePointCount} {t('cp.registeredChargePoints')}</span>
					</TileDetail>
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-cards-blank" title={t('cc.chargeCards')}to="chargeCards" iconColor="primary" isBusy={cpData.isBusy}>
						<span>{tokenActiveCount} {t('cc.activeCards')}</span>
					</TileDetail>
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-rectangle-history-circle-user" title={t('t.transactions')} to="transactions" iconColor="primary" isBusy={txData.isBusy}>
						<span>{transactionCount} {t('t.transactionsInThePast7Days')}</span>
					</TileDetail>
				</Col>
				 <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-clock" title={t('sc.scheduleCharging')}  to="/schedule" iconColor="primary">
						<span>{t('sc.setupAChargeSchedule')} </span>
					</TileDetail>
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-earth-africa" title={t('m.chargePointMap')} to="/map" iconColor="primary">
						<span>{t('m.findChargePointsUsingTheMap')}</span>
					</TileDetail>
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-chart-column" title={t('r.reports')} to="transactions" iconColor="primary">
						<span>{t('r.accessVariousDetailedReports')}</span>
					</TileDetail>
				</Col>

				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-messages-question" title={t('cs.customerSupport')} to="/support" iconColor="primary">
						<span>{t('cs.requestSupportOrChangesToYourAccount')}</span>
					</TileDetail>
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-bell" title={t('a.alerts')} to="alerts" iconColor="primary">
						<span>{t('a.viewErrorsAndNotifications')}</span>
					</TileDetail>
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-coins" title={t('f.finance')}  to="finance" iconColor="primary">
						<span>{t('f.viewDownloadReimbursementChargeReports')} </span>
					</TileDetail>
				</Col>
			</Row>
		</Page>
	);
};

export default Home;
