import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/layout/Page';
import { BreadCrumb } from '../../components/layout/BreadCrumb';
import { FilterRecords } from '../../components/filters/FilterRecords';
import { ChargeCardInfo } from './components/ChargeCardInfo';

import { PagedListContext } from '../../dataAccess/PagedListContext';
import { FilterHelper } from '../../utils/FilterHelper';
import { Card, CardBody } from 'reactstrap';
import Loading from '../../components/Loading';
import { ChargeCardStop } from './components/ChargeCardStop';

export const ChargeCardFilter = (props) => {
	const { t } = useTranslation();

	const { title = t('cc.chargeCards'), multiSelect = false, stepSizeInitial = 1 } = props;
	const navigate = useNavigate();
	const [filter, setFilter] = useState([]);
	const [stepSize, setStepSize] = useState(1);
	const [optIndex, setOptIndex] = useState(0);

	const { data: cardData, getData: getCards } = PagedListContext('chargeTag', null, 300000);
	const { isBusy } = cardData;

	const updateFilter = (filterItem) => {
		filter.push(filterItem);
		navForward(filterItem);
	};

	const navForward = (filterItem) => {
		const nextIndex = optIndex + filterItem.stepSize;
		setStepSize(stepSize);
		setOptIndex(nextIndex);
	};

	const navBackward = () => {
		const filterItem = filter.at(-1) ?? { stepSize: 1 };
		const prevIndex = optIndex - (filterItem.stepSize ?? 1);

		filter.pop();
		setFilter(filter);

		if (prevIndex < 0) navigate(-1);
		else setOptIndex(prevIndex);
	};

	const refresh = () => {
		getCards();
	};

	const chargeCards = cardData.items?.map((element) => {
		return { recId: element.recId, description: element.description, checked: false, identifier: element.visibleNumber };
	});

	useEffect(() => {
		getCards();
	}, []);

	return (
		<Page>
			<BreadCrumb title={title} filter={filter} navBackward={navBackward} />
			{isBusy ? (
				<Card>
					<CardBody>
						<Loading message={t('g.loadingChargeCards')} />
					</CardBody>
				</Card>
			) : (
				<Fragment>
					{optIndex === 0 && <FilterRecords heading={t('cc.chargeCards')} setFilter={updateFilter} stepSize={stepSizeInitial} multiSelect={multiSelect} items={chargeCards} />}
					{optIndex === 1 && <ChargeCardInfo chargeCard={FilterHelper.getFilteredItem(cardData.items, filter.at(-1) ?? [])} refresh={refresh} />}
					{optIndex === 2 && <ChargeCardStop chargeCard={filter.at(-1).selection ?? []} navBackward={navBackward} />}
				</Fragment>
			)}
		</Page>
	);
};
