import React from 'react';

import { ContactForm } from './contactComponents/ContactForm';
import { ContactDetails } from './contactComponents/ContactDetails';

export const ContactUs = (props) => {
	return (
		<div className="row">
			<ContactForm {...props} />
			<ContactDetails />
		</div>
	);
};
