import React, { useState, useEffect, useContext, createContext } from 'react';
import { useTranslation } from 'react-i18next';
import { initializeApp } from 'firebase/app';
import {
	getAuth,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	confirmPasswordReset,
	updateProfile,
	updatePassword,
	reauthenticateWithCredential,
	EmailAuthProvider,
	checkActionCode,
	applyActionCode,
	multiFactor,
	TotpMultiFactorGenerator,
	getMultiFactorResolver,
} from 'firebase/auth';

import { useTheme } from './ThemeContextProvider';
import { HttpHandler } from './HttpHandler';
import Loading from '../components/Loading';

// Initialize Firebase
const firebaseApp = initializeApp({
	apiKey: process.env.REACT_APP_FB_API,
	authDomain: process.env.REACT_APP_FB_DOMAIN,
});

const AuthContext = createContext();

// Hook for child components to get the auth object ... and re-render when it changes.
export const useAuth = () => {
	return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
	const theme = useTheme();
	const auth = getAuth(firebaseApp);
	const { httpPost, httpPut } = HttpHandler(process.env.REACT_APP_API_PATH, true);

	const [user, setUser] = useState(null);
	const [mfaFactors, setMfaFactors] = useState(null);
	const [isAuthenticating, setIsAuthenticating] = useState(true);

	const { t } = useTranslation();

	auth.tenantId = theme.authTenant ?? null;

	// Private
	const parseError = (error = null) => {
		if (error === null) return;

		if (error.code === 'auth/multi-factor-auth-required') {
			const mfaResolver = getMultiFactorResolver(auth, error);
			const enrolledFactor = mfaResolver.hints.find((hint) => hint.factorId == 'totp');
			if (enrolledFactor !== undefined) {
				setMfaFactors({
					resolver: mfaResolver,
					factorUid: enrolledFactor.uid,
				});
			}

			return true;
		}
	};

	const signup = async (email, password) => {
		try {
			const response = await httpPost('/permissions/user', null, { tenantId: auth.tenantId, email: email, password: password });
			setUser(response.user);
			return response.user;
		} catch (error) {
			console.error(error);
			throw new Error(t('l.thereWasAnError'));
		}
	};

	const resendEmailVerificationEmail = async (email = null) => {
		try {
			await httpPost('/permissions/user/resend', null, { tenantId: auth.tenantId, email: user?.email ?? email });
			return true;
		} catch (error) {
			console.error(error);
			throw new Error(t('l.anError'));
		}
	};

	const verifyEmail = async (oobCode) => {
		try {
			const result = await checkActionCode(auth, oobCode);
			const { operation, data } = result;
			if (operation !== 'VERIFY_EMAIL') throw new Error(t('l.linkInvalid'));

			await applyActionCode(auth, oobCode);

			return await httpPost('/permissions/user/emailVerified', null, { tenantId: auth.tenantId, email: data.email });
		} catch (error) {
			console.error(error);
			switch (error?.code) {
				case 'auth/expired-action-code':
					throw new Error(t('l.linkExpired'));
				case 'auth/invalid-action-code':
					throw new Error(t('l.activationLinkInvalid'));
				default:
					throw new Error(t('l.unhandledError'));
			}
		}
	};

	const signInUser = (user) => {
		try {
			const requestUrl = '/permissions/user/signedIn';
			const request = {
				id: user.uid,
				email: user.email,
				tenantId: auth.tenantId,
			};

			console.debug(`[Auth:signInUser] Calling sign-in user endpoint '${requestUrl}'...`, request);
			user.getIdToken().then(async (token) => {
				await httpPost(requestUrl, null, request, token);
			});
			console.debug('[Auth:signInUser] Successfully called sign-in user endpoint');
		} catch (error) {
			console.error('[Auth:signInUser] Failed trying to call sign-in user endpoint: ', error);
			throw new Error(t('e.errorSignIn'));
		}
	};

	const login = async (email, password) => {
		try {
			const response = await signInWithEmailAndPassword(auth, email, password);

			setUser(response.user);
			signInUser(response.user);

			return response.user;
		} catch (error) {
			console.error(error);
			if (parseError(error)) {
				return true;
			} else throw new Error(t('l.thereWasAnError'));
		}
	};

	const reAuthUser = async (password) => {
		try {
			const credential = EmailAuthProvider.credential(user.email, password);
			const result = await reauthenticateWithCredential(user, credential);
		} catch (error) {
			parseError(error);
			throw error;
		}
	};

	const sendPasswordReset = async (email) => {
		try {
			await httpPost('/permissions/user/forgot', null, { tenantId: auth.tenantId, email: email });

			return true;
		} catch (error) {
			console.error(error);
			throw new Error(t('l.thereWasAnError'));
		}
	};

	const resetPassword = async (oobCode, newPassword) => {
		try {
			const result = await checkActionCode(auth, oobCode);
			const { operation, data } = result;
			if (operation !== 'PASSWORD_RESET') throw new Error(t('l.linkInvalid'));

			await confirmPasswordReset(auth, oobCode, newPassword);

			return httpPost('/permissions/user/passwordChanged', null, { tenantId: auth.tenantId, email: data.email });
		} catch (error) {
			console.error(error);
			throw new Error(t('l.thereWasAnError'));
		}
	};

	const changePassword = async (oldPassword, password) => {
		await reAuthUser(oldPassword);
		await updatePassword(user, password);
		await httpPost('/permissions/user/passwordChanged', null, { tenantId: auth.tenantId, email: user.email });
	};

	const updateUserProfile = async (profile) => {
		try {
			await updateProfile(user, profile);
			const token = await user.getIdToken();

			await httpPut('/permissions/user', null, { tenantId: auth.tenantId, email: profile.email, id: user.uid, displayName: profile.displayName }, token);
			await httpPut('/permissions/user/metadata', null, { mobileNumber: profile.phoneNumber }, token);

			return true;
		} catch (error) {
			console.error(error);
			throw new Error(t('l.thereWasAnError'));
		}
	};

	const logout = async () => {
		try {
			await auth.signOut();
			setUser(false);
		} catch (error) {
			console.error(error);
			throw new Error(t('l.anError'));
		}
	};

	const enrollMfa = async () => {
		const multiFactorSession = await multiFactor(user).getSession();
		const totpSecret = await TotpMultiFactorGenerator.generateSecret(multiFactorSession);

		return {
			totpSecret: totpSecret,
			secretKey: totpSecret.secretKey,
			qrContents: totpSecret.generateQrCodeUrl(user.email, theme.displayName),
		};
	};

	const completeMfa = async (totpSecret, verificationCode) => {
		const multiFactorAssertion = TotpMultiFactorGenerator.assertionForEnrollment(totpSecret, verificationCode);
		return await multiFactor(user).enroll(multiFactorAssertion, theme.displayName);
	};

	const verifyMfa = async (otp) => {
		if (mfaFactors === null) throw new Error(t('l.mfaFactorValueNotSet'));

		try {
			const { resolver, factorUid } = mfaFactors;
			const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(factorUid, otp);

			const result = await resolver.resolveSignIn(multiFactorAssertion);
			if (!result) signInUser(result.user);
		} catch (error) {
			if (error.code === 'auth/invalid-verification-code') {
				throw new Error(t('l.verificationCodeInvalid'));
			} else throw new Error(t('l.thereWasAnError'));
		}
	};

	const unenrolMfa = async (factorId = null) => {
		if (factorId === null) throw new Error(t('l.mfaFactorIdValueNotSupplied'));

		return await multiFactor(user).unenroll(factorId);
	};

	// Subscribe to user on mount
	// Because this sets state in the callback it will cause any ...
	// ... component that utilizes this hook to re-render with the ...
	// ... latest auth object.
	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			setUser(user);
			setIsAuthenticating(false);
		});

		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, [auth]);

	// The user object and auth methods
	const values = {
		user,
		isAuthenticating,
		login,
		signup,
		logout,
		reAuthUser,
		sendPasswordReset,
		resetPassword,
		updateUserProfile,
		changePassword,
		resendEmailVerificationEmail,
		verifyEmail,
		enrollMfa,
		completeMfa,
		verifyMfa,
		unenrolMfa,
	};

	// Provider component that wraps your app and makes auth object
	// ... available to any child component that calls useAuth().
	return <AuthContext.Provider value={values}>{isAuthenticating ? <Loading padding="m-3" /> : children}</AuthContext.Provider>;
};
