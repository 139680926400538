import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChargePointFilter } from '../chargePoints/ChargePointFilter';

export const ScheduleWrapper = (props) => {

	const { t } = useTranslation();

	return <ChargePointFilter title={t('sc.scheduleCharging')} stepSizeInitial={3} {...props} />;
};
