import React from 'react';
import { Card, CardHeader, CardBody, CardFooter } from 'reactstrap';

export const CardPlain = (props) => {
	const { children, bodyStyle } = props;

	return (
		<Card className="mb-4">
			<CardBody style={bodyStyle}>{children}</CardBody>
		</Card>
	);
};

export const CardWithHeader = (props) => {
	const { heading, children, bodyStyle } = props;

	return (
		<Card className="mb-4">
			<CardHeader>{heading}</CardHeader>
			<CardBody style={bodyStyle}>{children}</CardBody>
		</Card>
	);
};

export const CardWithHeaderActions = (props) => {
	const { heading, children, actions, bodyStyle, canPrint = true, style = null, headerStyle = null } = props;

	return (
		<Card className="card-header-actions p-0 m-0" style={style}>
			<CardHeader className={'px-2 px-sm-3 w-100' + (canPrint ? '' : 'd-print-none')} style={headerStyle}>
				{heading}
				{actions}
			</CardHeader>
			<CardBody style={bodyStyle}>{children}</CardBody>
		</Card>
	);
};

export const CardWithHeaderFooterActions = (props) => {
	const { heading, footer, children, headerActions, footerActions, bodyStyle, canPrint = false, style = null, headerStyle = null, footerStyle = null, hideFooter = false } = props;

	return (
		<>
			<Card className="card-header-actions p-0 m-0" style={style}>
				<CardHeader className={'px-2 px-sm-3 w-100' + (canPrint ? '' : 'd-print-none')} style={headerStyle}>
					{heading}
					{headerActions}
				</CardHeader>
				<CardBody style={bodyStyle}>{children}</CardBody>
				{hideFooter && (
					<CardFooter className={'card-header px-2 px-sm-3 w-100' + (canPrint ? '' : 'd-print-none')} style={footerStyle}>
						{footer}
						{footerActions}
					</CardFooter>
				)}
			</Card>
			<div className="mb-3"></div>
		</>
	);
};
