import React from 'react';
import { Col, Row } from 'reactstrap';

import { useTranslation } from 'react-i18next';

import { CardWithHeader } from '../../components/layout/CardContent';
import { Page } from '../../components/layout/Page';
import { LanguageDropdown } from '../../components/layout/controls/LanguageDropdown';

//import Loading from '../../components/Loading';

export const Settings = () => {
	const { t } = useTranslation();

	return (
		<Page icon="fal fa-sliders" heading={t('s.settings')} subHeading={t('s.manageSettings')}>
			<CardWithHeader heading={t('s.systemSettings')}>
				<Row className="justify-content-start">
					<Col className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
						<LanguageDropdown />
					</Col>
				</Row>
			</CardWithHeader>

			{/*<CardWithHeader heading="Invoice Settings"></CardWithHeader>*/}
			{/*<CardWithHeader heading="Payment Methods"></CardWithHeader>*/}
			{/*<CardWithHeader heading="Top-Up Options"></CardWithHeader>*/}
			{/*<CardWithHeader heading="Permissions"></CardWithHeader>*/}
		</Page>
	);
};
