import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';

import Register from './views/auth/Register';
import Login from './views/auth/Login';
import Forgot from './views/auth/Forgot';
import Verify from './views/auth/Verify';
import { ResetPassword } from './views/auth/ResetPassword';
import { SetPassword } from './views/auth/SetPassword';

import Home from './views/home/Home';

import { Dashboard } from './views/dashboard/Dashboard';
import { TransactionFilter } from './views/transactions/TransactionFilter';
import { ChargePointFilter } from './views/chargePoints/ChargePointFilter';
import { ChargeCardFilter } from './views/chargeCards/ChargeCardFilter';
import { InvoiceFilter } from './views/invoices/invoiceFilter';

import { Profile } from './views/user/Profile';
import { Settings } from './views/settings/Settings';
import { LiveMap } from './views/maps/LiveMap';
import { Alerts } from './views/alerts/Alerts';
import { Reports } from './views/reports/Reports';
import { SessionInfo } from './views/session/SessionInfo';
import { ChargeCardWrapper } from './views/chargeCards/ChargeCardWrapper';
import { ChargePointWrapper } from './views/chargePoints/ChargePointWrapper';
import { SupportHome } from './views/support/SupportHome';
import { ContactPage } from './views/support/ContactPage';
import { ScheduleWrapper } from './views/schedule/ScheduleWrapper';
import { FinanceFilter } from './views/reimbursement/FinanceFilter';

import { LanguageDropdown } from './components/layout/controls/LanguageDropdown';

const AppRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/register" element={<Register />} />
				<Route path="/login" element={<Login languageSelect={<LanguageDropdown compact={true} />} />} />
				<Route path="/forgot" element={<Forgot languageSelect={<LanguageDropdown compact={true} />} />} />
				<Route path="/verifyEmail" element={<Verify languageSelect={<LanguageDropdown compact={true} />} />} />
				<Route path="/verifyEmail/:oobCode" element={<Verify languageSelect={<LanguageDropdown compact={true} />} />} />
				<Route path="/resetPassword/:oobCode" element={<ResetPassword languageSelect={<LanguageDropdown compact={true} />} />} />
				<Route path="/setPassword/:oobCode" element={<SetPassword languageSelect={<LanguageDropdown compact={true} />} />} />

				<Route element={<PrivateRoute isAllowed={true} />}>
					<Route path="/" element={<Home />} />
					<Route path="/dashboard" element={<Dashboard />} />
					<Route path="/invoices" element={<InvoiceFilter />} />

					<Route path="/transactions" element={<TransactionFilter />} />
					<Route path="/finance" element={<FinanceFilter />} />

					<Route path="/chargePoints/:stepSize?/:step?/:locationId?/:cpId?" element={<ChargePointFilter />} />
					<Route path="/chargePoint/:id" element={<ChargePointWrapper />} />

					<Route path="/chargeCards" element={<ChargeCardFilter />} />
					<Route path="/chargeCards/:id" element={<ChargeCardWrapper />} />

					<Route path="/session/:id" element={<SessionInfo />} />

					<Route path="/schedule/:stepSize?/:step?/:locationId?/:cpId?" element={<ScheduleWrapper />} />
					<Route path="/support" element={<SupportHome />} />
					<Route path="/support/:type/:stepSize?/:step?/:locationId?/:cpId?" element={<ContactPage />} />

					<Route path="/map" element={<LiveMap />} />
					<Route path="/alerts" element={<Alerts />} />
					<Route path="/reports" element={<Reports />} />

					<Route path="/profile" element={<Profile />} />
					<Route path="/settings" element={<Settings />} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoutes;
