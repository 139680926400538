import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Settings } from '../../utils/Settings';

const getTypeStyle = (type) => {
	if (type === null || type === undefined || type === '') return 'alert-info';

	switch (type.toLowerCase()) {
		case 'status':
			return 'alert-light';

		default:
			break;
	}
};

export const Alert = ({ item }) => {
	const { t } = useTranslation();
	const { dateCreated, messageType, messageSubject, chargePointId, chargePointDeviceId, chargePointLocation } = item;

	return (
		<div className={`alert ${getTypeStyle(messageType)}`} role="alert">
			<h4>{messageType}</h4>
			<div>{messageSubject ?? t('a.noMessageContents')}</div>
			<hr />
			<div className="font-weight-lighter">
				{Settings.formatDate(dateCreated, 'datetime')} |{' '}
				<Link to={`/chargePoint/${chargePointId}`}>
					{chargePointLocation} {chargePointDeviceId}
				</Link>
			</div>
		</div>
	);
};
