import React, { Fragment } from 'react';
import { Settings } from '../../utils/Settings';

export const Pagination = ({ pageChanged, currentPage = 1, itemCount = 0, tabCount = 5, pageSize = Settings.pageSize }) => {
	let pageCount = Math.ceil(itemCount / pageSize);
	let pageCentre = Math.ceil(tabCount / 2);

	const pageHasChanged = (currentPage) => {
		if (pageChanged) {
			pageChanged(currentPage);
		}
	};

	const pageClick = (event, pageNumber) => {
		event.preventDefault();
		pageHasChanged(pageNumber);
	};

	const pageFirst = (event) => {
		event.preventDefault();
		pageHasChanged(1);
	};

	const pagePrevious = (event) => {
		event.preventDefault();
		if (currentPage > 1) {
			pageHasChanged(currentPage - 1);
		}
	};

	const pageNext = (event, pageCount) => {
		event.preventDefault();
		if (currentPage < pageCount) {
			pageHasChanged(currentPage + 1);
		}
	};

	const pageLast = (event, pageCount) => {
		event.preventDefault();
		pageHasChanged(pageCount);
	};

	if (pageCount <= 1) {
		return null;
	}

	return (
		<ul className="pagination justify-content-center mt-2">
			{currentPage > 1 ? (
				<Fragment>
					<li className={currentPage > 1 ? 'page-item' : 'page-item inactive'}>
						<a className="page-link" href="/" onClick={pageFirst}>
							&lt;&lt;
						</a>
					</li>
					<li className={currentPage > 1 ? 'page-item' : 'page-item inactive'}>
						<a className="page-link" href="/" onClick={pagePrevious}>
							&lt;
						</a>
					</li>
				</Fragment>
			) : null}

			{Array.from({ length: pageCount < tabCount ? pageCount : tabCount }, (v, k) => k + (currentPage < pageCentre || pageCount < tabCount ? 1 : currentPage + (pageCentre - 1) < pageCount ? currentPage - (pageCentre - 1) : pageCount - (tabCount - 1))).map((item) => (
				<li key={item} className={currentPage !== item ? 'page-item' : 'page-item  active'}>
					<a className="page-link" href="/" onClick={(e) => pageClick(e, item)}>
						{item}
					</a>
				</li>
			))}

			{currentPage < pageCount ? (
				<Fragment>
					<li className="page-item">
						<a className="page-link" href="/" onClick={(e) => pageNext(e, pageCount)}>
							&gt;
						</a>
					</li>
					<li className={currentPage < pageCount ? 'page-item' : 'page-item inactive'}>
						<a className="page-link" href="/" onClick={(e) => pageLast(e, pageCount)}>
							&gt;&gt;
						</a>
					</li>
				</Fragment>
			) : null}
		</ul>
	);
};
