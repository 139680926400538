export const FilterHelper = {
	getFilterItemId: (filter, index = 0) => {
		const { selection = [] } = filter;
		const { recId = null } = selection.length <= 0 ? {} : selection[index];

		return recId;
	},

	getFilteredItem: (list, filter, index = 0) => {
		const itemKey = FilterHelper.getFilterItemId(filter, index);
		return list.find((element) => element.recId === itemKey) ?? null;
	},

	filterItem: (item, filterValue = null) => {
		return filterValue == null || item.locationId === filterValue;
	},
};
