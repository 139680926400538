import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

export const ButtonSubmit = (props) => {
	const { btnSubmitClick, btnResetClick, isBusy = false, isDirty = false } = props;

	const { t } = useTranslation();

	return (
		<Fragment>
			<button className="btn btn-sm btn-primary mr-2" onClick={btnSubmitClick} disabled={isBusy || !isDirty}>
				<i className={`fa ${isBusy ? 'fa-sync fa-spin' : 'fa-check'} mr-1`} />
				{t('g.submit')}
			</button>
		</Fragment>
	);
};
