import React, { useState, Fragment } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { GetThemeColour } from '../../../utils/ThemeHelper';
import { LanguageManager } from './LanguageSelector';

export const LanguageDropdown = ({ compact = false }) => {
	const { t } = useTranslation();
	const { languages } = LanguageManager();
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => setDropdownOpen((prevState) => !prevState);

	const setLanguage = (e, selection = null) => {
		const { currentTarget } = e;
		const { value } = currentTarget;

		i18next.changeLanguage(!selection ? value : selection);
	};

	return compact ? (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
			<DropdownToggle caret tag="span" title={t('language')} style={{ color: GetThemeColour('--secondary'), cursor: 'pointer' }}>
				<i className="fa-solid fa-language mr-2" style={{ fontSize: '1.7rem' }} />
				{i18next.language}
			</DropdownToggle>
			<DropdownMenu style={{ color: GetThemeColour('--secondary') }}>
				{Object.keys(languages).map((i) => (
					<DropdownItem key={i} style={{ color: GetThemeColour('--secondary') }} active={languages[i].value == i18next.language} onClick={(e) => setLanguage(e, languages[i].value)}>
						{languages[i].text}
					</DropdownItem>
				))}
			</DropdownMenu>
		</Dropdown>
	) : (
		<Fragment>
			<label className="m-0">{t('language')}</label>
			<select className="form-control form-control-sm" name="systemLanguage" onChange={setLanguage} value={i18next.language}>
				{Object.keys(languages).map((i) => (
					<option key={i} value={languages[i].value}>
						{languages[i].text}
					</option>
				))}
			</select>
		</Fragment>
	);
};
