import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpHandler } from '../utils/HttpHandler';
import { Settings } from '../utils/Settings';
import { ConfirmModal } from './modals/Modals';

import { EmptyGuid } from '../utils/Helpers';

const statusEnum = {
	none: Symbol('none'),
	errorOnStop: Symbol('errorOnStop'),
	errorOnForceStop: Symbol('errorOnForce'),
	errorOnConcurrent: Symbol('errorOnConcurrent'),
};

const isHttpSuccess = (result) => {
	return result.data?.status?.toString().toLowerCase() === 'accepted';
};

export const ButtonStop = (props) => {
	const { httpGet, httpPost } = HttpHandler();
	const [isBusy, setIsBusy] = useState(false);
	const [message, setMessage] = useState('');
	const [status, setStatus] = useState(statusEnum.none);

	const { t } = useTranslation();

	const { chargePointId = null, transactionId = null, transactionNumber = null, transactionDateStopped = null, chargeTagPartnerId = null, size = 'small', refresh } = props;

	/* 	Refresh is delayed because the device still needs to send a stop response
		to the server after the stop request is successfull, otherwise the refresh response
		will not contain any stop data. */
	const delayedRefresh = () => {
		return new Promise((resolve) =>
			setTimeout(() => {
				refresh();
				resolve();
			}, 15000),
		);
	};

	const stop = async (e) => {
		e.preventDefault();
		setIsBusy(true);

		setStatus(statusEnum.none);
		setMessage('');

		try {
			const result = await httpPost(`chargePointSupport/${chargePointId}/stopTransaction/${transactionNumber}`);
			if (isHttpSuccess(result)) {
				setMessage(`${t('t.deviceResponse')} '${result.data?.status}'`);
				await delayedRefresh();
			} else {
				setStatus(statusEnum.errorOnStop);
				setMessage(result?.data?.errorMessage ?? `${t('t.unexpectedResponse')} ${transactionNumber}.`);
			}
		} catch (error) {
			setStatus(statusEnum.errorOnStop);
			setMessage(`${t('t.error')} ${((error.response || {}).data || {}).message || error.message || ''}.`);
		} finally {
			setIsBusy(false);
		}
	};

	const forceStop = async () => {
		setIsBusy(true);
		try {
			const result = await httpGet(`chargeTransaction/${transactionId}/forceStop`);
			if (!isHttpSuccess(result)) {
				setStatus(statusEnum.errorOnForceStop);
				setMessage(`${t('t.deviceResponse')} '${result.data?.status}'`);
				await delayedRefresh();
			}
		} catch (error) {
			setStatus(statusEnum.errorOnForceStop);
			setMessage(`${t('t.error')} ${((error.response || {}).data || {}).message || error.message || ''}.`);
		}
	};

	const enableConcurrentTokenRequest = async () => {
		try {
			const result = await httpGet(`/chargeTransaction/${transactionId}/toggleConcurrent`);
			if (!isHttpSuccess(result)) {
				setStatus(statusEnum.errorOnConcurrent);
				setMessage(`${t('t.deviceResponse')} '${result.data?.status}'`);
			}
		} catch (error) {
			setStatus(statusEnum.errorOnConcurrent);
			setMessage(`${t('t.error')} ${((error.response || {}).data || {}).message || error.message || ''}.`);
		}
	};

	const ShowDialog = () => {
		const [isOpen, setIsOpen] = useState(false);

		const toggle = () => {
			setIsOpen(!isOpen);
			setMessage('');
			setIsBusy(false);
			setStatus(statusEnum.none);
		};

		switch (status) {
			case statusEnum.errorOnStop: {
				return (
					<ConfirmModal isOpen={status === statusEnum.errorOnStop} btn2Text={t('t.force')} btn1Action={toggle} btn2Action={forceStop} isBusy={isBusy}>
						<>
							{t('t.errorStoppingTransaction')} {message} {t('t.forceStopTransaction')}
							{chargeTagPartnerId === EmptyGuid && (
								<div className="pt-2">
									{t('t.alternatively')}{' '}
									<button className="btn btn-link m-0 p-0" onClick={enableConcurrentTokenRequest}>
										{t('t.enableConcurrent')}
									</button>{' '}
									{t('t.transactionsChargeToken')}
								</div>
							)}
						</>
					</ConfirmModal>
				);
			}
			case statusEnum.errorOnForceStop:
			case statusEnum.errorOnConcurrent: {
				return (
					<ConfirmModal isOpen={isOpen && status !== statusEnum.errorOnStop} btn1Action={toggle} btn2Text={t('t.force')} isBusy={isBusy}>
						<>{message}</>
					</ConfirmModal>
				);
			}
		}
	};

	if (chargePointId === null || transactionId === null || transactionNumber === null || !Settings.emptyDate(transactionDateStopped)) return null;

	return (
		<Fragment>
			<ShowDialog />

			{size === 'small' ? (
				<a title={t('t.clickStopTransaction')} disabled={isBusy} onClick={stop} href="/">
					<i className={`fal ${isBusy ? 'fa-sync fa-spin' : 'fa-circle-stop'}`} />
				</a>
			) : (
				<Fragment>
					<div className="d-none d-md-flex d-print-none align-items-center ">
						<button className="btn btn-sm btn-outline-primary" onClick={stop} title={t('t.clickStopTransaction')} disabled={isBusy}>
							<i className={`fas ${isBusy ? 'fa-sync fa-spin' : 'fa-circle-stop'}  mr-2`} />
							{t('t.stopTransaction')}
						</button>
					</div>

					<div className="d-flex d-md-none">
						<button className="btn btn-icon btn-primary mr-2" disabled={isBusy} onClick={stop} title={t('t.clickStopTransaction')}>
							<i className={`fas ${isBusy ? 'fa-sync fa-spin' : 'fa-stop'}`} />
						</button>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};
