export const parseJwt = (token = null) => {
	if (token === null || token === undefined) return null;

	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
};

export const readProperty = (token = null, property = null) => {
	if (token === null || property === null) return null;

	const parsedToken = parseJwt(token);
	if (parsedToken === undefined || parsedToken === null) return null;

	return parsedToken[property] ?? null;
};

export const readMfaIdFactor = (token) => {
	if (token === null || token === undefined) return null;

	const firebaseSection = readProperty(token, 'firebase') ?? null;
	if (firebaseSection === null || firebaseSection === undefined) return null;

	const { sign_in_second_factor = null, second_factor_identifier = null } = firebaseSection;
	if (sign_in_second_factor === null || second_factor_identifier === null) return null;

	return {
		factor: sign_in_second_factor,
		factorId: second_factor_identifier,
	};
};
