import React from 'react';
import LocationMarker from './markers/LocationMarker';
import ClusterMarker from './markers/ClusterMarker';

export const MapMarker = ({ locationInfo, selectedLocationId, lat, lng, locationCount, zoomCluster, zoomLocation }) => {
	const { cluster } = locationInfo.properties;

	if (lat === 0 && lng === 0) return null;

	return cluster ? <ClusterMarker clusterInfo={locationInfo} points={locationCount} zoomCluster={zoomCluster} lat={lat} lng={lng} /> : <LocationMarker locationInfo={locationInfo} selectedLocationId={selectedLocationId} zoomLocation={zoomLocation} lat={lat} lng={lng} />;
};
