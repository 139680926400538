import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpHandler } from '../utils/HttpHandler';

export const ButtonClearSchedules = (props) => {
	
	const { httpDel } = HttpHandler();
	const [isBusy, setIsBusy] = useState(false);

	const { t } = useTranslation();

	//const [hasError, setHasError] = useState('');

	const { isOnline, onReset, chargePointId = null } = props;

	const restart = async () => {
		setIsBusy(true);
		try {
			await httpDel(`chargePoint/${chargePointId}/schedule`);
		} catch (error) {
			console.error(error);
			//setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	if (chargePointId === null) return null;

	return (
		<Fragment>
			<button className="btn btn-sm btn-outline-primary" onClick={onReset} /*disabled={!isOnline || isBusy}*/ title={isOnline ? t('sc.resetChargePoint') : t('sc.chargePointOffline')}>
				<i className={`fal ${isBusy ? 'fa-sync fa-spin' : 'fa-redo'}  mr-2`} />
				{t('sc.reset')}
			</button>
			<button className="btn btn-sm btn-outline-primary ml-2" onClick={restart} /*disabled={!isOnline || isBusy}*/ title={isOnline ? t('sc.restartChargePoint') : t('sc.chargePointOffline')}>
				<i className={`fal ${isBusy ? 'fa-sync fa-spin' : 'fa-trash'}  mr-2`} />
				{t('sc.clear')}
			</button>
		</Fragment>
	);
};
