import React from 'react';
import { PageContent } from './PageContent';
import NavbarContainer from './NavbarContainer';

export const LayoutContent = (props) => {
	const { children } = props;

	return (
		<div className="h-100">
			<NavbarContainer />
			<PageContent>{ children }</PageContent>
		</div>
	);
};
