import React, { useRef, useState, Fragment, useEffect } from 'react';
import { useReactToPrint } from 'react-to-print';
import { utils, writeFileXLSX } from 'xlsx';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { CardWithHeaderActions } from '../../../components/layout/CardContent';
import Loading from '../../../components/Loading';
import { EmptyData } from '../../../components/EmptyData';
import { DataTable } from '../../../components/Tables';
import { ErrorAlert } from '../../../components/GeneralError';

import { Settings } from '../../../utils/Settings';
import { downloadCSVFile, downloadPDFFile } from '../../../utils/JsUtils';
import { GroupBy } from '../../../utils/GroupByHelper';

import { HttpContext } from '../../../dataAccess/HttpService';

const cleanSheetName = (sheetName) => {
	return sheetName.replace(/[/\\?%*:|"<>]/g, '-');
};

const HeaderActions = ({ action }) => {
	const { hasData, printForm, downloadFile } = action;
	const [showDropdown, setShowDropdown] = useState(false);

	const { t } = useTranslation();

	const toggleDropDown = () => {
		setShowDropdown(!showDropdown);
	};

	const triggerDownload = (e, format) => {
		e.preventDefault();
		toggleDropDown();
		downloadFile(format);
	};

	return (
		<Fragment>
			<div className="d-none d-sm-flex align-items-center d-print-none">
				<button className="btn btn-sm btn-primary mr-2" disabled={!hasData} onClick={printForm}>
					<i className="fal fa-print mr-1" />
					{t('g.print')}
				</button>

				<button className="btn btn-sm btn-secondary mr-2" disabled={!hasData}>
					<i className="fal fa-envelope mr-1" />
					{t('g.email')}
				</button>

				<div className="dropdown">
					<button className="btn btn-sm btn-outline-secondary mr-2 dropdown-toggle" disabled={!hasData} onClick={toggleDropDown}>
						<i className="fal fa-download mr-2" />
						{t('g.download')}
					</button>
					<div className={`dropdown-menu ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'xlsx')}>
							.xlsx
						</a>
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'csv')}>
							.csv
						</a>
					</div>
				</div>
			</div>

			<div className="d-flex d-sm-none">
				<button className="btn btn-icon btn-primary mr-2" disabled={!hasData} onClick={printForm}>
					<i className="fal fa-print" />
				</button>
				<button className="btn btn-icon btn-secondary mr-2" disabled={!hasData}>
					<i className="fal fa-envelope" />
				</button>
				<div className="dropdown">
					<button className="btn btn-icon btn-outline-secondary dropdown-toggle" disabled={!hasData} onClick={toggleDropDown}>
						<i className="fal fa-download" />
					</button>
					<div className={`dropdown-menu ${showDropdown ? 'show' : ''}`} aria-labelledby="dropdownMenuButton">
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'xlsx')}>
							.xlsx
						</a>
						<a className="dropdown-item" href="#!" onClick={(e) => triggerDownload(e, 'csv')}>
							.csv
						</a>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

const buildHeaders = (t) => {
	let headers = [];
	headers.push({ title: t('g.view'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-1 text-lg-left' });

	headers.push({ title: t('r.payee'), className: 'align-top p-0 pr-1 col-2 col-lg-1' });
	headers.push({ title: t('r.paymentPeriod'), className: 'text-left align-top p-0 pr-1 col-2 col-lg-1' });
	headers.push({ title: t('r.approver'), className: 'text-left align-top p-0 pr-1 col-2 col-lg-1' });
	headers.push({ title: t('r.status'), className: 'text-left align-top p-0 pr-1 col-2 col-lg-1' });
	headers.push({ title: t('r.totalDuration'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-1 text-sm-center text-lg-right' });
	headers.push({ title: t('g.totalEnergy'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-1 text-sm-center text-lg-right' });
	headers.push({ title: t('r.value'), className: 'text-right align-top p-0 pr-1 col-2 col-lg-1 text-sm-center text-lg-right' });
	headers.push({ title: 'Actions', className: 'col-1 d-print-none text-right align-top p-0 pr-1' });

	return headers;
};

const TableRow = (item, index, rest, updateFilter, downloadPdf, isBusyDownloading) => {
	const { t } = rest;
	const [isDownloading, setIsDownloading] = useState(false);

	const downloadingPdf = (index) => {
		setIsDownloading(true);
		downloadPdf(index);
	};

	useEffect(() => {
		if (!isBusyDownloading) {
			setIsDownloading(false);
		}
	}, [isBusyDownloading]);

	return (
		<Fragment>
			<tr key={`table-row-1-${index}`} className="d-none d-md-table-row">
				<td className="p-0 pb-2 pt-2 pl-1 pr-1" style={{ width: '1%', minWidth: 20 }}>
					<Link className="d-print-none" to={'#'} onClick={(e) => updateFilter(e, index)}>
						<i className="fas fa-magnifying-glass" />
					</Link>
					<div className="d-none d-print-block">
						<i className="fas fa-magnifying-glass" />
					</div>
				</td>

				<td className="text-left p-0 pb-2 pt-2 pr-1">
					<Link className="d-print-none" to={'#'} onClick={(e) => updateFilter(e, index)}>
						{item.payeeName}
					</Link>
					<div className="d-none d-print-block">{item.payeeName}</div>
				</td>
				<td className="text-left p-0 pb-2 pt-2 pr-1">{item.statementPeriod}</td>
				<td className="text-left p-0 pb-2 pt-2 pr-1">{item.approvedUserDisplayName}</td>
				<td className="text-left p-0 pb-2 pt-2 pr-1">{item.status}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatDuration(item.totalDurationSec * 1000, null, 'HH:mm:ss')}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">{Settings.formatNumber(item.totalEnergyUsedKwh, 2)}</td>
				<td className="text-right p-1 pb-2 pt-2 pr-1">
					{Settings.formatNumber(item.statementAmounts.amountIncl, 2)} {item.currency}
				</td>
				<td className="text-right pl-5 pr-1 pb-2 pt-2">
					<button className="btn btn-sm btn-outline-primary" onClick={() => downloadingPdf(index)} title={`PDF ${t('g.download')}`}>
						<i className={`fal ${isDownloading ? 'fa-refresh fa-spin' : 'fa-download'}`} />
					</button>
				</td>
			</tr>

			<tr key={`table-row-2-${index}`} className="d-table-row d-md-none d-print-none">
				<td className="border">
					<div className="d-flex align-items-center">
						<div className="d-flex flex-column flex-grow-1">
							<div className="mb-1">
								<div className="flex-grow-1">
									<i className="fal fa-user ml-1 mr-2" />
									{item.payeeName}
								</div>
							</div>

							<div className="mb-1">
								<div className="flex-grow-1">
									<i className="fal fa-clock ml-1 mr-2" />
									{Settings.formatDuration(item.totalDurationSec * 1000, null, 'HH:mm:ss')} (h:m:s)
								</div>
							</div>

							<div className="mb-1">
								<div className="flex-grow-1">
									<i className="fal fa-spinner ml-1 mr-2" />
									{item.status}
								</div>
							</div>

							<div className="d-flex">
								<div className="flex-grow-1">
									<i className="fal fa-battery-bolt ml-1 mr-2" />
									{Settings.formatNumber(item.totalEnergyUsedKwh, 2)} {t('g.kwh')}
								</div>
								<div className="flex-grow-1 text-right">
									<i className="fal fa-circle-dollar ml-1 mr-2" />
									{Settings.formatNumber(item.statementAmounts.amountIncl, 2)} {item.currency}
								</div>
							</div>

							<div className="d-flex mt-3 mb-2">
								<button className="btn btn-sm btn-outline-primary" onClick={() => downloadingPdf(index)} title={`PDF ${t('g.download')}`}>
									<i className={`fal ${isDownloading ? 'fa-refresh fa-spin' : 'fa-download'}`} />
									<span className="ml-2">PDF {t('g.download')}</span>
								</button>
							</div>
						</div>
						<div className="ml-3">
							<Link className="d-print-none" to={`#`} onClick={(e) => updateFilter(e, index)}>
								<i className="fal fa-chevron-right font-weight-bold" />
							</Link>
						</div>
					</div>
				</td>
			</tr>
		</Fragment>
	);
};

const tableFooter = (items = null, rest) => {
	if (items === null) return null;

	const calculateTotal = (array, propName) => {
		let total = 0;
		const props = propName.split('.');
		array.forEach((item) => {
			total += props.reduce((obj, prop) => (obj && obj[prop] !== undefined ? obj[prop] : 0), item);
		});
		return total;
	};

	const { t } = rest;

	const totalDuration = Settings.formatDuration(calculateTotal(items, 'totalDurationSec') * 1000, null, 'HH:mm:ss');
	const totalEnergy = Settings.formatNumber(calculateTotal(items, 'totalEnergyUsedKwh'), 2);
	const totalBilled = Settings.formatNumber(calculateTotal(items, 'statementAmounts.amountIncl'), 2);

	return (
		<>
			<tr className="d-md-table-row">
				<td></td>
			</tr>
			<tr className="text-secondary d-none d-md-table-row">
				<td className="p-0 pb-2 pt-2 pr-1"></td>

				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="p-0 pb-2 pt-2 pr-1"></td>
				<td className="text-left p-0 pb-2 pt-2 pr-1">{t('t.reportTotal')}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalDuration}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalEnergy}</td>
				<td className="text-right p-0 pb-2 pt-2 pr-1">{totalBilled}</td>
			</tr>

			<tr className="text-secondary d-table-row d-md-none d-print-none">
				<div className="d-flex flex-grow-1 pl-3 pr-3">
					<div className="flex-grow-1 text-right">
						<div>{t('r.totalDuration')}</div>
						<div>
							<i className="fal fa-clock ml-1 mr-2" />
							{totalDuration}
						</div>
					</div>
					<div className="flex-grow-1 text-right">
						<div>{t('g.totalEnergy')}</div>
						<div>
							<i className="fal fa-battery-bolt ml-1 mr-2" />
							{totalEnergy}
						</div>
					</div>
					<div className="flex-grow-1 text-right">
						<div>
							{t('t.reportTotal')} {t('r.value')}
						</div>
						<div>
							<i className="fal fa-circle-dollar ml-1 mr-2" /> {totalBilled}
						</div>
					</div>
				</div>
			</tr>
		</>
	);
};

export const ReimbursementTable = (props) => {
	const { t } = useTranslation();
	const { statementPdfDownload } = HttpContext();
	const { title = t('r.report'), items, isLoading = true, loadMore = null, setFilter, filter } = props;
	const [hasError, setHasError] = useState('');
	const printRef = useRef();
	const downRef = useRef();
	const [isBusyDownloading, setIsBusyDownloading] = useState(false);

	const headers = buildHeaders(t);

	const updateFilter = (e, dataIndex) => {
		e.preventDefault();

		setFilter({
			...filter.slice(-1)[0],
			dataIndex,
			description: t('t.chargeTransactions'),
			dataType: 'summaryTable',
		});
	};

	const printForm = useReactToPrint({ content: () => printRef.current });

	const downloadPdf = async (index) => {
		setHasError('');
		setIsBusyDownloading(true);

		const result = await statementPdfDownload(items[index].recId);
		const defaultFilename = `statement_${items[index].statementPeriod}`;

		if (!result?.data) {
			setHasError('Error downloading statement PDF file.');
			setIsBusyDownloading(false);
			return;
		}

		const filename = !result.headers['content-disposition'] ? defaultFilename : result.headers['content-disposition'].split('filename=')[1].split(';')[0].replace(/\"/g, '');

		setIsBusyDownloading(false);
		downloadPDFFile(result.data, filename);
	};

	const downloadFile = async (format = 'xlsx') => {
		const workbook = utils.book_new();
		let allData = [{ sheetName: t('t.allTranactions'), sheetData: [] }];

		GroupBy(items, 'chargeTokenId').forEach((element) => {
			const data = element.data.map((row) => {
				return {
					Payee: row.payeeName,
					PaymentPeriod: row.statementPeriod,
					Approver: row.approvedUserDisplayName,
					Status: row.status,
					TotalDuration: Settings.formatDuration(row.totalDurationSec * 1000, null, 'HH:mm:ss'),
					TotalkWh: row.totalEnergyUsedKwh,
					Invoiced: Settings.formatNumber(row.statementAmounts.reportingAmountExcl),
					Currency: row.statementAmounts.currency,
				};
			});

			const firstRow = element.data[0];
			const { chargePointPhysicalReference, chargePointLocation, chargePointDeviceId } = firstRow;

			allData.push({
				sheetName: `${chargePointPhysicalReference ?? chargePointLocation}-${chargePointDeviceId}`,
				sheetData: data,
			});

			allData[0].sheetData = [...allData[0].sheetData, ...data];
		});

		allData.forEach((row, index) => {
			const worksheet = utils.json_to_sheet(row.sheetData);
			utils.book_append_sheet(workbook, worksheet, cleanSheetName(row.sheetName));

			if (index === 0 && format === 'csv') downloadCSVFile(utils.sheet_to_csv(worksheet));
		});

		if (format === 'xlsx') writeFileXLSX(workbook, `${title}.xlsx`);
	};

	const tableData = { data: items };

	return (
		<div ref={printRef}>
			<style type="text/css" media="print">
				{'@page { size: landscape; }'}
			</style>

			<ErrorAlert message={hasError} margin="" />

			<CardWithHeaderActions
				heading={title}
				actions={<HeaderActions action={{ isLoading: isLoading, hasData: tableData.data.length > 0, printForm: printForm, downloadFile: downloadFile, downloadPdf: downloadPdf }} />}>
				{isLoading ? (
					<Loading message={`${t('g.loading')} ${title}...`} />
				) : tableData.data.length <= 0 ? (
					<EmptyData />
				) : (
					<Fragment>
						<div ref={downRef}>
							<DataTable
								headers={headers}
								items={tableData}
								rowTemplate={(item, index, rest) => TableRow(item, index, rest, updateFilter, downloadPdf, isBusyDownloading)}
								footTemplate={tableFooter}
								t={t}
							/>
						</div>

						{loadMore && (
							<div className="d-flex justify-content-center">
								<button className="btn btn-link text-gray-500 text-xs" onClick={loadMore}>
									{t('g.loadMore')}
								</button>
							</div>
						)}
					</Fragment>
				)}
			</CardWithHeaderActions>
		</div>
	);
};
