import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';

const CardItem = (props) => {
	const { icon = '', iconColor = 'primary', title = 'Title', onClick } = props;

	return (
		<Card className="mb-3 lift" onClick={onClick} style={{ cursor: 'pointer' }}>
			<CardBody className="d-flex align-items-center ">
				<i className={`${icon} fa-2xl text-${iconColor} mt-3 mb-3 mr-3`} />
				<span className="text-gray-700">{title}</span>
			</CardBody>
		</Card>
	);
};

export const TileImage = (props) => {
	const { to = null } = props;

	if (to === null) return <CardItem {...props} />;

	return (
		<Link to={to ?? ''}>
			<CardItem {...props} />
		</Link>
	);
};
