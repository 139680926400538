import { useReducer } from 'react';

import { HttpHandler } from '../utils/HttpHandler';
import { Settings } from '../utils/Settings';

export const PagedListContext = (baseUrl, urlSuffix = null, pageSize = Settings.pageSize, basePath = process.env.REACT_APP_API_PATH) => {
	const initialState = { items: [], itemCount: 0, currentPage: 1, isBusy: true, hasError: '', hasTimeout: false };
	const { httpGet } = HttpHandler(basePath);

	const [data, dispatch] = useReducer((prevState, action) => {
		switch (action.type) {
			case 'ISBUSY':
				return {
					...prevState,
					isBusy: true,
					hasError: '',
				};
			case 'HASDATA':
				return {
					...prevState,
					items: action.data.items,
					itemCount: action.data.recordCount,
					isBusy: false,
					hasError: '',
				};
			case 'PAGECHANGED': {
				return {
					...prevState,
					currentPage: action.data.currentPage,
				};
			}
			case 'HASERROR':
				return {
					...prevState,
					isBusy: false,
					hasError: action.data,
				};
			case 'HASTIMEOUT':
				return {
					...prevState,
					isBusy: action.isBusy,
					hasTimeout: action.data,
				};
			case 'SORTED':
				return {
					...prevState,
					items: action.data.items,
				};
			default:
				throw new Error();
		}
	}, initialState);

	const getData = async (searchText = '', params = null, queryParams = '', useNewMethod = false) => {
		dispatch({ type: 'ISBUSY' });
		dispatch({ type: 'HASTIMEOUT', data: false, isBusy: true });
		try {
			let url = baseUrl;
			let queryParameters = '';

			if (useNewMethod) {
				queryParameters = `?pageSize=${pageSize}&currentPage=${data.currentPage}`;
				if (searchText !== '') {
					queryParameters += `&searchText=${searchText}`;
				}
				if (queryParams !== '') {
					queryParameters += `&${queryParams}`;
				}
			} else {
				if (pageSize > 0) {
					url += `/${data.currentPage}/pagesize/${pageSize}`;
				}
				if (searchText !== '') {
					url += `/search/${searchText}`;
				}
				if (params !== null) {
					url += `/${params}`;
				}
				if (queryParams !== '') {
					url += `?${queryParams}`;
				}
			}

			if (urlSuffix !== null) {
				url += urlSuffix;
			}

			const result = await httpGet(`${url}${queryParameters}`);

			dispatch({ type: 'HASDATA', data: { items: result.data.result ?? [], recordCount: result.data.recordCount ?? 0 } });
			return { items: result.data.result ?? [], recordCount: result.data.recordCount ?? 0, isBusy: false };
		} catch (error) {
			const errorMessage = `${((error.response || {}).data || {}).message || error.message || ''}`;
			dispatch({ type: 'HASERROR', data: errorMessage });
			dispatch({ type: 'HASTIMEOUT', data: error.code === 'ECONNABORTED', isBusy: false });
			return {
				error: new Error(),
				hasError: error.message,
				isBusy: false,
				hasTimeout: error.code === 'ECONNABORTED',
			};
		}
	};

	const pageChanged = async (pageNumber) => {
		dispatch({ type: 'PAGECHANGED', data: { currentPage: pageNumber } });
	};

	const sortData = (items) => {
		dispatch({ type: 'SORTED', data: { items } });
	};

	return { data, getData, pageChanged, sortData };
};
