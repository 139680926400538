import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './InfoWindow.css';

const UserLocation = ({ lat, lng }) => {
	const { t } = useTranslation();

	return (
		<Fragment>
			<span>
				<b>{t('m.thisPinIndicatesCurrentLocation')}</b>
			</span>
			<br />
			<span className="plain-text">{t('m.coordinatesAre')}</span>
			<br />
			<span className="co-ordinates">{t('m.latitude')} {lat}</span>
			<br />
			<span className="co-ordinates">{t('m.longitude')} {lng}</span>
		</Fragment>
	);
};

const LocationWindow = ({ locationInfo }) => {
	const { t } = useTranslation();
	
	if (locationInfo === null) return null;

	const { title, status, locationId, chargePointId } = locationInfo;

	return (
		<div>
			<span>
				<b>{title}</b>
			</span>
			<br />
			<br />
			<span className="plain-text">
				{status}
				<br />
				<Link to={`/chargePoint/${chargePointId}`}>{t('m.clickMoreInfo')}</Link>
			</span>
		</div>
	);
};

const InfoWindow = ({ locationInfo, lat, lng }) => {
	return <div className="info-window">{locationInfo === null ? <UserLocation lat={lat} lng={lng} /> : <LocationWindow locationInfo={locationInfo} />}</div>;
};

export default InfoWindow;
