import { useEffect, useState } from 'react';

import { StaticMapUrl } from '../utils/MapUtils';
import { HttpHandler } from '../utils/HttpHandler';

const mapImgUrl = (session) => {
	return StaticMapUrl({
		latitude: session.chargePointGeolocation.latitude,
		longitude: session.chargePointGeolocation.longitude,
		zoom: 16,
		size: { width: 400, height: 280 },
	});
};

export const SessionService = (sessionId, doRefresh = false) => {
	const { httpGet } = HttpHandler();
	const [data, setSession] = useState({});

	const getData = async () => {
		console.debug(`getting session ${sessionId}`);
		try {
			const result = await httpGet(`/chargeTransaction/${sessionId}`);
			const { data = null } = result;

			if (data !== null) {
				data.mapUrl = mapImgUrl(data);

				setSession(data);
			}
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		getData();
	}, [sessionId, doRefresh]);

	return data;
};
