import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CardBody } from 'reactstrap';

import ItemCrudContext from '../../dataAccess/ItemCrudContext';
import { Page } from '../../components/layout/Page';
import { CardWithHeader } from '../../components/layout/CardContent';
import PasswordForm from './components/PasswordForm';
import AddressForm from './components/AddressForm';
import BankingForm from './components/BankingForm';
import { UserMfa } from './components/UserMfa';
import Loading from '../../components/Loading';

import { useAuth } from '../../utils/Auth';

export const Profile = () => {
	const { user, updateUserProfile, changePassword } = useAuth();
	const { data: metaData, getData: getMobileNumber } = ItemCrudContext('/permissions/user/metadata');
	const { data, getData } = ItemCrudContext('/user/customer');

	const { t } = useTranslation();

	useEffect(() => {
		getData();
		getMobileNumber();
	}, []);

	if (!data.item.recId || !user) return <Loading />;

	return (
		<Page icon="fal fa-user" heading={t('u.profile')} subHeading={t('u.manageProfile')}>
			<CardWithHeader heading={t('u.manageProfile')}>
				<CardBody>
					<AddressForm user={user} mobileNumber={metaData.item.mobileNumber} updateProfile={updateUserProfile} />
					<div className="mt-5">&nbsp;</div>

					<BankingForm customerId={data.item.recId} />
					<div className="mt-5">&nbsp;</div>
					<PasswordForm changePassword={changePassword} />

					<div className="mt-5">&nbsp;</div>
					<UserMfa />
				</CardBody>
			</CardWithHeader>
		</Page>
	);
};
