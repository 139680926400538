import React from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSubmit } from '../../../../components/ButtonSubmit';
import { UserContact } from '../../../../components/UserContact';

import { useTheme } from '../../../../utils/ThemeContextProvider';

export const ContactForm = (props) => {
	const theme = useTheme();
	
	const { t } = useTranslation();

	const { onChange, data = {} } = props ?? {};
	const { description = '' } = data ?? {};

	return (
		<div className="col-12 col-xl-6 order-1 order-xl-0">
			<div className="d-flex justify-content-center">
				<form className="w-100 m-sm-0 m-md-4">
					<UserContact {...props} />

					<div className="row mb-3">
						<div className="col">
							<label className="m-0">{t('c.writeMessage')}</label>
							<textarea className="form-control form-control-sm" rows="3" name="description" defaultValue={description} value={description} onChange={onChange} />
						</div>
					</div>

					<ButtonSubmit {...props} />
				</form>
			</div>
		</div>
	);
};
