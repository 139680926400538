import React from 'react';

import { useTheme } from '../../../../utils/ThemeContextProvider';

export const ContactDetails = () => {
	const theme = useTheme();

	const { contactDetails = [] } = theme ?? {};

	return (
		<div className="col-12 col-xl-6 order-0 order-xl-1">
			<div className="d-flex align-content-stretch flex-wrap">
				{contactDetails?.map((office) => (
					<div className="p-5 m-auto">
						<div>
							<h3>{office.name}</h3>
						</div>

						{office.address && <div>{office.address.replace(/(?:\r\n|\r|\n)/g, '<br>')}</div>}

						{office.phoneNumber && (
							<div>
								<i className="fal fa-messages-question mr-2" />
								<a href={`tel:${office.phoneNumber}`}>{office.phoneNumber}</a>
							</div>
						)}

						{office.email && (
							<div>
								<i className="fal fa-envelope mr-2" />
								<a href={`mailto:${office.email}`}>{office.email}</a>
							</div>
						)}
					</div>
				))}
			</div>
		</div>
	);
};
