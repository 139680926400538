import React from 'react';
import { useTranslation } from 'react-i18next';

export const EmptyData = (props) => {
	const { t } = useTranslation();

	const { message = t('g.noDataToDisplay') } = props;
	return (
		<div className="d-flex flex-grow justify-content-md-center">
			<span>{message}</span>
		</div>
	);
};
