import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';

import { Line } from 'react-chartjs-2';
import { EmptyData } from '../EmptyData';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const LineChart = ({ data, height }) => {
	if (!data || !data.datasets || data.datasets <= 0) return <EmptyData />;

	const options = {
		responsive: true,
		maintainAspectRatio: false,

		layout: {
			padding: {
				left: 10,
				right: 25,
				top: 25,
				bottom: 0,
			},
		},
		scales: {
			x: {
				time: {
					unit: 'date',
				},
				grid: {
					display: false,
				},
				ticks: {
					maxTicksLimit: 7,
				},
			},

			y: {
				ticks: {
					maxTicksLimit: 5,
					padding: 10,
				},
				grid: {
					display: true,
					drawBorder: false,
					color: 'rgb(234, 236, 244)',
					zeroLineColor: 'rgb(234, 236, 244)',
					drawBorder: false,
					borderDash: [2],
					zeroLineBorderDash: [2],
				},
			},
		},
		legend: {
			display: false,
		},
		tooltips: {
			backgroundColor: 'rgb(255,255,255)',
			bodyFontColor: '#858796',
			titleMarginBottom: 10,
			titleFontColor: '#6e707e',
			titleFontSize: 14,
			borderColor: '#dddfeb',
			borderWidth: 1,
			xPadding: 15,
			yPadding: 15,
			displayColors: false,
			intersect: false,
			mode: 'index',
			caretPadding: 10,
		},
		elements: {
			line: {
				tension: 0.4, // disables bezier curves
			},
		},
	};

	return (
		<div style={{ height: { height }, width: '100%' }}>
			<Line type="line" height={height} data={data} options={options} />
		</div>
	);
};

export default LineChart;
