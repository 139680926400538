import React from 'react';
import QRCode from 'react-qr-code';

export const QrCode = (props) => {
	const { value, height = "auto", width = '20%', padding = '10px' } = props;
	return (
		<div style={{ background: 'white', width: width, height: height, padding: padding }}>
			<QRCode value={value} style={{ width: "100%", height: "100%", maxWidth: '100%' }} />
		</div>
	);
};
