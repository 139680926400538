import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '../utils/Auth';

const PrivateRoute = ({ isAllowed, redirectPath = '/', children }) => {
	const { user } = useAuth();

	if (!isAllowed) return <Navigate to={redirectPath} replace />;

	if (!user) return <Navigate to="/login" replace />;

	if (user && user.emailVerified === false) return <Navigate to="/verifyEmail" replace />;

	return children ? children : <Outlet />;
};

export default PrivateRoute;
