import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';
import { ButtonNav } from '../Buttons';

import { Settings } from '../../utils/Settings';

export const FilterDate = (props) => {
	const { setFilter, stepSize } = props;
	const [dateStart, setDateStart] = useState(new Date());
	const [dateEnd, setDateEnd] = useState(new Date());
	const [preset, setPreset] = useState(0);

	const { t } = useTranslation();

	const updateFilter = () => {
		setFilter({ text: formatDates(), description: formatDates(), stepSize: stepSize, dateStart: dateStart, dateEnd: dateEnd, dataType: 'dates' });
	};

	const presetDate = (index) => {
		switch (index) {
			case 1: {
				setDate({ currentTarget: { name: 'dateStart', value: Settings.formatDate(new Date(), 'custom'), index: index } });
				setDate({ currentTarget: { name: 'dateEnd', value: Settings.formatDate(new Date(), 'custom'), index: index } });
				break;
			}
			case 2: {
				setDate({ currentTarget: { name: 'dateStart', value: Settings.formatDate(Settings.subtract(new Date(), 1, 'day'), 'custom'), index: index } });
				setDate({ currentTarget: { name: 'dateEnd', value: Settings.formatDate(Settings.subtract(new Date(), 1, 'day'), 'custom'), index: index } });
				break;
			}
			case 3: {
				setDate({ currentTarget: { name: 'dateStart', value: Settings.formatDate(Settings.subtract(new Date(), 7, 'day'), 'custom'), index: index } });
				setDate({ currentTarget: { name: 'dateEnd', value: Settings.formatDate(new Date(), 'custom'), index: index } });
				break;
			}
			case 4: {
				setDate({ currentTarget: { name: 'dateStart', value: Settings.formatDate(Settings.subtract(new Date(), 30, 'day'), 'custom'), index: index } });
				setDate({ currentTarget: { name: 'dateEnd', value: Settings.formatDate(new Date(), 'custom'), index: index } });
				break;
			}
			default:
				break;
		}
	};

	const setDate = (e) => {
		const { currentTarget } = e;
		const { name, value, index = 100 } = currentTarget;

		if (name === 'dateStart') setDateStart(value);
		if (name === 'dateEnd') setDateEnd(value);
		setPreset(index);
	};

	const formatDates = () => {
		return `${Settings.formatDate(dateStart)} - ${Settings.formatDate(dateEnd)}`;
	};

	return (
		<Card>
			<CardBody>
				<div className="row justify-content-md-center">
					<div className="col col-sm-12 col-lg-6">
						<Card className="mb-3" style={{ cursor: 'pointer' }}>
							<CardBody className="d-flex flex-column">
								<label>{t('t.datePreset')}</label>
								<div className="d-none d-sm-flex mt-2 mb-2">
									<button className={`btn btn-outline-secondary mr-2 p-2 mb-2 ${preset === 1 ? 'active' : ''}`} onClick={() => presetDate(1)}>
										{t('t.today')}
									</button>
									<button className={`btn btn-outline-secondary mr-2 p-2 mb-2 ${preset === 2 ? 'active' : ''}`} onClick={() => presetDate(2)}>
										{t('t.yesterday')}
									</button>
									<button className={`btn btn-outline-secondary mr-2 p-2 mb-2 ${preset === 3 ? 'active' : ''}`} onClick={() => presetDate(3)}>
										{t('t.last7Days')}
									</button>
									<button className={`btn btn-outline-secondary mr-2 p-2 mb-2 ${preset === 4 ? 'active' : ''}`} onClick={() => presetDate(4)}>
										{t('t.last30Days')}
									</button>
								</div>

								<div className="d-flex justify-content-between d-sm-none mt-2 mb-2">
									<button className={`btn btn-icon btn-outline-secondary ${preset === 1 ? 'active' : ''}`} onClick={() => presetDate(1)}>
										T
									</button>
									<button className={`btn btn-icon btn-outline-secondary ${preset === 2 ? 'active' : ''}`} onClick={() => presetDate(2)}>
										Y
									</button>
									<button className={`btn btn-icon btn-outline-secondary ${preset === 3 ? 'active' : ''}`} onClick={() => presetDate(3)}>
										7
									</button>
									<button className={`btn btn-icon btn-outline-secondary ${preset === 4 ? 'active' : ''}`} onClick={() => presetDate(4)}>
										30
									</button>
								</div>
							</CardBody>
						</Card>
					</div>

					<div className="col col-sm-12 col-lg-6">
						<Card className="mb-3" style={{ cursor: 'pointer' }}>
							<CardBody className="d-flex flex-column">
								<label>{t('t.customDateRange')}</label>

								<div className="mt-2 mb-2 d-flex flex-column">
									<label>{t('t.startDate')}</label>
									<input className="form-control" id="dateStart" type="date" name="dateStart" placeholder={t('t.transactionsFrom')} value={dateStart} onChange={setDate} />

									<div className="m-2"></div>

									<label>{t('t.endDate')}</label>
									<input className="form-control" id="dateStart" type="date" name="dateEnd" placeholder={t('t.transactionTo')} value={dateEnd} onChange={setDate} />
								</div>
							</CardBody>
						</Card>

						<ButtonNav next={updateFilter} hasSelection={preset > 0} />
					</div>
				</div>
			</CardBody>
		</Card>
	);
};
