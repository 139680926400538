import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const closeBtn = (onClick) => {
	return (
		<button className="close" onClick={onClick} type="button">
			&times;
		</button>
	);
};

export const OneButtonModal = (props) => {

	const { t } = useTranslation();

	const { isOpen = false, isBusy = false, headerIcon = 'fa-circle-info', title = t('t.information'), btnText = t('g.dismiss'), btnIcon = 'fa-close', btnAction, children } = props;

	return (
		<Modal isOpen={isOpen}>
			<ModalHeader toggle={btnAction} close={closeBtn(btnAction)}>
				<i className={`fal ${headerIcon} mr-2`} />
				{title}
			</ModalHeader>
			<ModalBody>{children}</ModalBody>
			<ModalFooter>
				<button className="btn btn-sm btn-primary" onClick={btnAction} disabled={isBusy}>
					<i className={`fal ${btnIcon} mr-2`} />
					{btnText}
				</button>
			</ModalFooter>
		</Modal>
	);
};

export const TwoButtonModal = (props) => {

	const { t } = useTranslation();

	const { isOpen = false, isBusy = false, headerIcon = 'fa-circle-question', title = t('g.confirmation'), btn1Text = t('g.no'), btn1Icon = 'fa-close', btn1Action, btn2Text = t('g.yes'), btn2Icon = 'fa-check', btn2Action, children } = props;

	return (
		<Modal isOpen={isOpen}>
			<ModalHeader toggle={btn1Action} close={closeBtn(btn1Action)}>
				<i className={`fal ${headerIcon} mr-2`} />
				{title}
			</ModalHeader>

			<ModalBody>{children}</ModalBody>
			<ModalFooter>
				<button className="btn btn-sm btn-outline-secondary" onClick={btn1Action} disabled={isBusy}>
					<i className={`fal ${btn1Icon} mr-2`} />
					{btn1Text}
				</button>
				{btn2Text &&
					<button className="btn btn-sm btn-primary" onClick={btn2Action} disabled={isBusy}>
						<i className={`fal ${btn2Icon} mr-2`} />
						{btn2Text}
					</button>
				}
			</ModalFooter>
		</Modal>
	);
};

export const OkModal = (props) => {
	return <OneButtonModal {...props} />;
};

export const ConfirmModal = (props) => {
	return <TwoButtonModal {...props} />;
};
