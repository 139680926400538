import React from 'react';
import { useTranslation } from 'react-i18next';

const UserForm = ({ user, onChange }) => {
	const { description, tagReference, visibleNumber, status } = user ?? {};

	const { t } = useTranslation();

	return (
		<form>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('cc.userNameRegistration')}</label>
					<input
						className="form-control form-control-sm"
						type="text"
						name="description"
						defaultValue={description}
						placeholder={t('cc.userNameRegistration')}
						onChange={(e) => onChange(e)}
					/>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('cc.cardReference')}</label>
					<input className="form-control form-control-sm" type="text" name="tagReference" defaultValue={tagReference} placeholder={t('cc.cardReference')} onChange={(e) => onChange(e)} />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col">
					<label className="mb-0">{t('cc.cardNumber')}</label>
					<input className="form-control form-control-sm " type="text" name="visibleNumber" defaultValue={visibleNumber ?? t('cc.notSet')} disabled={true} />
				</div>
			</div>

			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('cc.cardStatus')}</label>
					<input className="form-control form-control-sm" type="text" defaultValue={t(`cc.${status.toLowerCase()}`) ?? t('cc.accepted')} disabled={true} />
				</div>
			</div>
		</form>
	);
};

export default UserForm;
