import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { TileImage } from '../../../components/tiles/TileImage';

export const FilterCardOptions = (props) => {
	const { setFilter, category = null } = props;

	const { t } = useTranslation();

	const updateFilter = (text, description, dataType, stepSize) => {
		setFilter({ text: text, dataType: dataType, stepSize: stepSize, description: description });
	};

	return (
		<Card>
			<CardBody className="mt-3 mb-3">
				<Row className="justify-content-start">
					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-0 offset-lg-3">
						<TileImage icon="fal fa-credit-card-blank" title={t('t.individualCards')} onClick={() => updateFilter('Individual Cards', '', 'chargeCards', 1)} />
					</Col>
					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 ">
						<TileImage icon="fal fa-cards-blank" title={t('t.allCards')} onClick={() => updateFilter('All Cards', t('t.allCards'), 'chargeCards', 5)} />
					</Col>

					{category !== null && category === 'reimbursements' && (
						<>
							<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-0 offset-lg-3">
								<TileImage icon="fal fa-building" title={t('t.individualLocations')} onClick={() => updateFilter('Individual Locations', '', 'locations', 2)} />
							</Col>

							<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
								<TileImage icon="fal fa-house-building" title={t('t.allLocations')} onClick={() => updateFilter('All Locations', t('t.allLocations'), 'locations', 5)} />
							</Col>
						</>
					)}
				</Row>
			</CardBody>
		</Card>
	);
};
