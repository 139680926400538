import React from 'react';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/layout/Page';
import { CardPlain } from '../../components/layout/CardContent';
import { EmptyData } from '../../components/EmptyData';

export const Reports = () => {
	console.debug('loading reports');

	const { t } = useTranslation();

	return (
		<Page>
			<CardPlain heading={t('r.reports')}>
				<EmptyData />
			</CardPlain>
		</Page>
	);
};
