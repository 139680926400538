import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AuthWrapper } from './components/AuthWrapper';
import { ErrorAlert } from '../../components/GeneralError';

import { useAuth } from '../../utils/Auth';
import { InputValidityHelper } from '../../utils/InputValidityHelper';

import appStoreApple from '../../assets/images/appStoreApple.png';
import appStoreGoogle from '../../assets/images/appStoreGoogle.png';
import { useTheme } from '../../utils/ThemeContextProvider';
import { QrCode } from '../../components/qrCode/QrCode';

export const SetPassword = (props) => {
	const { oobCode = null } = useParams();
	const { resetPassword } = useAuth();
	const { appStoreIdApple = null, appStoreIdAndroid = null, displayName = null } = useTheme();

	const [credentials, setCredentials] = useState({});
	const [hasError, setHasError] = useState(null);
	const [isDone, setIsDone] = useState(false);
	const [isBusy, setIsBusy] = useState(false);
	const { t } = useTranslation();

	const isValid = InputValidityHelper.isPasswordUpdateFormValid(hasError, oobCode, credentials, 'password', 'passwordConfirm');

	const isValidPassword = (password) => {
		if (!password || password === '') return false;

		return password.length >= 8 ? true : false;
	};

	const onChange = (e) => {
		setHasError(null);
		const { currentTarget } = e;
		const { name, value } = currentTarget;

		const newCredentials = { ...credentials, [name]: value };
		setCredentials(newCredentials);
	};

	const doReset = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		setIsDone(false);
		try {
			await resetPassword(oobCode, credentials.password);
			setIsDone(true);
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	console.debug('here@@@');

	return (
		<div className="row h-100 d-flex justify-content-md-center">
			<AuthWrapper>
				{!isDone ? (
					<form>
						{hasError && <ErrorAlert message={hasError} margin="" />}

						<h5>{t('p.accountHasBeenVerified')}</h5>
						<p className="small mb-3">{t('p.createYourPassword')}</p>

						<div className="form-group">
							<label>{t('l.newPassword')}</label>
							<input className="form-control mb-3" type="password" placeholder={t('l.newPassword')} name="password" onChange={onChange} autoComplete="off" />
							{!isValidPassword(credentials.password) && credentials.password && <span className="input-error">{t('e.passwordCharacters')}</span>}
						</div>

						<div className="form-group">
							<label>{t('l.confirmNewPassword')}</label>
							<input className="form-control mb-3" type="password" placeholder={t('l.confirmNewPassword')} name="passwordConfirm" onChange={onChange} autoComplete="off" />
							{!isValidPassword(credentials.passwordConfirm) && credentials.passwordConfirm && <span className="input-error">{t('e.passwordCharacters')}</span>}
						</div>

						<div className="d-flex justify-content-between align-items-center mt-3">
							<button className="btn btn-primary" onClick={doReset} disabled={!isValid || isBusy}>
								{isBusy && <i className="fal fa-refresh fa-spin mr-2" />}
								{t('p.createPassword')}
							</button>
							{props.languageSelect}
						</div>
					</form>
				) : (
					<div>
						<h5>{t('p.thankYou')}</h5>
						<p className="small mb-3">{t('p.yourAccountHasBeenSetUp')}</p>

						{((appStoreIdApple !== null && appStoreIdApple !== '') || (appStoreIdAndroid !== null && appStoreIdAndroid !== '')) && (
							<div>
								<p className="small">{t('p.ifReadingOnMobileDeviceDownloadApp')}</p>
								<div className="d-flex justify-content-around mb-2">
									{appStoreIdApple !== null && appStoreIdApple !== '' && (
										<div className="d-flex">
											<Link to={appStoreIdApple}>
												<img src={appStoreApple} height={50} />
											</Link>
											<QrCode value={appStoreIdApple} width={50} height={50} padding={5} />
										</div>
									)}
									{appStoreIdAndroid !== null && appStoreIdAndroid !== '' && (
										<div className="d-flex">
											<Link to={appStoreIdAndroid}>
												<img src={appStoreGoogle} height={50} />
											</Link>
											<QrCode value={appStoreIdAndroid} width={50} height={50} padding={5} />
										</div>
									)}
								</div>

								<p className="font-size-xs text-muted">{`${appStoreIdApple !== null ? t('p.appleLogoTradeMark') : ''} ${
									appStoreIdAndroid !== null ? t('p.googlePlayLogoTradeMark') : ''
								}`}</p>
							</div>
						)}

						<p className="font-weight-bold text-dark">{t('p.accessWebsite')}</p>
						<p className="small mb-3">
							{t('l.youCanNow')} <Link to="/">{t('l.logIn')}</Link> {t('l.toThe')} {displayName ? displayName : ''} {t('l.website')}
						</p>
					</div>
				)}
			</AuthWrapper>
		</div>
	);
};
