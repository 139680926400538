import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSubmit } from '../../../components/ButtonSubmit';
import { UserContact } from '../../../components/UserContact';

const ChargePointRow = (props) => {
	const { item, removeFromOrder, onChange } = props;
	const { make, model, installAt, moreInfo } = item;

	const { t } = useTranslation();

	return (
		<>
			<hr />
			<div className="row my-3">
				<div className="col-12 col-md-6 col-lg-4">
					<label className="m-0">{t('cs.chargePointMake')}</label>
					<input className="form-control form-control-sm" name="make" defaultValue={make} value={make} onChange={onChange} />
				</div>

				<div className="col-12 col-md-6 col-lg-4">
					<label className="m-0">{t('cs.chargePointModel')}</label>
					<input className="form-control form-control-sm" name="model" defaultValue={model} value={model} onChange={onChange} />
				</div>

				<div className="col-12 col-lg-4">
					<label className="m-0">{t('cs.installationLocation')}</label>
					<input className="form-control form-control-sm" name="installAt" defaultValue={installAt} value={installAt} onChange={onChange} />
				</div>

				<div className="col-12">
					<label className="m-0">{t('cs.additionalInformation')}</label>
					<input className="form-control form-control-sm" name="moreInfo" defaultValue={moreInfo} value={moreInfo} onChange={onChange} />
				</div>
				<button className="btn btn-light mr-2 ml-3" style={{ marginTop: 18 }} onClick={removeFromOrder}>
					<i className="fal fa-trash" />
				</button>
			</div>
		</>
	);
};

export const OrderChargePoint = (props) => {
	const { onChange, isBusy, data } = props;
	const { chargePointList = [] } = data;

	const { t } = useTranslation();

	const addToOrder = (e) => {
		e.preventDefault();
		const newChargePointList = [...chargePointList, {}];
		onChange({ currentTarget: { name: 'chargePointList', value: newChargePointList } });
	};

	const removeFromOrder = (e, itemIndex) => {
		e.preventDefault();

		let newChargePointList = [...chargePointList];
		newChargePointList.splice(itemIndex, 1);

		onChange({ currentTarget: { name: 'chargePointList', value: newChargePointList } });
	};

	const onElementChange = (itemIndex, e) => {
		const { name, value } = e.currentTarget;
		const newItem = { ...chargePointList[itemIndex], [name]: value };

		let newChargePointList = [...chargePointList];

		newChargePointList.splice(itemIndex, 1, newItem);
		onChange({ currentTarget: { name: 'chargePointList', value: newChargePointList } });
	};

	return (
		<form>
			<UserContact {...props} />

			{chargePointList.map((element, index) => (
				<ChargePointRow key={`order-row-${index}`} item={element} removeFromOrder={(e) => removeFromOrder(e, index)} onChange={(e) => onElementChange(index, e)} />
			))}

			<div className="mb-5">
				<button className="btn btn-sm btn-accent-1" onClick={addToOrder} disabled={isBusy}>
					<i className="fal fa-plus mr-1" />
					{chargePointList.length === 0 ? t('cs.addDevice') : t('cs.addAnotherDevice')}
				</button>
			</div>

			<ButtonSubmit {...props} />
		</form>
	);
};
