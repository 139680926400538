import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { AuthWrapper } from './components/AuthWrapper';

import { useAuth } from '../../utils/Auth';
import Loading from '../../components/Loading';
import { RequestVerificationEmail } from './components/RequestVerificationEmail';

import { ReactComponent as ErrorImage } from '../../assets/images/expiredImage.svg';
import { ReactComponent as SentImage } from '../../assets/images/activationSent.svg';

import { GetThemeColour } from '../../utils/ThemeHelper';

const Verify = (props) => {
	const { t } = useTranslation();
	const { oobCode = null } = useParams();
	const { resendEmailVerificationEmail, verifyEmail } = useAuth();
	const navigate = useNavigate();
	const [hasError, setHasError] = useState(null);
	const [isDone, setIsDone] = useState(false);
	const [isBusy, setIsBusy] = useState(false);

	const doRequest = async (e, email = null) => {
		e.preventDefault();
		setIsBusy(true);
		setIsDone(false);
		setHasError('');
		try {
			await resendEmailVerificationEmail(email);
			setIsDone(true);
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	const doRetry = async (e) => {
		e?.preventDefault();
		navigate('/', { replace: true });
		window.location.reload();
	};

	const verifyCode = async () => {
		if (oobCode === null) return;

		try {
			const {
				data: { emailVerified, requiresPasswordReset, passwordResetToken },
			} = await verifyEmail(oobCode);

			if (!emailVerified) {
				throw new Error(t('p.failedToVerifyAccount'));
			} else if (requiresPasswordReset === true) {
				navigate(`/setPassword/${passwordResetToken}`, { replace: true });
			} else {
				setTimeout(async () => {
					await doRetry();
				}, 3000);
			}
		} catch (error) {
			setHasError(error.message);
		} finally {
			setIsBusy(false);
		}
	};

	useEffect(() => {
		verifyCode();
	}, [oobCode]);

	useEffect(() => {
		setHasError(oobCode === null ? t('p.validationCodeMissing') : null);
	}, [i18next.language]);

	return (
		<div className="row h-100 d-flex justify-content-md-center">
			{hasError !== null ? (
				<AuthWrapper>
					{!isDone ? (
						<div>
							<div className="d-flex mb-5 justify-content-center">
								<ErrorImage fill={GetThemeColour('--primary')} height={110} />
							</div>
							<div className="mb-3">
								<h5 className="mb-0">{hasError}</h5>
								<span className="small">{t('p.enterEmailAssociatedWithAccount')}</span>
							</div>

							<RequestVerificationEmail doRequest={doRequest} isBusy={isBusy} doRetry={doRetry} languageSelect={props.languageSelect} />
						</div>
					) : (
						<div>
							<div className="d-flex mb-5 justify-content-center">
								<SentImage fill={GetThemeColour('--primary')} height={110} />
							</div>
							<div className="mb-3">
								<h5 className="mb-0">{t('p.thankYou')}</h5>
								<span className="small">{t('p.activationLinkSent')}</span>
							</div>
						</div>
					)}
				</AuthWrapper>
			) : (
				<Loading message={t('p.validatingPleaseWait')} />
			)}
		</div>
	);
};

export default Verify;
