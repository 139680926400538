import { HttpHandler } from '../utils/HttpHandler';

export const HttpContext = () => {
	const { httpGet, httpPost, httpPut, httpDel } = HttpHandler();

	const getBalance = async () => {
		try {
			return await httpGet('account/balance');
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const getProducts = async (currentPage, pageSize) => {
		try {
			let extraParams = '';
			if (currentPage && pageSize) {
				extraParams = `/${currentPage}/${pageSize}`;
			}

			return await httpGet(`products${extraParams}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const getTransactions = async (currentPage, pageSize) => {
		try {
			let extraParams = '';
			if (currentPage && pageSize) {
				extraParams = `/${currentPage}/${pageSize}`;
			}

			return await httpGet(`transactions${extraParams}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const getTransaction = async (transactionId) => {
		try {
			return await httpGet(`transactions/${transactionId}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const transactionPrep = async (product) => {
		try {
			return await httpPost(`transactions`, null, { order: [product], callbackUrl: process.env.APP_TRN_CALLBACK });
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const transactionValidate = async (transactionRef) => {
		try {
			return await httpGet(`transactions/validate/${transactionRef}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const transactionCancel = async (transactionRef) => {
		try {
			return await httpDel(`transactions/${transactionRef}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const transactionReimburse = async (transactions) => {
		try {
			return await httpPost(`chargeTransactionStatement`, null, transactions);
		} catch (error) {
			console.log(error);
			// OkAlert('System Error', 'Error reimbursing transactions. Please ensure your interent connection is working.');
			return null;
		}
	};

	const statementPdfDownload = async (statementId) => {
		try {
			return await httpGet(`chargeTransactionStatement/${statementId}`, null, 'blob');
		} catch (error) {
			console.log(error);
			// OkAlert('System Error', 'Error reimbursing transactions. Please ensure your interent connection is working.');
			return null;
		}
	};

	const getAccountUsers = async (currentPage, pageSize) => {
		try {
			let extraParams = '';
			if (currentPage && pageSize) {
				extraParams = `/${currentPage}/${pageSize}`;
			}

			return await httpGet(`users${extraParams}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const getAccountUser = async (userId, includeUsage = false, days) => {
		try {
			let usageUrl = '';
			if (includeUsage && days) {
				usageUrl = `/usage/${days}`;
			}

			return await httpGet(`users/${userId}${usageUrl}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const saveAccountUser = async (user) => {
		try {
			return await httpPost(`users`, null, user);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const validateTag = async (tagId) => {
		try {
			return await httpPost(`tags/validate`, null, { tageId: tagId });
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const getSessionSummary = async (dataSet, days) => {
		try {
			let extraParams = '';
			if (dataSet && days) {
				extraParams = `/${dataSet}/${days}`;
			}

			return await httpGet(`sessions/summary${extraParams}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const getSessions = async (userId, tagId, currentPage, pageSize) => {
		try {
			let extraParams = '';
			if (userId) {
				extraParams += `/tag/${userId}`;
			} else if (tagId) {
				extraParams += `/tag/${userId}`;
			}

			if (currentPage && pageSize) {
				extraParams += `/${currentPage}/${pageSize}`;
			}

			return await httpGet(`sessions${extraParams}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const getSession = async (sessionId) => {
		try {
			return await httpGet(`sessions/${sessionId}`);
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Error loading your profile. Please ensure your interent connection is working.');
			return null;
		}
	};

	const update = async (data) => {
		try {
			const result = await httpPut('profile', null, data);
			//OkAlert('Fantastic!', 'Profile updated successfully');
			return result != null;
		} catch (error) {
			console.log(error);
			//OkAlert('System Error', 'Could not update profile. Please check your internet connection and try again.');
			return null;
		}
	};

	return {
		getBalance,
		getProducts,
		getTransactions,
		getTransaction,
		transactionPrep,
		transactionValidate,
		transactionCancel,
		transactionReimburse,
		statementPdfDownload,
		getAccountUsers,
		getAccountUser,
		saveAccountUser,
		validateTag,
		getSessionSummary,
		getSessions,
		getSession,
		update,
	};
};
