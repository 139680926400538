export const InputValidityHelper = {
    isPasswordUpdateFormValid: (hasError, oobCode, credentials, label1, label2) => {
        return (
            hasError === null &&
            oobCode !== null &&
            credentials &&
            credentials[label1] &&
            credentials[label1] !== '' &&
            credentials[label1].length >= 8 &&
            credentials[label2] &&
            credentials[label2] !== '' &&
            credentials[label2].length >= 8 &&
            credentials[label1] === credentials[label2]
        )
    }

} 