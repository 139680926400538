import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { BreadCrumb } from '../../components/layout/BreadCrumb';

import { Page } from '../../components/layout/Page';
import { TileDetail } from '../../components/tiles/TileDetail';

export const SupportHome = () => {
	const navigate = useNavigate();

	const { t } = useTranslation();

	const navBackward = () => {
		navigate(-1);
	};

	return (
		<Page>
			<BreadCrumb title={t('cs.customerSupport')} navBackward={navBackward} />
			<Row className="mt-3 justify-content-md-start">
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-debug" title={t('cs.reportAProblem')} to="/support/problem" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-charging-station" title={t('cs.orderChargePoints')} to="/support/orderChargePoints" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-trash" title={t('cs.removeChargePoint')} to="/support/removeChargePoint" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>

				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-circle-phone-flip" title={t('cs.requestCallback')} to="/support/callback" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-question-circle" title={t('cs.invoiceQuery')} to="/support/invoice" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-chalkboard-teacher" title={t('cs.requestTraining')} to="/support/training" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>

				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-lightbulb-on" title={t('cs.suggestNewFeature')} to="/support/feature" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-bags-shopping" title={t('cs.orderChargeCards')}to="/support/orderChargeCards" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>
				<Col className="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-4">
					<TileDetail icon="fal fa-ban" title={t('cs.stopChargeCards')} to="/support/stopChargeCard" iconColor="secondary" showLogo={false} hasMoreLink={false} />
				</Col>
			</Row>
		</Page>
	);
};
