import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../utils/Auth';
import { Link, useNavigate } from 'react-router-dom';

import { LeftPane } from './components/LeftPane';
import { AuthWrapper } from './components/AuthWrapper';
import { ErrorAlert } from '../../components/GeneralError';

const Login = (props) => {
	const navigate = useNavigate();
	const { login, verifyMfa } = useAuth();
	const [credentials, setCredentials] = useState({});
	const [hasError, setHasError] = useState(null);
	const [requireMfa, setRequireMfa] = useState(false);
	const [isBusy, setIsBusy] = useState(false);

	const { t } = useTranslation();

	const isValid = hasError === null && credentials && credentials.email && credentials.email !== '' && credentials.password && credentials.password !== '';

	const onChange = (e) => {
		setHasError(null);
		const { currentTarget } = e;
		const { name, value } = currentTarget;

		const newCredentials = { ...credentials, [name]: value };
		setCredentials(newCredentials);
	};

	const doLogin = async (e) => {
		e.preventDefault();
		setIsBusy(true);
		try {
			const hasMfa = await await login(credentials.email, credentials.password);
			if (hasMfa === true) {
				setRequireMfa(true);
			} else navigate('/', { replace: true });
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	const verifyCode = async () => {
		setIsBusy(true);
		try {
			await verifyMfa(credentials.verificationCode);
			navigate('/', { replace: true });
		} catch (error) {
			setHasError(error.message);
		}
		setIsBusy(false);
	};

	if (requireMfa)
		return (
			<div className="row h-100 d-flex justify-content-md-center">
				<AuthWrapper>
					<label>{t('d.verificationCode')}</label>
					<input className="form-control mb-3" type="text" placeholder={t('l.verificationCode')} name="verificationCode" onChange={onChange} />

					<p className="text-danger mb-2">{hasError}</p>

					<button className={`btn btn-primary ${!isBusy ? '' : 'disabled'}`} onClick={verifyCode}>
						<i className={`fal ${isBusy ? 'fa-sync fa-spin' : 'fa-check'} text-white mr-2`} />
						<span className="text-white">{t('l.verify')}</span>
					</button>
				</AuthWrapper>
			</div>
		);

	return (
		<div className="row h-100 d-flex justify-content-md-center">
			{/* <LeftPane /> */}
			<AuthWrapper>
				<form>
					{hasError && <ErrorAlert message={hasError} margin="" />}
					<div className="form-group">
						<label>{t('l.emailAddress')}</label>
						<input className="form-control mb-3" type="text" placeholder={t('l.emailAddress')} name="email" onChange={onChange} />
					</div>
					<div className="form-group">
						<label>{t('l.password')}</label>
						<input className="form-control mb-3" type="password" placeholder={t('l.password')} name="password" onChange={onChange} autoComplete="off" />
					</div>
					<button className="btn btn-primary" onClick={doLogin} disabled={!isValid || isBusy}>
						{isBusy && <i className="fal fa-refresh fa-spin mr-2" />}
						{t('l.login')}
					</button>
				</form>
				<div className="d-flex justify-content-between mt-3">
					<Link className="text-secondary" to="/forgot">
						{t('l.forgotPassword')}
					</Link>
					{props.languageSelect}

					{/*This is disabled for now*/}
					{/*<div className="mt-3">
						<span>Don't have an acount?</span>
						<Link className="text-secondary ml-2" to="/register">
							Register Here
						</Link>
					</div>*/}
				</div>
			</AuthWrapper>
		</div>
	);
};

export default Login;
