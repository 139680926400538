import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from './Loading';

export const UserContact = (props) => {
	const { onChange, isBusy, data } = props;
	const { contactName, contactNumber, contactEmail } = data ?? {};

	const { t } = useTranslation();

	if (!data) return <Loading />;

	return (
		<Fragment>
			<div className="row mb-3">
				<div className="col">
					<label className="mb-0">{t('c.Name')}</label>
					<input className="form-control form-control-sm " type="text" name="contactName" onChange={onChange} disabled={isBusy} value={contactName} />
				</div>
			</div>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('c.contactNumber')}</label>
					<input className="form-control form-control-sm" type="text" name="contactNumber" onChange={onChange} disabled={isBusy} value={contactNumber} />
				</div>
			</div>
			<div className="row mb-3">
				<div className="col">
					<label className="m-0">{t('c.emailAddress')}</label>
					<input className="form-control form-control-sm" type="text" name="contactEmail" onChange={onChange} disabled={isBusy} value={contactEmail} />
				</div>
			</div>
		</Fragment>
	);
};
