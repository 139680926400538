import React from 'react';
import { useTranslation } from 'react-i18next';

const daysActiveDefault = (days) => {
	if (days === null) {
		return [true, true, true, true, true, true, true];
	} else {
		return days;
	}
};

export const DayOptions = (props) => {
	const { t } = useTranslation();

	// Initial state of 'daysActive' contains booleans for each day of the week (Mon - Sun)
	const { title = t('g.daysActive'), onChange = null } = props;
	const daysActive = daysActiveDefault(props.daysActive);

	const toggleDay = (dayIndex) => {
		const newDays = [...daysActive];
		newDays[dayIndex] = !newDays[dayIndex];

		if (onChange) onChange({ currentTarget: { name: 'daysActive', value: newDays } });
	};

	return (
		<form className="d-flex flex-column align-items-center w-100">
			<label className="m-0 mb-1">{title}</label>
			<ul className="d-flex">
				<li className={`btn btn-sm btn-icon mr-2 ${daysActive[0] ? 'active' : ''}`} style={{ paddingTop: 3 }} onClick={() => toggleDay(0)}>
					M
				</li>

				<li className={`btn btn-sm btn-icon mr-2 ${daysActive[1] ? 'active' : ''}`} style={{ paddingTop: 3 }} onClick={() => toggleDay(1)}>
					T
				</li>

				<li className={`btn btn-sm btn-icon mr-2 ${daysActive[2] ? 'active' : ''}`} style={{ paddingTop: 3 }} onClick={() => toggleDay(2)}>
					W
				</li>

				<li className={`btn btn-sm btn-icon mr-2 ${daysActive[3] ? 'active' : ''}`} style={{ paddingTop: 3 }} onClick={() => toggleDay(3)}>
					T
				</li>

				<li className={`btn btn-sm btn-icon mr-2 ${daysActive[4] ? 'active' : ''}`} style={{ paddingTop: 3 }} onClick={() => toggleDay(4)}>
					F
				</li>

				<li className={`btn btn-sm btn-icon mr-2 ${daysActive[5] ? 'active' : ''}`} style={{ paddingTop: 3 }} onClick={() => toggleDay(5)}>
					S
				</li>

				<li className={`btn btn-sm btn-icon mr-2 ${daysActive[6] ? 'active' : ''}`} style={{ paddingTop: 3 }} onClick={() => toggleDay(6)}>
					S
				</li>
			</ul>
		</form>
	);
};
