import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { TileImage } from '../../../components/tiles/TileImage';

export const FilterInitial = (props) => {
	const { t } = useTranslation();
	const { setFilter } = props;

	const updateFilter = (text, description, dataType, stepSize) => {
		setFilter({ text: text, dataType: dataType, stepSize: stepSize, description: description });
	};

	return (
		<Card>
			<CardBody className="mt-3 mb-3">
				<Row className="justify-content-start">
					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3 offset-0 offset-lg-3">
						<TileImage icon="fal fa-clipboard-user" title={t('f.reimbursement')} onClick={() => updateFilter('Reimbursement', t('f.reimbursement'), 'reimbursements', 1)} />
					</Col>

					<Col className="col-sm-12 col-md-6 col-lg-4 col-xl-3">
						<TileImage icon="fal fa-file-invoice" title={t('f.invoice')} onClick={() => updateFilter('Invoice', t('f.invoice'), 'chargeCards', 2)} />
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
