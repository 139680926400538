import React, { Fragment, useState } from 'react';
import { useAuth } from '../../../utils/Auth';
import { useTranslation } from 'react-i18next';

export const RequestVerificationEmail = (props) => {
	const { t } = useTranslation();
	const { user = null } = useAuth();
	const { doRequest, isBusy } = props;

	const [email, setEmail] = useState(user?.email ?? '');

	const onChange = (e) => {
		const { value } = e.currentTarget;
		setEmail(value);
	};

	return (
		<Fragment>
			<div className="form-group">
				<label>{t('l.emailAddress')}</label>
				<input className="form-control mb-3" type="email" placeholder={t('l.emailAddress')} name="email" value={email} onChange={onChange} readOnly={user !== null} />
			</div>

			<div className="d-flex justify-content-between align-items-center mt-3">
				<button className="btn btn-primary" onClick={(e) => doRequest(e, email)} disabled={isBusy || email === '' || email === null}>
					{isBusy && <i className="fal fa-refresh fa-spin mr-2" />}
					{t('a.sendANewLink')}
				</button>
				{props.languageSelect}
			</div>
		</Fragment>
	);
};
