import React from 'react';
import { useTranslation } from 'react-i18next';
import { CardWithHeaderActions } from '../layout/CardContent';

import FilterActions from '../../components/charts/FilterButtons';
import BarChart from '../../components/charts/BarChart';
import { EmptyData } from '../EmptyData';

const TransactionSpendGraph = (props) => {
	const { t } = useTranslation();

	const { data, updateFilter, height = 240 } = props;

	// Detemrine if the graph has data
	const hasData = data.datasets[0].data.length != 0;

	const updateTheFilter = async (days) => {
		if (updateFilter) await updateFilter(1, days);
	};

	return (
		<CardWithHeaderActions heading={t('g.energyConsumed')} actions={<FilterActions action={updateTheFilter} />} style={{ minHeight: 314 }} headerStyle={{ minHeight: 62 }}>
			{hasData ? <BarChart height={height} data={data} /> : <EmptyData />}
		</CardWithHeaderActions>
	);
};

export default TransactionSpendGraph;
