import React from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, Alert, Button } from 'reactstrap';

import BackgroundImage from '../../src/assets/images/background.jpg';

const GeneralError = ({ message, wrapped = true, rowWrapped = true, width }) => {
	const { t } = useTranslation();

	const content = (message) => {
		return (
			<div>
				<h4>
					<i className="fal fa-exclamation-triangle mr-2" />
					{t('g.error')}
				</h4>
				<h6>{message}</h6>
				<p>{t('g.reloadingPage')}</p>
			</div>
		);
	};

	const isWrapped = (message, width) => {
		return (
			<Col md={width || 12} xl={width || 12} className="mb-30">
				<Card className="card-statistics h-100">
					<CardBody>{content(message)}</CardBody>
				</Card>
			</Col>
		);
	};

	const isRowWrapped = (message, width) => {
		return <Row>{isWrapped(message, width)}</Row>;
	};

	return !wrapped ? content(message) : rowWrapped ? isRowWrapped(message, width) : isWrapped(message, width);
};

const ErrorPage = ({ message }) => {
	const { t } = useTranslation();

	return (
		<div className="justify-content-center d-flex flex-column" style={{ height: '100vh', gap: '15px' }} >
			<div className='image-mask'>
				<img src={BackgroundImage} style={{ width: '100%', height: '100%', opacity: 0.7 }} />
			</div>
			<div className='text-center error-info-container'>
				<h3 style={{ fontWeight: 'bold' }}>
				{t('g.errorEncountered')}
				</h3>
				<br/>
				<h6>{t('g.reason')} {message}</h6>
				<div>{t('g.reloadPage')}</div>
				<br/>
				<Button className="button button-success" href='/' >{t('g.reload')}
				</Button>
			</div>
		</div>
	)
};

const ErrorAlert = ({ message, margin = 'm-3' }) => {
	return message === '' ? null : (
		<div className={`alert alert-danger ${margin}`}>
			<i className="fal fa-exclamation-triangle mr-2" />
			{message}
		</div>
	);
};

const NotificationAlert = ({ message, margin = 'm-3' }) => {
	return message === '' ? null : (
		<div className={`alert alert-success ${margin}`}>
			<i className="fal fa-check mr-2" />
			{message}
		</div>
	);
};

const NoDataAlert = ({ message = null }) => {
	const { t } = useTranslation();

	message = message ?? t('g.noItemsAvaliable');

	return message === '' ? null : (
		<Alert className="alert-light mt-3">
			<i className="fal fa-question-circle mr-2" />
			{message}
		</Alert>
	);
};

export { ErrorAlert, ErrorPage, NoDataAlert, NotificationAlert };
export default GeneralError;
