export const GetThemeColour = (colourName = '--primary') => {
	var style = getComputedStyle(document.body);
	return style.getPropertyValue(colourName);
};

export const SetTitle = (title = '', translate) => {
	if (title === undefined || title === null || title === '') {
		document.title = translate('l.customerPortal');
	} else {
		document.title = `${title} - ${translate('l.portal')}`;
	}
};

export const SetThemeColour = (colourName = '--primary', value) => {
	document.documentElement.style.setProperty(colourName, value);
};

export const SetFavIcon = (url = '') => {
	var link = document.querySelector("link[rel~='icon']");
	if (link) link.href = url;
};
