import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { TileImage } from '../tiles/TileImage';

export const FilterChargePointOptions = (props) => {
	const { t } = useTranslation();
	const { setFilter, allChargePointsStep = 4 } = props;

	const updateFilter = (option, description, stepSize) => {
		setFilter({ text: option, description: description, dataType: 'chargepoints', stepSize: stepSize });
	};

	return (
		<Card>
			<CardBody>
				<Row className="justify-content-md-center">
					<Col className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<TileImage icon="fal fa-charging-station" title={t('t.individualChargePoints')} onClick={() => updateFilter(t('t.individualChargePoints'), '', 1)} />
					</Col>
					<Col className="col-sm-6 col-md-6 col-lg-4 col-xl-3">
						<TileImage icon="fa-light fa-bolt" title={t('t.allChargePoints')} onClick={() => updateFilter(t('t.allChargePoints'), t('t.allChargePoints'), allChargePointsStep)} />
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};
