import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LineChart from '../../../components/charts/LineChart';
import { ErrorAlert, NoDataAlert } from '../../../components/GeneralError';
import Loading from '../../../components/Loading';
import { HttpHandler } from '../../../utils/HttpHandler';
import { Settings } from '../../../utils/Settings';
import { GetThemeColour } from '../../../utils/ThemeHelper';

const buildGraphData = (t, graphData = null) => {
	const { formatDate } = Settings;
	if (graphData === null || (graphData.values?.length ?? []) <= 0) return {};

	const data = {
		labels: [],
		datasets: [
			{
				label: t('g.kWhConsumed'),
				data: [],
				borderColor: GetThemeColour('--primary-50'),
				backgroundColor: GetThemeColour('--primary-25'),
				tension: 0.355,
				fill: true,
			},
		],
	};

	graphData.values.map((element) => {
		const { value, timeStamp } = element;
		data.labels.push(formatDate(timeStamp, 'time'));
		data.datasets[0].data.push(value);
	});

	return data;
};

export const SessionGraph = ({ sessionId }) => {
	const { httpGet } = HttpHandler();
	const [isBusy, setIsBusy] = useState(false);
	const [hasError, setHasError] = useState('');
	const [graphData, setGraphData] = useState(null);

	const { t } = useTranslation();

	const getData = async () => {
		setIsBusy(true);
		try {
			const result = await httpGet(`/chargeTransaction/${sessionId}/meterValues/4`);
			if (result && result.data) {
				setGraphData(result.data[0]);
			} else throw new Error(t('g.noValidData'));
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	useEffect(() => {
		getData();
	}, [sessionId]);

	if (hasError !== '') {
		return <ErrorAlert message={hasError} margin="" />;
	}

	if (isBusy) return <Loading />;
	if (graphData === null) return <NoDataAlert />;

	return <LineChart height={320} data={buildGraphData(t, graphData)} />;
};
