import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import _ from 'lodash';

import { Page } from '../../components/layout/Page';
import { BreadCrumb } from '../../components/layout/BreadCrumb';
import { FilterRecords } from '../../components/filters/FilterRecords';
import { ChargePointInfo } from './components/ChargePointInfo';

import { PagedListContext } from '../../dataAccess/PagedListContext';
import Loading from '../../components/Loading';
import { Card, CardBody } from 'reactstrap';
import { ChargePointRemove } from './components/ChargePointRemove';
import { ChargeSchedule } from '../schedule/ChargeSchedule';

const mapData = (items = [], locationId = null) => {
	const locations = [
		...items.map((element) => {
			return { recId: element.locationId, identifier: element.parentName, checked: false, description: element.locationName };
		}),
	].filter((element, index, self) => index === self.findIndex((x) => x.recId === element.recId));

	const chargePoints = [
		...items.map((element) => {
			return { locationId: element.locationId, recId: element.recId, identifier: element.identity, checked: false, description: element.physicalReference ?? element.identity ?? '<not set>' };
		}),
	].filter((element) => element.locationId === locationId);

	return { locations, chargePoints };
};

export const ChargePointFilter = (props) => {
	const { t } = useTranslation();

	const { step, stepSize, locationId, cpId, type } = useParams();
	const { pathname } = useLocation();
	const { title = t('cp.chargePoints'), stepSizeInitial = 1 } = props;

	const navigate = useNavigate();
	const [filter, setFilter] = useState([]);

	const { data, getData, sortData } = PagedListContext('chargePoint', null, 300000);
	const { isBusy } = data;
	const mappedData = mapData(data.items, locationId);

	const updateFilter = (filterItem) => {
		filter.push(filterItem);
		navForward(filterItem);
	};

	const navForward = (filterItem) => {
		const nextIndex = step ? +step + (stepSize ? +stepSize : +stepSizeInitial) : 1;
		const route = pathname ? pathname.split('/')[1] : '';

		if (!nextIndex) navigate(`/${route}`);
		if (nextIndex === 1)
			navigate(
				`/${route}/${type ? type + '/' : ''}${(stepSize ? stepSize : stepSizeInitial) + '/'}${nextIndex}${filterItem?.selection?.[0].recId ? '/' + filterItem?.selection?.[0].recId : ''}`,
			);
		if (nextIndex === 2)
			navigate(
				`/${route}/${type ? type + '/' : ''}${(stepSize ? stepSize : stepSizeInitial) + '/'}${nextIndex}${
					filterItem?.selection?.[0].locationId ? '/' + filterItem?.selection?.[0].locationId : ''
				}${filterItem?.selection?.[0].recId ? '/' + filterItem?.selection?.[0].recId : ''}`,
			);
		if (nextIndex === 3)
			navigate(
				`/${route}/${type ? type + '/' : ''}${(stepSize ? stepSize : stepSizeInitial) + '/'}${nextIndex}${
					filterItem?.selection?.[0].locationId ? '/' + filterItem?.selection?.[0].locationId : ''
				}${filterItem?.selection?.[0].recId ? '/' + filterItem?.selection?.[0].recId : ''}`,
			);
		if (nextIndex === 4)
			navigate(
				`/${route}/${type ? type + '/' : ''}${(stepSize ? stepSize : stepSizeInitial) + '/'}${nextIndex}${
					filterItem?.selection?.[0].locationId ? '/' + filterItem?.selection?.[0].locationId : ''
				}${filterItem?.selection?.[0].recId ? '/' + filterItem?.selection?.[0].recId : ''}`,
			);
	};

	const navBackward = () => {
		filter.pop();
		setFilter(filter);

		navigate(-1);
	};

	const sorting = (arrayProp, prop, id, order) => {
		const index = data.items.findIndex((i) => i.recId === id);
		let dataCopy = [...data.items];

		dataCopy[index][arrayProp] = _.orderBy(dataCopy[index][arrayProp], [prop], order);

		sortData(dataCopy);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Page>
			<BreadCrumb title={title} filter={filter} navBackward={navBackward} />
			{isBusy ? (
				<Card>
					<CardBody>
						<Loading message={t('g.loadingChargePoints')} />
					</CardBody>
				</Card>
			) : (
				<Fragment>
					{!step && <FilterRecords heading={t('t.locations')} setFilter={updateFilter} stepSize={1} multiSelect={false} items={mappedData.locations} />}
					{step == 1 && <FilterRecords heading={t('cp.chargePoints')} setFilter={updateFilter} multiSelect={false} items={mappedData.chargePoints} />}
					{step == 2 && (
						<ChargePointInfo
							chargePoint={data.items.find((item) => item.recId === cpId)}
							sorting={sorting}
							connectorSorting={'connectorSummary'}
							sortKeys={['index', 'status']}
							connectorReferencePropName={'physicalReference'}
						/>
					)}
					{step == 3 && <ChargePointRemove chargePoint={data.items.find((item) => item.recId === cpId)} navBackward={navBackward} />}
					{step == 4 && <ChargeSchedule chargePoint={data.items.find((item) => item.recId === cpId)} navBackward={navBackward} />}
				</Fragment>
			)}
		</Page>
	);
};
