import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpHandler } from '../utils/HttpHandler';

export const ButtonRestart = (props) => {
	const { httpPost } = HttpHandler();
	const [isBusy, setIsBusy] = useState(false);
	const [hasError, setHasError] = useState('');

	const { t } = useTranslation();

	const { isOnline, chargePointId = null } = props;

	const restart = async () => {
		setIsBusy(true);
		try {
			const result = await httpPost(`chargePointSupport/${chargePointId}/reset`, null, { resetType: 'Hard' });
		} catch (error) {
			console.error(error);
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	if (chargePointId === null) return null;

	return (
		<Fragment>
			<Fragment>
				<div className="d-none d-md-flex d-print-none align-items-center ">
					<button className="btn btn-sm btn-outline-primary" onClick={restart} /*disabled={!isOnline || isBusy}*/ title={isOnline ? t('sc.resetChargePoint') : t('sc.chargePointOffline')}>
						<i className={`fal ${isBusy ? 'fa-sync fa-spin' : 'fa-power-off'}  mr-2`} />
						{t('cp.restart')}
					</button>
				</div>

				<div className="d-flex d-md-none">
					<button className="btn btn-icon btn-outline-primary" onClick={restart} /*disabled={!isOnline || isBusy}*/ title={isOnline ? t('sc.restartChargePoint') : t('sc.chargePointOffline')}>
						<i className={`fal ${isBusy ? 'fa-sync fa-spin' : 'fa-power-off'}`} />
					</button>
				</div>
			</Fragment>
		</Fragment>
	);
};
