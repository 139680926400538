import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpHandler } from '../utils/HttpHandler';

import { Settings } from '../utils/Settings';
import { ConfirmModal, OkModal } from './modals/Modals';

export const ButtonUnclockConnector = (session) => {
	const { httpPost } = HttpHandler();
	const { t } = useTranslation();
	const [data, setData] = useState({
		modal: false,
		okModal: false,
		isBusy: false,
		hasError: '',
		message: '',
	});

	const { chargePointId = null, transactionConnectorId = null, transactionDateStopped = null } = session;

	const unlockModal = () => {
		setData({ ...data, modal: !data.modal });
	};

	const completionModal = () => {
		setData({ ...data, okModal: !data.okModal });
	};

	const unlock = async () => {
		setData({ ...data, modal: false, isBusy: true, hasError: '', message: '' });

		try {
			const result = await httpPost(`/chargePointSupport/${chargePointId}/unlock/${transactionConnectorId}`);

			if (result?.data?.status.toLowerCase() === 'accepted') {
				setData({ ...data, message: `${t('c.successfullyUnlockedConnector')} ${transactionConnectorId}.`, isBusy: false, okModal: true, modal: false });
			} else {
				setData({ ...data, hasError: `${t('c.unableToUnlockConnector')} ${transactionConnectorId}.`, isBusy: false, okModal: true, modal: false });
			}
		} catch (error) {
			setData({ ...data, hasError: `${((error.response || {}).data || {}).message || error.message || ''}`, isBusy: false, okModal: true, modal: false });
		}
	};

	if (chargePointId === null || transactionConnectorId === null || !Settings.emptyDate(transactionDateStopped)) return null;

	return (
		<Fragment>
			<ConfirmModal children={`${t('c.doYouWantToUnlockConnector')} ${transactionConnectorId}?`} isOpen={data.modal} btn1Action={unlockModal} btn2Action={unlock} />
			<OkModal
				isOpen={data.okModal}
				btnAction={completionModal}
				children={data.hasError ? data.hasError : data.message}
				headerIcon={data.message ? 'fa-circle-check' : 'fa-circle-info'}
				title={data.message ? t('e.success') : t('e.error')}
			/>
			<Fragment>
				<div className="d-none d-md-flex d-print-none align-items-center ">
					<button className="btn btn-sm btn-outline-primary" onClick={unlockModal} disabled={data.isBusy} title={t('c.unlockConnector')}>
						<i className={`fal ${data.isBusy ? 'fa-sync fa-spin' : 'fa-regular fa-unlock'}  mr-2`} />
						{t('c.unlockConnector')}
					</button>
				</div>

				<div className="d-flex d-md-none">
					<button className="btn btn-icon btn-outline-primary" onClick={unlockModal} disabled={data.isBusy} title={t('c.unlockConnector')}>
						<i className={`fal ${data.isBusy ? 'fa-sync fa-spin' : 'fa-lock-open'}`} />
					</button>
				</div>
			</Fragment>
		</Fragment>
	);
};
