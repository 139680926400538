import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ButtonSubmit } from '../../../components/ButtonSubmit';
import { ErrorAlert, NotificationAlert } from '../../../components/GeneralError';
import { CardWithHeader } from '../../../components/layout/CardContent';
import { HttpHandler } from '../../../utils/HttpHandler';

export const ChargePointRemove = (props) => {
	const { chargePoint = {}, navBackward } = props;
	const [isBusy, setIsBusy] = useState(false);
	const [hasError, setHasError] = useState('');
	const [hasSuccess, setHasSuccess] = useState('');

	const { t } = useTranslation();

	const { httpPost } = HttpHandler();
	const [data, setData] = useState({ type: 'removeChargePoints', stopReason: 1, chargePointList: chargePoint });

	const onChange = (e) => {
		const { name, value } = e.currentTarget;

		setData({ ...data, [name]: value });
	};

	const onSubmit = async (event) => {
		event.preventDefault();

		setIsBusy(true);
		setHasError('');
		setHasSuccess('');
		try {
			const result = await httpPost('/support', null, data);
			if (result) {
				setHasSuccess(result.data?.message ?? t('cs.requestSubmittedSuccessfully'));
			} else throw new Error(t('cs.error'));
		} catch (error) {
			setHasError(`${((error.response || {}).data || {}).message || error.message || ''}`);
		} finally {
			setIsBusy(false);
		}
	};

	return (
		<CardWithHeader heading={t('cs.removeChargePoint')}>
			<ErrorAlert message={hasError} margin={0} />
			<NotificationAlert message={hasSuccess} margin={0} />

			<div className="mb-3">
				<label className="m-0">{t('cs.selectReasonForElectedChargePoint')}</label>
				<select className="form-control form-control-sm" name="stopReason" onChange={onChange} disabled={isBusy}>
					<option value={1}>{t('cs.damaged')}</option>
					<option value={2}>{t('cs.warranteeClaim')}</option>
				</select>
			</div>

			<div className="mb-5">
				<ul className="pl-4">
					<li>{`${chargePoint.physicalReference ? chargePoint.physicalReference : chargePoint.identity} (${chargePoint.identity})`}</li>
				</ul>
			</div>

			<ButtonSubmit btnSubmitClick={onSubmit} btnResetClick={navBackward} isBusy={isBusy} isDirty={chargePoint.length > 0} />
		</CardWithHeader>
	);
};
