import React, { useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import useSupercluster from 'use-supercluster';

import { MapMarker } from './components/MapMarker';
import { MapLocationsContext } from '../../dataAccess/MapLocationsContext';
import { useTheme } from '../../utils/ThemeContextProvider';

export const MapLocations = () => {
	const theme = useTheme();
	const mapRef = useRef();
	const { data, getData, mapChanged, setLocation } = MapLocationsContext();

	const { locations, locationId, centre, bounds, zoom } = data;

	const defaultPosition = { lat: theme.latitude ?? 0, lng: theme.longitude ?? 0 };
	const { clusters, supercluster } = useSupercluster({ points: locations, bounds, zoom, options: { radius: 50, maxZoom: 13 } });

	const zoomCluster = async (clusterId, latitude, longitude) => {
		const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(clusterId), 15);

		mapRef.current.panTo({ lat: latitude, lng: longitude });
		mapRef.current.setZoom(expansionZoom);
	};

	const zoomLocation = async (locationId, latitude, longitude) => {
		const locationZoom = Math.max(zoom, 18);
		setLocation(locationId);

		await mapRef.current.panTo({ lat: latitude, lng: longitude });
		await mapRef.current.setZoom(locationZoom);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<GoogleMapReact
			options={{
				mapTypeControl: true,
				styles: [
					{
						featureType: 'poi.business',
						elementType: 'labels',
						stylers: [
							{
								visibility: 'off',
							},
						],
					},
				],
			}}
			bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_KEY }}
			defaultCenter={defaultPosition}
			defaultZoom={1}
			center={centre}
			zoom={zoom}
			onChange={mapChanged}
			yesIWantToUseGoogleMapApiInternals
			onGoogleApiLoaded={({ map }) => {
				mapRef.current = map;
			}}
		>
			{(theme.useClusters ? clusters : locations).map((cluster, index) => {
				const [lng, lat] = cluster.geometry.coordinates;
				return <MapMarker key={`location-${index}`} lat={lat} lng={lng} selectedLocationId={locationId} locationInfo={cluster} locationCount={clusters.length} zoomCluster={zoomCluster} zoomLocation={zoomLocation} clusterEnabled={false} />;
			})}
		</GoogleMapReact>
	);
};
