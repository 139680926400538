const EmptyGuid = '00000000-0000-0000-0000-000000000000';

const isNumeric = (value) => {
    // Check if the value is a number type.
    // This check is required since booleans will pass the isNaN check.
    if (typeof value !== "number") {
        return false;
    }

    // Check if the value is Null.
    // This check is required since the value null will pass the isNaN check.
    if (value === null) {
        return false;
    }

    // Check if the value is undefined or NaN.
    if (isNaN(value)) {
        return false;
    }

    // The value is a number, not NaN, not Null or Undefined.
    return true;
};

export { EmptyGuid, isNumeric };
