import React from 'react';

import { useTheme } from '../../../utils/ThemeContextProvider';

import defaultLogo from '../../../assets/images/logo.png';

export const AuthWrapper = ({ children }) => {
	const theme = useTheme();

	return (
		<div className="col col-12 order-1 col-sm-10 col-md-8 col-lg-6 col-xl-4 order-md-2 p-0 ">
			<div className="d-flex justify-content-center align-items-center h-100">
				<div className="w-75">
					<div className="d-flex mb-5 justify-content-center">
						<img src={theme.logoUrl ?? defaultLogo} alt="Application Logo" style={{ maxHeight: '150px', maxWidth: '350px' }} />
					</div>
					{children}
				</div>
			</div>
		</div>
	);
};
