import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { ButtonNav } from '../Buttons';
import { EmptyData } from '../EmptyData';
import { Pagination } from '../layout/Pagination';
import Loading from '../Loading';
import { SelectFilter } from './SelectFilter';

const DataList = (props) => {
	const { t } = useTranslation();
	const { heading, stepSize, setFilter, items = [], multiSelect = true, pageSize = 21 } = props;

	const [itemsToList, setItemsToList] = useState(items);
	const [itemsSelected, setItemsSelected] = useState([]);
	const [pageItems, setPageItem] = useState([]);

	const [hasSomething, setHasSomething] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);

	const columnCount = Math.min(items.length / 5, 3);

	const itemChecked = (recId) => {
		const newItems = itemsToList.map((item) => {
			if (!multiSelect) item.checked = false;

			if (item.recId === recId) {
				item.checked = !item.checked;
			}

			return item;
		});

		buildSelection(newItems);
		setItemsToList(newItems);

		// Look to see if we have at least something checked
		setHasSomething(false);
		newItems.forEach((element) => {
			if (element.checked === true) {
				setHasSomething(true);
				return;
			}
		});
	};

	const buildSelection = (items) => {
		const selectedItems = [...itemsSelected];

		for (const item of items) {
			const selectedItemIndex = selectedItems.findIndex((e) => e.recId === item.recId);

			if (item.checked) {
				if (selectedItemIndex < 0) {
					selectedItems.push(item);
				}
			} else {
				if (selectedItemIndex >= 0) {
					selectedItems.splice(selectedItemIndex, 1);
				}
			}
		}

		setItemsSelected(selectedItems);
	};

	const toggleSelect = (checked) => {
		const newItems = itemsToList.map((item) => {
			item.checked = checked;
			return item;
		});

		buildSelection(newItems);
		setItemsToList(newItems);
		setHasSomething(checked);
	};

	const updateFilter = () => {
		const itemDescription = itemsSelected[0].description ?? heading;
		setFilter({
			text: multiSelect ? `${itemsSelected.length} ${heading}` : heading,
			description: multiSelect ? (itemsSelected.length === 1 ? itemDescription : `${itemsSelected.length} ${heading}`) : itemDescription,
			stepSize: stepSize,
			selection: itemsSelected,
			dataType: 'selection',
			multiSelect: multiSelect,
		});
	};

	const getPage = () => {
		const start = pageSize * (currentPage - 1);
		const end = start + pageSize;
		if (itemsToList.length > start) {
			const pageItems = itemsToList.slice(start, end);
			setPageItem([...pageItems]);
		} else {
			setPageItem([...itemsToList]);
		}
	};

	const filterByText = (e) => {
		const {
			currentTarget: { value },
		} = e;

		const filteredItems = items.filter((item) => {
			const { identifier = '', description = '' } = item;
			if (value === '' || identifier.toLowerCase().includes(value.toLowerCase()) || description.toLowerCase().includes(value.toLowerCase())) return item;
		});

		setItemsToList(filteredItems);
		setCurrentPage(1);
	};

	const recordTitle = (item) => {
		const { description, identifier } = item;
		if (!description && !identifier) return t('g.noDescriptionAvailable');

		if (description) {
			return description;
		} else {
			return identifier;
		}
	};

	useEffect(() => {
		getPage();
	}, [items, currentPage, itemsToList]);

	return (
		<div className="col flex-fill ml-xl-10 mr-xl-10 pl-xl-10 pr-xl-10">
			<SelectFilter multiSelect={multiSelect} selectAll={() => toggleSelect(true)} selectNone={() => toggleSelect(false)} textEntered={filterByText} itemCount={items.length} />

			<ul className="d-flex flex-fill flex-wrap list-group list-group-horizontal">
				{pageItems?.map((item, index) => (
					<li key={index} className="list-group-item">
						<label className="d-flex flex-row align-items-center m-0 p-1">
							<span className="d-flex flex-fill justify-content-start ml-3 mr-2 text-truncate" title={item.description}>
								{recordTitle(item)}
							</span>
							<input type={multiSelect ? 'checkbox' : 'radio'} className="mr-2" checked={item.checked} onChange={() => itemChecked(item.recId)} />
						</label>
					</li>
				))}
			</ul>

			<Pagination pageSize={pageSize} itemCount={itemsToList.length} currentPage={currentPage} pageChanged={setCurrentPage} />

			<ButtonNav next={updateFilter} previous={null} hasSelection={hasSomething} />
		</div>
	);
};

export const FilterRecords = (props) => {
	const { heading = '', items = [], isLoading = false } = props;
	const { t } = useTranslation();

	return (
		<Card>
			<CardBody>
				<Row className="justify-content-center">{isLoading ? <Loading message={`${t('g.loading')} ${heading}`} /> : items.length <= 0 ? <EmptyData /> : <DataList {...props} />}</Row>
			</CardBody>
		</Card>
	);
};
