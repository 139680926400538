import i18n from 'i18next';
import dayjs from 'dayjs';
import { isNumeric } from './Helpers';
import 'dayjs/locale/af';
import 'dayjs/locale/ar';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/lt';
import 'dayjs/locale/nl';
var duration = require('dayjs/plugin/duration');
var relativeTime = require('dayjs/plugin/relativeTime');
var utc = require('dayjs/plugin/utc');

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(utc);

const formatter = new Intl.NumberFormat('en-UK', {
	style: 'currency',
	currency: 'GBP',
});

export const Settings = {
	dateFormat: process.env.REACT_APP_DATE_FORMAT,
	timeFormat: process.env.REACT_APP_TIME_FORMAT,
	dateTimeFormat: process.env.REACT_APP_DATETIME_FORMAT,
	pageSize: 10,
	currency: {
		format: 'ZAR',
		rate: 1.0,
	},

	emptyDate: (date) => {
		return date === undefined || date === null || date === '01/01/0001 00:00' || date === '01-01-0001 00:00:00' || date === '0001-01-01T00:00:00' || date === '0001-01-01T00:00:00Z';
	},

	formatDate: (date, format = 'date', customFormat = 'YYYY-MM-DD') => {
		switch (format) {
			case 'date':
				return dayjs(date).locale(i18n.language).format(Settings.dateFormat);
			case 'time':
				return dayjs(date).locale(i18n.language).format(Settings.timeFormat);
			case 'datetime':
				return dayjs(date).locale(i18n.language).format(Settings.dateTimeFormat);
			case 'custom':
				return dayjs(date).locale(i18n.language).format(customFormat);
			default:
				return dayjs(date).locale(i18n.language).format(Settings.dateFormat);
		}
	},

	formatDuration: (duration, units = 'seconds', format = 'HH[h] mm[m] ss[s]') => {
		return duration <= 0 ? '-' : dayjs.duration(duration, units).locale(i18n.language).format(format);
	},

	formatFromNow: (dateTime) => {
		if (Settings.emptyDate(dateTime)) return '';

		return dayjs(dateTime).locale(i18n.language).fromNow();
	},

	formatMoney: (amount) => {
		return formatter.format(amount); /* $2,500.00 */
	},

	formatNumber: (number = 0, decimalPlace = 4) => {
		if (!isNumeric(number)) {
			return 0;
		}
		return number.toFixed(decimalPlace).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
	},
	subtract: (date, value, unit) => {
		return dayjs(date).locale(i18n.language).subtract(value, unit);
	},
	formatTimeToUtc: (hours = '00', minutes = '00', seconds = '00', returnFormat = null) => {
		return dayjs().hour(hours).minute(minutes).second(seconds).utc().format(returnFormat);
	},
};
