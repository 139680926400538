import React from 'react';
import InfoWindow from './components/InfoWindow';

import UserIcon from '../../../../assets/images/mapMarkerUser.png';

import './LocationMarker.css';
import { GetThemeColour } from '../../../../utils/ThemeHelper';

const LocationIconSvg = ({ primary = null, secondary = null }) => {
	const prm = primary ?? GetThemeColour('--primary');
	const sec = secondary ?? GetThemeColour('--secondary');

	return (
		<svg id="Map_Marker_-_In-Use" data-name="Map Marker - In-Use" width="32" height="32" viewBox="0 0 20 26.75" xmlns="http://www.w3.org/2000/svg">
			<path id="Path_29" data-name="Path 29" d="M 10 0 C 4.484 0.016 0.016 4.484 0 10 C 0 14 1.333 15.167 9 26.167 C 9.5 26.834 10.5 26.834 11 26.167 C 18.667 15.167 20 14 20 10 C 19.984 4.484 15.516 0.016 10 0 Z" fill={prm} />
			<ellipse cx="10" cy="10" rx="5" ry="5" fill={sec} />
		</svg>
	);
};

const LocationMarker = ({ locationInfo, selectedLocationId = null, zoomLocation, lat, lng }) => {
	const { type = 'point', status, title, chargePointId } = locationInfo?.properties ?? {};
	const show = selectedLocationId === chargePointId;

	const zoom = async () => {
		if (zoomLocation) await zoomLocation(chargePointId, lat, lng);
	};

	switch (type.toLowerCase()) {
		case 'user':
			return (
				<div className="map-marker" title={title} onClick={zoom}>
					<img src={UserIcon} alt={title} />
					{show && <InfoWindow locationInfo={null} lat={lat} lng={lng} />}
				</div>
			);
		case 'point':
			return (
				<div className="map-marker" title={title} onClick={zoom}>
					<LocationIconSvg secondary="#ffffff" />
					{show && <InfoWindow locationInfo={locationInfo?.properties ?? {}} lat={lat} lng={lng} />}
				</div>
			);

		default:
			return null;
	}
};

export default LocationMarker;
