import React from 'react';
import AppRoutes from './AppRoutes.js';

import './Vendor.js';

const App = () => {
	return <AppRoutes />;
};

export default App;
