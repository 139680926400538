import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CardWithHeaderActions } from '../../components/layout/CardContent';
import { SessionService } from '../../dataAccess/SessionService';
import SessionInfoForm from './SessionInfoForm';
import { BreadCrumb } from '../../components/layout/BreadCrumb';
import { Page } from '../../components/layout/Page';
import { SessionGraph } from './components/SessionGraph';
import { Settings } from '../../utils/Settings';
import { ButtonStop } from '../../components/ButtonStop';
import { ButtonUnclockConnector } from '../../components/ButtonUnlockConnector';
import { MapStatic } from '../../components/maps/MapStatic';

const HeaderActions = (props) => {
	const { session = {}, refresh } = props;
	return (
		<div className="d-flex">
			<div className="ml-2">
				<ButtonUnclockConnector {...session} />
			</div>
			<div className="ml-2">
				<ButtonStop size="large" refresh={refresh} {...session} />
			</div>
		</div>
	);
};

export const SessionInfo = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const [doRefresh, setDoRefresh] = useState(false);
	const session = SessionService(id, doRefresh);

	const { t } = useTranslation();

	const { transactionNumber, transactionDateStarted, chargePointGeolocation = {} } = session;
	const { latitude, longitude } = chargePointGeolocation;

	const navBackward = () => {
		navigate(-1);
	};

	const refresh = async () => {
		setDoRefresh(!doRefresh);
	};

	return (
		<Page>
			<BreadCrumb title={`${t('cp.transaction')} ${transactionNumber}, ${t('cp.dated')} ${Settings.formatDate(transactionDateStarted)}`} navBackward={navBackward} />

			<CardWithHeaderActions heading={t('cp.transactionDetailedInformation')} actions={<HeaderActions refresh={refresh} session={session} />}>
				<div className="row">
					<div className="col-xs-12 col-sm-3 mb-3" style={{ minHeight: 200 }}>
						<div className="media-map">
							<MapStatic markerLat={latitude} markerLng={longitude} />
						</div>
					</div>
					<div className="col">
						<SessionInfoForm session={session} />
					</div>
				</div>
				<SessionGraph sessionId={id} />
			</CardWithHeaderActions>
		</Page>
	);
};
