import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Page } from '../../components/layout/Page';
import { BreadCrumb } from '../../components/layout/BreadCrumb';
import { CardPlain } from '../../components/layout/CardContent';
import { EmptyData } from '../../components/EmptyData';

import { PagedListContext } from '../../dataAccess/PagedListContext';
import Loading from '../../components/Loading';
import { ErrorAlert } from '../../components/GeneralError';
import { AlertList } from './AlertList';
import { Settings } from '../../utils/Settings';

export const Alerts = () => {
	const navigate = useNavigate();
	const [pageSize, setPageSize] = useState(Settings.pageSize);
	const { data, getData } = PagedListContext('/notifications', null, pageSize);
	const { items, itemCount, currentPage, isBusy, hasError } = data;

	const { t } = useTranslation();

	const navBackward = () => {
		navigate(-1);
	};

	useEffect(() => {
		getData('', null, '', true);
	}, [pageSize]);

	return (
		<Page>
			<BreadCrumb title={t('a.alertsNotifications')} navBackward={navBackward} />
			<CardPlain heading={t('a.alerts')}>
				{isBusy && <Loading message={t('a.loadingNotifications')} />}
				{hasError && <ErrorAlert message={hasError} />}
				{!isBusy && !hasError && itemCount == 0 ? <EmptyData /> : <AlertList items={items} itemCount={itemCount} currentPage={currentPage} setPageSize={setPageSize} pageSize={pageSize} itemCount={itemCount} />}
			</CardPlain>
		</Page>
	);
};
