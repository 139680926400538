import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

console.debug('Setting up language support...');

export const setupLanguages = () => {
	i18n.use(initReactI18next)
		.use(LanguageDetector)
		.use(HttpApi)
		.init({
			fallbackLng: 'en',
			detection: {
				order: ['localStorage', 'htmlTag'],
				caches: ['localStorage'],
			},
			backend: {
				loadPath: '/assets/locales/{{lng}}/translation.json',
				requestOptions: {
					cache: 'no-store',
				},
			},
		});

	console.debug('Done Setting up language support.');
};
