import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

import { Bar } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = ({ height, data }) => {
	const options = {
		responsive: true,
		maintainAspectRatio: false,
		layout: {
			padding: {
				left: 10,
				right: 25,
				top: 25,
				bottom: 0,
			},
		},
		scales: {
			xAxes: {
				time: {
					unit: 'month',
				},
				grid: {
					display: false,
					drawBorder: false,
				},
				ticks: {
					maxTicksLimit: 6,
					autoSkip: false,
				},
				maxBarThickness: 25,
			},
			yAxes: {
				ticks: {
					min: 0,
					//max: 15000,
					maxTicksLimit: 5,
					autoSkip: false,
					padding: 10,
					// Include a dollar sign in the ticks
					//callback: function(value, index, values) {
					//    return "$" + number_format(value);
					//}
				},
				grid: {
					color: 'rgb(234, 236, 244)',
					zeroLineColor: 'rgb(234, 236, 244)',
					drawBorder: false,
					borderDash: [2],
					zeroLineBorderDash: [2],
				},
			},
		},
		legend: {
			display: false,
		},
		tooltips: {
			titleMarginBottom: 10,
			titleFontColor: '#6e707e',
			titleFontSize: 14,
			backgroundColor: 'rgb(255,255,255)',
			bodyFontColor: '#858796',
			borderColor: '#dddfeb',
			borderWidth: 1,
			xPadding: 15,
			yPadding: 15,
			displayColors: false,
			caretPadding: 10,
		},
	};

	return (
		<div style={{ height: { height }, width: '100%' }}>
			<Bar height={height} data={data} options={options} />
		</div>
	);
};

export default BarChart;
