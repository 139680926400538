import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HttpHandler } from '../../../utils/HttpHandler';

import TransactionSpendGraph from '../../../components/charts/TransactionSpendGraph';
import { CardWithHeaderActions } from '../../../components/layout/CardContent';
import { TransactionTable } from '../../transactions/components/TransactionTable';
import UserForm from './UserForm';

import { PagedListContext } from '../../../dataAccess/PagedListContext';
import { MapGraphData } from '../../../utils/MapGraphData';
import { Settings } from '../../../utils/Settings';
import { ButtonText } from '../../../components/Buttons';

const UpdateUserInfoButton = ({ action, disabled, isBusy }) => {
	const { t } = useTranslation();
	return <ButtonText icon={`fal ${isBusy ? 'fa-sync fa-spin' : 'fa-check'} mr-1`} extraClass="active" action={action} text={t('g.save')} disabled={disabled} />;
};

export const ChargeCardInfo = ({ chargeCard: user, refresh }) => {
	const { httpPut } = HttpHandler(process.env.REACT_APP_API_PATH, false);
	const { recId } = user ?? {};
	const [days, setDays] = useState(7);
	const [pageSize, setPageSize] = useState(Settings.pageSize);
	const [isBusy, setIsBusy] = useState(false);

	const { t } = useTranslation();

	const { data: usageData, getData: getUsage } = PagedListContext(`report/chargeSessionSummary/${days}/chargeTag/${recId}`, null, 0);
	const { data: txnData, getData: getTxnData } = PagedListContext('chargeTransaction', `/chargeTagId/${recId}`, pageSize);

	let graphData = {};
	switch (days) {
		case 7: {
			graphData = MapGraphData.toDayOfWeek(t('g.kWhConsumed'), 'total', 'date', usageData.items ?? []);
			break;
		}
		case 30: {
			graphData = MapGraphData.toDayOfMonth(t('g.kWhConsumed'), 'total', 'date', usageData.items ?? []);
			break;
		}
		case 365: {
			graphData = MapGraphData.toMonthOfYear(t('g.kWhConsumed'), 'total', 'date', usageData.items ?? []);
			break;
		}
	}

	const updateFilter = async (graph, days) => {
		setDays(days);
	};

	const loadMore = () => {
		setPageSize(pageSize + Settings.pageSize);
	};

	const updateUserInfo = async () => {
		setIsBusy(true);
		return httpPut('/chargeTag', null, user)
			.then(() => {
				if (refresh) refresh();
				return true;
			})
			.catch((error) => {
				console.error(error);
				throw new Error(t('l.genericError'));
			})
			.finally(() => setIsBusy(false));
	};

	const onChange = (e) => {
		const { currentTarget } = e;
		const { name, value } = currentTarget;

		user = {
			...user,
			[name]: value,
		};
	};

	useEffect(() => {
		getUsage();
		getTxnData();
	}, [user, recId, days, pageSize]);

	return (
		<Fragment>
			<div className="row">
				<div className="col-xl-6 mb-3">
					<CardWithHeaderActions heading={t('cc.chargeCardUserInformation')} actions={<UpdateUserInfoButton action={updateUserInfo} disabled={isBusy} isBusy={isBusy} />}>
						<UserForm user={user} onChange={onChange} />
					</CardWithHeaderActions>
				</div>
				<div className="col-xl-6 mb-3">
					<TransactionSpendGraph data={graphData} height={284} updateFilter={updateFilter} />
				</div>
			</div>
			<div className="row">
				<div className="col-xl-12">
					<TransactionTable
						title={t('g.latestTransactions')}
						isLoading={txnData.isBusy}
						items={txnData.items}
						showCard={false}
						showLocation={false}
						loadMore={loadMore}
						groupBy={['chargeTokenDescription', 'chargePointPhysicalReference']}
					/>
				</div>
			</div>
		</Fragment>
	);
};
