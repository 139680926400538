import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination } from './layout/Pagination';

const unGroupElement = (element) => {
	if (element === null || element === undefined) return [];

	let result = [];
	Object.keys(element).forEach((key) => {
		result = [...result, ...element[key]];
	});

	return result;
};

const unGroupAllElements = (items) => {
	let result = [];
	if (items == null || items === undefined) return result;

	items.forEach((element) => {
		Object.keys(element).forEach((key) => {
			result = [...result, ...unGroupElement(element[key])];
		});
	});

	return result;
};

const TableHeader = ({ headers, selectAll = null }) => {
	return (
		<thead>
			<tr className="d-none d-md-table-row ">
				{headers.map((header, index) =>
					selectAll ? (
						<th className={`align-top ${header.className ?? ''}`} key={`th-${index}`} colSpan={1}>
							{header.title}
						</th>
					) : (
						index > 0 && (
							<th className={`align-top ${header.className ?? ''}`} key={`th-${index}`} colSpan={index === 1 ? 2 : 1}>
								{header.title}
							</th>
						)
					),
				)}
			</tr>
		</thead>
	);
};

const TableBody = (props) => {
	const { items = {}, rowTemplate, itemKey } = props;
	return <tbody key={itemKey}>{items?.map((item, index) => rowTemplate(item, index, { ...props }))}</tbody>;
};

const GroupFooter = (props) => {
	const { item = [], groupTemplate = null, itemKey } = props;
	return groupTemplate == null ? null : <tbody key={itemKey}>{groupTemplate(item, { ...props })}</tbody>;
};

const TableFooter = (props) => {
	const { items = [], footTemplate = null } = props;

	return footTemplate == null ? null : <tbody>{footTemplate(items, { ...props })}</tbody>;
};

const EmptyData = () => {
	const { t } = useTranslation();
	return <span>{t('g.noDataToShow')}</span>;
};

export const DataTable = (props) => {
	const { headers, items } = props;
	if (!items || !items.data || items.data.length <= 0) return <EmptyData />;

	return (
		<div>
			<div className="datatable table-responsive">
				<table className="table table-hover" id="dataTable" width="100%" cellSpacing="0">
					<TableHeader headers={headers} />
					<TableBody {...props} items={items.data} />
					<TableFooter {...props} items={items.data} />
				</table>
			</div>
		</div>
	);
};

export const PagedTable = (props) => {
	const { items = [], currentPage, pageChanged } = props;
	if (!items || !items.data || items.data.length <= 0) return <EmptyData />;

	return (
		<div>
			<DataTable {...props} />
			<Pagination pageChanged={pageChanged} currentPage={currentPage} itemCount={items.recordCount} />
		</div>
	);
};

export const GroupedTable = (props) => {
	const { t } = useTranslation();
	const { headers, items, showGroupHeaders = true, selectAll = null } = props;

	if (!items || items.length <= 0) return <EmptyData />;

	const allItems = unGroupAllElements(items);

	return (
		<div>
			<div className="datatable table-responsive">
				{selectAll && (
					<div className="pb-2 d-flex justify-content-end">
						<button className="btn btn-sm btn-outline-dark mr-3" onClick={() => selectAll(true)}>
							<i className="fal fa-check-square mr-1" />
							Select All
						</button>
						<button className="btn btn-sm btn-outline-dark" onClick={() => selectAll(false)}>
							<i className="fal fa-square mr-1" />
							Deselect All
						</button>
					</div>
				)}
				<table className="table table-hover" id="dataTable" width="100%" cellSpacing="0">
					{items.map((element, index) => (
						<Fragment key={`outer-group-${index}`}>
							{Object.keys(element).map((elementKey, groupIndex) => (
								<Fragment key={`outer-group-1-${groupIndex}`}>
									{showGroupHeaders && (
										<thead>
											<tr>
												<th className={`${index === 0 ? 'p-0' : 'p-0 pt-4'}`} colSpan={headers.length}>
													{elementKey}
												</th>
											</tr>
										</thead>
									)}

									<Fragment key={`inner-group-${groupIndex}`}>
										{Object.keys(element[elementKey]).map((innerKey, innerGroupIndex) => (
											<Fragment key={`inner-group-1-${innerGroupIndex}`}>
												{showGroupHeaders && (
													<tr>
														<th className={`${innerGroupIndex === 0 ? 'p-0 pt-1 pb-1' : 'p-0 pt-3 pb-1'}`} colSpan={headers.length}>
															{innerKey}
														</th>
													</tr>
												)}
												<TableHeader headers={headers} selectAll={selectAll} />
												<TableBody {...props} items={element[elementKey][innerKey]} itemKey={`${index} ${groupIndex} ${innerGroupIndex}`} />
												{Object.keys(element[elementKey]).length > 1 && (
													<GroupFooter {...props} item={{ data: element[elementKey][innerKey] }} label={innerKey} title={t('g.subTotal')} itemKey={innerGroupIndex} />
												)}
											</Fragment>
										))}
										{items.length > 1 && <GroupFooter {...props} item={{ data: unGroupElement(element[elementKey]) }} label={elementKey} title={t('g.groupTotal')} />}
									</Fragment>
								</Fragment>
							))}
						</Fragment>
					))}

					<TableFooter {...props} items={allItems} />
				</table>
			</div>
		</div>
	);
};
